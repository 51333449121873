import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
  Button,
  UncontrolledDropdown,
} from "reactstrap";
import DeleteModal from "../../../Components/Common/DeleteModal";
import { ToastContainer } from "react-toastify";
import Loader from "Components/Common/Loader";
import TableContainer from "Components/Common/TableContainer";

//redux
import { useSelector, useDispatch } from "react-redux";

//Import Icons
import FeatherIcon from "feather-icons-react";

//import action
import {
  getProjectList as onGetProjectList,
  deleteProjectList as onDeleteProjectList,
} from "../../../slices/thunks";
import { createSelector } from "reselect";

import {
  useGetAllProjectsQuery,
  useCreateProjectMutation,
  useUpdateProjectMutation,
} from "slices/projects/projectSlice";
import { ProjectFormModal } from "../Modal/ProjectForm";

const List = () => {
  const dispatch: any = useDispatch();
  const [modal, setModal] = useState<boolean>(false);
  const [selectedFiles, setselectedFiles] = useState<any>([]);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [viewMode, setViewMode] = useState<"card" | "table">("card");
  const selectDashboardData = createSelector(
    (state: any) => state.Projects.projectLists,
    (projectLists) => projectLists
  );
  const projectLists = useSelector(selectDashboardData);

  const [project, setProject] = useState<any>([]);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const itemsPerPage = 8;

  const {
    data: allProjects = [],
    isLoading: isProjectLoading,
    refetch: refetchProject,
    error: projectError,
  } = useGetAllProjectsQuery(undefined);
  const [createProject, { isLoading: isLoadingCreateProject }] =
    useCreateProjectMutation();
  const [updateProject, { isLoading: isLoadingUpdateProject }] =
    useUpdateProjectMutation();

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      setProject(null);
    } else {
      setModal(true);
    }
  }, [modal]);

  useEffect(() => {
    dispatch(onGetProjectList());
  }, [dispatch]);

  useEffect(() => {
    setProject(projectLists);
  }, [projectLists]);

  const onClickData = (project: any) => {
    setProject(project);
    setDeleteModal(true);
  };

  const handleDeleteProjectList = () => {
    if (project) {
      dispatch(onDeleteProjectList(project.id));
      setDeleteModal(false);
    }
  };
  const columns = useMemo(
    () => [
      {
        Header: "Project",
        accessor: "project_name",
        filterable: false,
      },

      {
        Header: "Description",
        accessor: "description",
        filterable: false,
      },

      {
        Header: "Actual Cost",
        accessor: "project_actual_cost",
        filterable: false,
      },
      {
        Header: "Estimation Cost",
        accessor: "project_estimation_cost",
        filterable: false,
      },
      {
        Header: "Allocated Budget",
        accessor: "project_allocated_budget",
        filterable: false,
      },
      {
        Header: "Due Date",
        accessor: "end_date",
        filterable: false,
      },
      {
        Header: "Actions",
        accessor: "actions",
        filterable: false,
        Cell: ({ row }: any) => {
          console.log("row", row);
          return (
            <UncontrolledDropdown direction="start">
              <DropdownToggle
                tag="button"
                className="btn btn-link text-muted p-1 mt-n2 py-0 text-decoration-none fs-15 shadow-none"
              >
                <FeatherIcon icon="more-horizontal" className="icon-sm" />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem
                  href={`/apps-projects-overview/${row?.original?.id}`}
                >
                  <i className="ri-eye-fill align-bottom me-2 text-muted"></i>{" "}
                  View
                </DropdownItem>
                <Link
                  className="text-black"
                  to={`/inventory-dashboard/${row?.original?.id}`}
                >
                  <DropdownItem>
                    <i className="ri-file-fill align-bottom me-2 text-muted"></i>{" "}
                    Inventory
                  </DropdownItem>
                </Link>
                <DropdownItem
                  href="#"
                  onClick={() => {
                    setProject(row?.original);
                    setIsEdit(true);
                    toggle();
                  }}
                >
                  <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>{" "}
                  Edit
                </DropdownItem>
                <div className="dropdown-divider"></div>
                <DropdownItem
                  href="#"
                  onClick={() => onClickData(row?.original)}
                  data-bs-toggle="modal"
                  data-bs-target="#removeProjectModal"
                >
                  <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i>{" "}
                  Remove
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          );
        },
      },
    ],
    []
  );

  const indexOfLastProject = currentPage * itemsPerPage;
  const indexOfFirstProject = indexOfLastProject - itemsPerPage;
  const currentProjects = allProjects?.slice(
    indexOfFirstProject,
    indexOfLastProject
  );

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  const totalPages = Math.ceil(allProjects?.length / itemsPerPage);

  const activebtn = (ele: any) => {
    if (ele.closest("button").classList.contains("active")) {
      ele.closest("button").classList.remove("active");
    } else {
      ele.closest("button").classList.add("active");
    }
  };

  return (
    <React.Fragment>
      <ToastContainer closeButton={false} />
      <DeleteModal
        show={deleteModal}
        onDeleteClick={() => handleDeleteProjectList()}
        onCloseClick={() => setDeleteModal(false)}
      />
      <Row className="g-4 mb-3">
        <div className="col-sm-auto">
          <div>
            <Button
              onClick={() => {
                setIsEdit(false);
                toggle();
              }}
              disabled={isProjectLoading}
              className="btn btn-success"
            >
              <i className="ri-add-line align-bottom me-1"></i> Add New
            </Button>
          </div>
        </div>
        <div className="col-sm-3 ms-auto">
          {/* <div className="d-flex justify-content-sm-end gap-2">
            <div className="search-box ms-2 col-sm-7">
              <Input
                type="text"
                className="form-control"
                placeholder="Search..."
              />
              <i className="ri-search-line search-icon"></i>
            </div>

            <select
              className="form-control w-md"
              data-choices
              data-choices-search-false
            >
              <option value="All">All</option>
              <option value="Last 7 Days">Last 7 Days</option>
              <option value="Last 30 Days">Last 30 Days</option>
              <option value="Last Year">Last Year</option>
              <option value="This Month">This Month</option>
              <option value="Today">Today</option>
              <option value="Yesterday" defaultValue="">
                Yesterday
              </option>
            </select>
          </div> */}
        </div>
        <div className="col-sm-auto">
          <Button
            onClick={() => setViewMode(viewMode === "card" ? "table" : "card")}
            className="btn btn-primary"
          >
            {viewMode === "card" ? (
              <i className="ri ri-table-2"></i>
            ) : (
              <i className="ri ri-grid-line"></i>
            )}
          </Button>
        </div>
      </Row>

      {viewMode === "card" ? (
        <div>
          <div className="row">
            {currentProjects.map((item: any, index: any) => (
              <React.Fragment key={index}>
                <Col xxl={3} sm={6} className="project-card">
                  <Card className="card-height-100">
                    <CardBody>
                      <div className="d-flex flex-column h-100">
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-muted mb-4">Updated 3hrs ago</p>
                          </div>
                          
                          <div className="flex-shrink-0">
                            <div className="d-flex gap-1 align-items-center">
                              {/* <button
                                type="button"
                                className={`btn avatar-xs mt-n1 p-0 favourite-btn shadow-none active`}
                                onClick={(e) => activebtn(e.target)}
                              >
                                <span className="avatar-title bg-transparent fs-15">
                                  <i className="ri-star-fill"></i>
                                </span>
                              </button> */}
                              <UncontrolledDropdown direction="start">
                                <DropdownToggle
                                  tag="button"
                                  className="btn btn-link text-muted p-1 mt-n2 py-0 text-decoration-none fs-15 shadow-none"
                                >
                                  <FeatherIcon
                                    icon="more-horizontal"
                                    className="icon-sm"
                                  />
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-end">
                                  <DropdownItem
                                    href={`/apps-projects-overview/${item.id}`}
                                  >
                                    <i className="ri-eye-fill align-bottom me-2 text-muted"></i>{" "}
                                    View
                                  </DropdownItem>
                                  <Link
                                    className="text-black"
                                    to={`/inventory-dashboard/${item.id}`}
                                  >
                                    <DropdownItem>
                                      <i className="ri-file-fill align-bottom me-2 text-muted"></i>{" "}
                                      Inventory
                                    </DropdownItem>
                                  </Link>
                                  <DropdownItem
                                    href="#"
                                    onClick={() => {
                                      setProject(item);
                                      setIsEdit(true);
                                      toggle();
                                    }}
                                  >
                                    <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>{" "}
                                    Edit
                                  </DropdownItem>
                                  <div className="dropdown-divider"></div>
                                  <DropdownItem
                                    href="#"
                                    onClick={() => onClickData(item)}
                                    data-bs-toggle="modal"
                                    data-bs-target="#removeProjectModal"
                                  >
                                    <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i>{" "}
                                    Remove
                                  </DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex mb-2">
                          <div className="flex-shrink-0 me-3">
                            <div className="avatar-sm">
                              <span
                                className={
                                  "avatar-title rounded-circle p-2 bg-" +
                                  item.imgbgColor +
                                  "-subtle"
                                }
                                style={{ fontSize: "22px" }}
                              >
                                {item?.thumbnail_url ? (
                                  <img
                                    src={item.thumbnail_url}
                                    alt=""
                                    className="rounded-circle avatar-sm"
                                  />
                                ) : (
                                  <>{item.project_name.charAt(0)}</>
                                )}
                              </span>
                            </div>
                          </div>
                          <div className="flex-grow-1">
                            <h5 className="mb-1 fs-15">
                              <Link
                                to="/apps-projects-overview"
                                className="text-body"
                              >
                                {item?.project_name}
                              </Link>
                            </h5>
                            <p className="text-muted text-truncate-two-lines mb-3">
                              {item?.description}
                            </p>
                          </div>
                        </div>
                        <div className="mt-auto">
                          <div className="d-flex mb-2">
                            <div className="flex-grow-1">
                              <div className="text-muted mb-2">
                                Actual Cost: {item?.project_actual_cost || 0}
                              </div>
                              <div className="text-muted mb-2">
                                Estimation Cost:{" "}
                                {item?.project_estimation_cost || 0}
                              </div>
                              <div className="text-muted">
                                Allocated Budget:{" "}
                                {item?.project_allocated_budget || 0}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </CardBody>
                    <div className="card-footer bg-transparent border-top-dashed py-2">
                      <div className="d-flex align-items-center">
                        <div className="flex-grow-1">
                          <div className="avatar-group" style={{ padding: 0 }}>
                            <span className="badge bg-success-subtle text-success mt-1 float-end fs-6">
                              Active
                            </span>
                            {/* {item?.subItem?.map((item : any, key : any) => (
                                                       <React.Fragment key={key}>
                                                           {item.imgFooter ? <Link to="#" className="avatar-group-item" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="Darline Williams">
                                                               <div className="avatar-xxs">
                                                                   <img src={item.imgFooter} alt="" className="rounded-circle img-fluid" />
                                                               </div>
                                                           </Link> : <Link to="#" className="avatar-group-item" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="Add Members">
                                                               <div className="avatar-xxs">
                                                                   <div className={item.bgColor ? "avatar-title rounded-circle bg-" + item.bgColor : "avatar-title rounded-circle bg-light border-dashed border text-primary fs-16 "}>
                                                                       {item.imgNumber}
                                                                   </div>
                                                               </div>
                                                           </Link>}
                                                       </React.Fragment>
                                                   ))} */}
                          </div>
                        </div>
                        <div className="flex-shrink-0">
                          {/* {item?.start_date ?
                                                   <span className="text-muted">
                                                       <i className="ri-calendar-event-fill me-1 align-bottom"></i> {item?.start_date}
                                                       &nbsp; &nbsp; &nbsp;
                                                   </span> 
                                                   
                                               : ''} */}

                          {item?.end_date ? (
                            <span className="text-muted">
                              <i className="ri-calendar-event-fill me-1 align-bottom"></i>{" "}
                              {item?.end_date}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </Card>
                </Col>
              </React.Fragment>
            ))}
          </div>
          <Row className="g-0 text-center text-sm-start align-items-center mb-4">
            <Col sm={6}>
              <div>
                <p className="mb-sm-0 text-muted">
                  Showing <span className="fw-semibold">1</span> to{" "}
                  <span className="fw-semibold">10</span> of{" "}
                  <span className="fw-semibold text-decoration-underline">
                    12
                  </span>{" "}
                  entries
                </p>
              </div>
            </Col>

            <Col sm={6}>
              <ul className="pagination pagination-separated justify-content-center justify-content-sm-end mb-sm-0">
                <li className="page-item">
                  <button
                    className="page-link"
                    onClick={() =>
                      setCurrentPage((prev) => Math.max(prev - 1, 1))
                    }
                    disabled={currentPage === 1}
                  >
                    Previous
                  </button>
                </li>
                {[...Array(totalPages)].map((_, index) => (
                  <li
                    key={index}
                    className={`page-item ${
                      currentPage === index + 1 ? "active" : ""
                    }`}
                  >
                    <button
                      className="page-link"
                      onClick={() => paginate(index + 1)}
                    >
                      {index + 1}
                    </button>
                  </li>
                ))}
                <li className="page-item">
                  <button
                    className="page-link"
                    onClick={() =>
                      setCurrentPage((prev) => Math.min(prev + 1, totalPages))
                    }
                    disabled={currentPage === totalPages}
                  >
                    Next
                  </button>
                </li>
              </ul>
            </Col>
          </Row>
        </div>
      ) : (
        <React.Fragment>
          <div className="row">
            <div className="card" id="projectList">
              <div className="card-body pt-0">
                <TableContainer
                  columns={columns}
                  data={allProjects || []}
                  isGlobalFilter={true}
                  isAddUserList={false}
                  customPageSize={10}
                  className="custom-header-css"
                  divClass="table-responsive table-card mb-3"
                  tableClass="align-middle table-nowrap mb-0"
                  theadClass="table-light text-muted"
                  thClass="table-light text-muted"
                  // handleTaskClick={handleTaskClicks}
                  // isTaskListFilter={true}
                  SearchPlaceholder="Search for Projects..."
                  isPagination={true}
                  isLoading={isProjectLoading}
                />
              </div>
            </div>
          </div>
        </React.Fragment>
      )}

      {project && (
        <ProjectFormModal
          modal={modal}
          toggle={toggle}
          isEdit={isEdit}
          isLoadingCreateTask={
            !isEdit ? isLoadingCreateProject : isLoadingUpdateProject
          }
          selectedFiles={selectedFiles}
          setselectedFiles={setselectedFiles}
          setModal={setModal}
          project={project}
          createProject={createProject}
          updateProject={updateProject}
          refetchProject={refetchProject}
        />
      )}

      {isProjectLoading || isLoadingCreateProject || isLoadingUpdateProject ? (
        <Loader />
      ) : null}
    </React.Fragment>
  );
};

export default List;
