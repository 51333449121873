import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  WorkLocationType,
  WorkPositionType,
  ProjectType,
  EmployeeDropDownType,
  EmployeeDocumentType,
} from "./type";

const getToken = () => {
  const authUser = sessionStorage.getItem("authUser");
  return authUser ? JSON.parse(authUser).access : null;
};

const convertToFormData = (obj: Record<string, any> | undefined | null, parentKey: string = ''): FormData => {
  const formData = new FormData();

  const appendData = (key: string, value: any) => {
    if (value instanceof File) {
      formData.append(key, value);
    } else if (Array.isArray(value)) {
      value.forEach((item, index) => {
        if (item instanceof File) {
          formData.append(`${key}[${index}]`, item);
        } else {
          formData.append(`${key}[${index}]`, item);
        }
      });
    } else if (value && typeof value === 'object') {
      if (value instanceof Date) {
        formData.append(key, value.toISOString());
      } else {
        Object.keys(value).forEach(subKey => {
          appendData(`${key}[${subKey}]`, value[subKey]);
        });
      }
    } else {
      formData.append(key, value);
    }
  };

  if (obj) {
    Object.keys(obj).forEach(key => {
      const value = obj[key];
      appendData(parentKey ? `${parentKey}[${key}]` : key, value);
    });
  }

  return formData;
};

const convertToRoleFormData = (obj: any) => {
  const formData = new FormData();
  Object.keys(obj).forEach((key) => {
    if (Array.isArray(obj[key])) {
      obj[key].forEach((item) => formData.append(`${key}[]`, item));
    } else {
      formData.append(key, obj[key]);
    }
  });
  return formData;
};

export const employeeSlice = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: window.__RUNTIME_CONFIG__.REACT_APP_API_URL,
    prepareHeaders: (headers) => {
      const token = getToken();
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getEmployees: builder.query({
      query: () => "/employee/api/v1/employee_info/",
    }),

    createEmployee: builder.mutation({
      query: (newEmployee) => ({
        url: "/employee/api/v1/employee_onboarding/",
        method: "POST",
        body: newEmployee,
      }),
    }),
    getEmployeeByUuid: builder.query({
      query: (employee_uuid) =>
        `/employee/api/v1/employee_info/${employee_uuid}/`,
    }),
    patchEmployee: builder.mutation({
      query: ({ employee_uuid, updateData }) => {
        const formData = convertToFormData(updateData);
        return {
          url: `/employee/api/v1/employee_view/${employee_uuid}/`,
          method: "PATCH",
          body: formData,
        };
      },
    }),
    getProjects: builder.query<ProjectType[], void>({
      query: () => "/organization/api/v1/projects/",
    }),
    getWorkLocations: builder.query<WorkLocationType[], void>({
      query: () => "/employee/api/v1/employee_work_location/",
    }),
    getEmployeeDropDown: builder.query<EmployeeDropDownType[], void>({
      query: () => "/employee/api/v1/employee_drop_down/",
    }),
    getPositions: builder.query<WorkPositionType[], void>({
      query: () => "/employee/api/v1/employee_role_policy/",
    }),

    // New endpoints
    getEmployeePersonalInfo: builder.query({
      query: (employee_uuid) =>
        `/employee/api/v1/employee_personal_info/${employee_uuid}/`,
    }),
    patchEmployeePersonalInfo: builder.mutation({
      query: ({ employee_uuid, ...updateData }) => ({
        url: `/employee/api/v1/employee_personal_info/${employee_uuid}/`,
        method: "PATCH",
        body: updateData,
      }),
    }),
    getEmergencyContactInfo: builder.query({
      query: (employee_uuid) =>
        `/employee/api/v1/emergency_contact_info/${employee_uuid}/`,
    }),
    patchEmergencyContactInfo: builder.mutation({
      query: ({ employee_uuid, ...updateData }) => ({
        url: `/employee/api/v1/emergency_contact_info/${employee_uuid}/`,
        method: "PATCH",
        body: updateData,
      }),
    }),
    getEmployeeCountryOfIssue: builder.query({
      query: () => "/employee/api/v1/employee_country_of_issue/",
    }),

    patchEmployeeRole: builder.mutation({
      query: ({ employee_uuid, updateData }) => {
        const formData = convertToRoleFormData(updateData);
        return {
          url: `/employee/api/v1/employee_role_update/${employee_uuid}/`,
          method: "PATCH",
          body: formData,
        };
      },
    }),

    getDocumentsByEmployeeUuid: builder.query<
      EmployeeDocumentType[],
      string | undefined
    >({
      query: (employee_uuid) =>
        `/employee/api/v1/employee_documents/?employee__employee_uuid=${employee_uuid}`,
    }),

    // Post a new document using FormData
    postDocument: builder.mutation({
      query: (newDocument) => {
        const formData = convertToFormData(newDocument);
        return {
          url: "/employee/api/v1/employee_documents/",
          method: "POST",
          body: formData,
        };
      },
    }),

    getDocumentById: builder.query({
      query: (document_id) =>
        `/employee/api/v1/employee_documents/${document_id}/`,
    }),

    // Patch (update) a document by document ID using FormData
    patchDocumentById: builder.mutation({
      query: ({ id, formData }) => {
        const userData = convertToFormData(formData);
        return {
          url: `/employee/api/v1/employee_documents/${id}/`,
          method: "PATCH",
          body: userData,
        };
      },
    }),

    deleteDocumentById: builder.mutation({
      query: (id) => ({
        url: `/employee/api/v1/employee_documents/${id}/`,
        method: "DELETE",
      }),
    }),

    // Get employee profile image by UUID
    getEmployeeProfileImage: builder.query({
      query: (employee_uuid) =>
        `/employee/api/v1/employee_profile_image/${employee_uuid}/`,
    }),

    // Patch (update) employee profile image by UUID
    patchEmployeeProfileImage: builder.mutation({
      query: ({ employee_uuid, updateData }) => {
        const formData = convertToFormData(updateData);
        return {
          url: `/employee/api/v1/employee_profile_image/${employee_uuid}/`,
          method: "PATCH",
          body: formData,
        };
      },
    }),
    getEmployeeByUsername: builder.query({
      query: (username) => `/employee/api/v1/employee_onboard_verify_user/?username=${encodeURIComponent(username)}`,
    }),
  }),
});

export const {
  useGetEmployeesQuery,
  useCreateEmployeeMutation,
  useGetEmployeeByUuidQuery,
  usePatchEmployeeMutation,
  useGetProjectsQuery,
  useGetWorkLocationsQuery,
   useGetEmployeeDropDownQuery,
  useGetPositionsQuery,
  useGetEmployeePersonalInfoQuery,
  usePatchEmployeePersonalInfoMutation,
  useGetEmergencyContactInfoQuery,
  usePatchEmergencyContactInfoMutation,
  useGetEmployeeCountryOfIssueQuery,
  usePatchEmployeeRoleMutation,
  useGetDocumentsByEmployeeUuidQuery,
  usePostDocumentMutation,
  useGetDocumentByIdQuery,
  usePatchDocumentByIdMutation,
  useDeleteDocumentByIdMutation,
  useGetEmployeeProfileImageQuery,
  usePatchEmployeeProfileImageMutation,
  useGetEmployeeByUsernameQuery,
} = employeeSlice;
