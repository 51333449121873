import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ProjectType } from "./type";

const getToken = () => {
  const authUser = sessionStorage.getItem("authUser");
  return authUser ? JSON.parse(authUser).access : null;
};

const convertToFormData = (obj: any) => {
  console.log('obj', obj);
  const formData = new FormData();
  Object.keys(obj).forEach(key => {
    if (Array.isArray(obj[key])) {
      obj[key].forEach(item => formData.append(key, item));
    } else {
      formData.append(key, obj[key]);
    }
  });
  return formData;
};

export const projectSlice = createApi({
  reducerPath: "project",
  baseQuery: fetchBaseQuery({
    baseUrl: window.__RUNTIME_CONFIG__.REACT_APP_API_URL,
    prepareHeaders: (headers) => {
      const token = getToken();
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    createProject: builder.mutation({
      query: (newTask) => {
        const formData = convertToFormData(newTask);
        return {
          url: "/organization/api/v1/projects/",
          method: "POST",
          body: formData,
        };
      },
    }),
    updateProject: builder.mutation({
      query: ({ id, newProject }: { id: number; newProject: any }) => {

        if (newProject.thumbnail === null) {
          delete newProject.thumbnail
        }

        const formData = convertToFormData(newProject);
        return {
          url: `/organization/api/v1/projects/${id}/`,
          method: "PATCH",
          body: formData,
        };
      },
    }),
    getAllProjects: builder.query<ProjectType[], void>({
      query: () => "/organization/api/v1/projects/",
    }),
    getprojectById: builder.query<ProjectType, string|undefined>({
      query: (id) => `/organization/api/v1/projects/${id}/`,
    }),
    // getAllPriority: builder.query<PriorityType[], void>({
    //   query: () => "/task/api/v1/task_priority/",
    // }),
  }),
});

export const {
  useCreateProjectMutation,
  useUpdateProjectMutation,
  useGetAllProjectsQuery,
  useGetprojectByIdQuery,
} = projectSlice;
