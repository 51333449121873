import React, { useState, useContext, useEffect } from "react";
import { Card, CardBody, Col, Container, Row, Button, Alert } from "reactstrap";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { ServiceContext } from "context/ServiceContextProvider";

// actions
import { loginSuccess, setRole } from "slices/auth/login/reducer";

import logoLight from "../../assets/images/puretech-logo.png";

interface LocationState {
  roles: string[];
  roleIds: number[];
  login: any;
}

const SelectRole = () => {
  const dispatch: any = useDispatch();
  const navigate = useNavigate();
  const location = useLocation(); 
  
  const context = useContext(ServiceContext);
  if (!context) {
    throw new Error(
      "ServiceContext must be used within a ServiceContextProvider"
    );
  }

  const { setselectedRoleId, setSelectedRoleName } = context;

  const { roles, roleIds, login } = (location.state as LocationState) || {};

  const [selectedRole, setSelectedRole] = useState<string>("");
  const [error, setError] = useState<string | null>(null);

  // Fetch selected role from sessionStorage when the component mounts
  useEffect(() => {
    const storedRole = sessionStorage.getItem("selectedRole");
    if (storedRole) {
      const { role_id, role_name } = JSON.parse(storedRole);
      
      // Set the role in context and state
      setselectedRoleId(role_id);
      setSelectedRoleName(role_name);
      setSelectedRole(role_id);
    }
  }, [setselectedRoleId, setSelectedRoleName]);

  const handleRoleSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedRole(event.target.value);
  };

  const handleSubmit = () => {
    if (!selectedRole) {
      setError("Please select a role to continue.");
    } else {
      setError(null);

      const selectedIndex = roleIds.indexOf(parseInt(selectedRole)); // parseInt to ensure matching types
      if (selectedIndex !== -1) {
        const selectedRoleName = roles[selectedIndex];

        // Store selected role in sessionStorage
        sessionStorage.setItem(
          "selectedRole",
          JSON.stringify({ role_id: selectedRole, role_name: selectedRoleName })
        );

        // Set the role in context and state
        setselectedRoleId(selectedRole);
        setSelectedRoleName(selectedRoleName);

        // Dispatch the role to Redux
        dispatch(
          setRole({ role_id: selectedRole, role_name: selectedRoleName })
        );

        sessionStorage.setItem("authUser", JSON.stringify(login));

        // Optionally, you can dispatch the loginSuccess here if needed
        dispatch(loginSuccess({ login }));

        navigate("/dashboard");
      } else {
        setError("Role not found. Please try again.");
      }
    }
  };

  return (
    <React.Fragment>
      <ParticlesAuth>
        <div className="auth-page-content">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="text-center mt-sm-5 mb-4 text-white-50">
                  <div>
                    <img src={logoLight} alt="" height="50" />
                  </div>
                </div>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="mt-4">
                  <CardBody className="p-4">
                    <div className="text-center mt-2">
                      <h5 className="text-primary">Select Your Role</h5>
                      <p className="text-muted">
                        Please choose a role to continue.
                      </p>
                    </div>
                    {error && <Alert color="danger">{error}</Alert>}
                    <div className="p-2 mt-4">
                      <div className="form-group">
                        {roles && roles.length > 0 ? (
                          <select
                            className="form-control"
                            value={selectedRole}
                            onChange={handleRoleSelect}
                          >
                            <option value="" disabled>
                              Select a role
                            </option>
                            {roles.map((roleName: string, index: number) => (
                              <option
                                key={roleIds[index]}
                                value={roleIds[index]}
                              >
                                {roleName}
                              </option>
                            ))}
                          </select>
                        ) : (
                          <p>No roles available. Please contact support.</p>
                        )}
                      </div>

                      <div className="mt-4">
                        <Button
                          color="success"
                          className="btn w-100"
                          onClick={handleSubmit}
                        >
                          Continue
                        </Button>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </ParticlesAuth>
    </React.Fragment>
  );
};

export default SelectRole;
