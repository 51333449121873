import React, { useEffect, useContext } from "react";
import { ServiceContext } from "context/ServiceContextProvider";
export const useAuthServices = () => {
  const { setselectedRoleId, setSelectedRoleName } = useContext(ServiceContext);

  const getSelectedRole = () => {
    // Retrieve the selectedRole from sessionStorage
    const storedRole = sessionStorage.getItem("selectedRole");

    // Check if storedRole is not null
    if (storedRole) {
      const selectedRole = JSON.parse(storedRole);

      // Set the role_id and role_name in ServiceContext
      setselectedRoleId(selectedRole.role_id);
      setSelectedRoleName(selectedRole.role_name);
    } else {
      console.error("No role found in session storage");
    }
  };

  return { getSelectedRole };
};
