import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  Spinner,
} from "reactstrap";
import { useCreateSectionPlannedMutation } from "slices/resourceInventory/inventorySlice";
import { Formik, Form as FormikForm, ErrorMessage } from "formik";
import * as Yup from "yup";

interface PlannedModalProps {
  isOpen: boolean;
  toggle: () => void;
  productId: number | string;
  refetchInventory: () => void;
  initialQuantity?: number;
}

const PlannedModal: React.FC<PlannedModalProps> = ({
  isOpen,
  toggle,
  productId,
  refetchInventory,
  initialQuantity = 1,
}) => {
  const [createSectionPlanned, { isLoading: isCreating }] =
    useCreateSectionPlannedMutation();

  const [quantity, setQuantity] = useState<number>(initialQuantity);

  useEffect(() => {
    setQuantity(initialQuantity);
  }, [initialQuantity]);

  const validationSchema = Yup.object({
    quantity: Yup.number()
      .required("Quantity is required")
      .typeError("Quantity must be a number")
      .min(1, "Quantity must be at least 1"),
  });

  // Increment and Decrement handlers
  const handleIncrement = (setFieldValue: (field: string, value: any) => void) => {
    const newQuantity = quantity + 1;
    setQuantity(newQuantity);
    setFieldValue("quantity", newQuantity);
  };

  const handleDecrement = (setFieldValue: (field: string, value: any) => void) => {
    const newQuantity = Math.max(quantity - 1, 1);
    setQuantity(newQuantity);
    setFieldValue("quantity", newQuantity);
  };

  const handleSubmit = async (
    values: { quantity: number },
    { resetForm }: { resetForm: () => void }
  ) => {
    try {
      await createSectionPlanned({
        ...values,
        quantity: quantity.toString(),
        product: productId,
      });
      toggle();
      refetchInventory();
      resetForm();
    } catch (error) {
      console.error("Error adding planned section:", error);
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Add Planned Section</ModalHeader>
      <hr />
      <ModalBody>
        <Formik
          initialValues={{ quantity }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ handleSubmit, setFieldValue }) => (
            <FormikForm onSubmit={handleSubmit}>
              <FormGroup>
                <Label for="quantity">Quantity</Label>
                <div className="input-group">
                  <Button onClick={() => handleDecrement(setFieldValue)}>-</Button>
                  <Input
                    type="number"
                    id="quantity"
                    name="quantity"
                    value={quantity}
                    onChange={(e) => {
                      const value = Number(e.target.value);
                      setQuantity(value);
                      setFieldValue("quantity", value);
                    }}
                    min={1}
                  />
                  <Button onClick={() => handleIncrement(setFieldValue)}>+</Button>
                </div>
                <ErrorMessage
                  name="quantity"
                  component="div"
                  className="text-danger"
                />
              </FormGroup>
              <ModalFooter>
                <Button color="primary" type="submit" disabled={isCreating}>
                  {isCreating ? (
                    <>
                      <Spinner size="sm" />
                      &nbsp;Saving...
                    </>
                  ) : (
                    "Save Changes"
                  )}
                </Button>
                <Button
                  color="secondary"
                  onClick={toggle}
                  disabled={isCreating}
                >
                  Cancel
                </Button>
              </ModalFooter>
            </FormikForm>
          )}
        </Formik>
      </ModalBody>
    </Modal>
  );
};

export default PlannedModal;
