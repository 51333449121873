import { combineReducers } from "redux";

// Front
import LayoutReducer from "./layouts/reducer";

// Authentication
import LoginReducer from "./auth/login/reducer";
import AccountReducer from "./auth/register/reducer";
import ForgetPasswordReducer from "./auth/forgetpwd/reducer";
import ProfileReducer from "./auth/profile/reducer";

//Project
import ProjectsReducer from "./projects/reducer";

// Tasks
import TasksReducer from "./tasks/reducer";

//Ecommerce
import EcommerceReducer from "./ecommerce/reducer";

// Dashboard Project
import DashboardProjectReducer from "./dashboardProject/reducer";

//Api Slice

import { employeeSlice } from "./employee/employeeSlice";
import { inspectionReportSlice } from "./InspectionReport/InspectionReportSlice";
import { taskSlice } from "./tasks/taskSlice";
import { projectSlice } from "./projects/projectSlice";
import { workflowSlice } from "./workflow/WorkFlowSlice.";
import { resourceInventorySlice } from "./resourceInventory/inventorySlice";
import { pageAccessSlice } from "./pageAccess/PageAccessSlice";

const rootReducer = combineReducers({
  Layout: LayoutReducer,
  Login: LoginReducer,
  Account: AccountReducer,
  ForgetPassword: ForgetPasswordReducer,
  Profile: ProfileReducer,
  Projects: ProjectsReducer,
  Tasks: TasksReducer,
  DashboardProject: DashboardProjectReducer,
  Ecommerce: EcommerceReducer,
  [employeeSlice.reducerPath]: employeeSlice.reducer,
  [taskSlice.reducerPath]: taskSlice.reducer,
  [projectSlice.reducerPath]: projectSlice.reducer,
  [inspectionReportSlice.reducerPath]: inspectionReportSlice.reducer,
  [workflowSlice.reducerPath]: workflowSlice.reducer,
  [resourceInventorySlice.reducerPath]: resourceInventorySlice.reducer,
  [pageAccessSlice.reducerPath]: pageAccessSlice.reducer,

});

export default rootReducer;
