import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  Modal,
  Form,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Label,
  Input,
  FormFeedback,
} from "reactstrap";
import { Link } from "react-router-dom";
import Flatpickr from "react-flatpickr";
import { useNavigate } from "react-router-dom";

// Import Breadcrumb
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import DeleteModal from "../../../Components/Common/DeleteModal";

import { useGetInspectionReportsQuery } from "slices/InspectionReport/InspectionReportSlice";

import { REPORT_STATUS, ReportStatusId } from "utils/CommonUtils";

// Redux
import { useSelector, useDispatch } from "react-redux";
import TableContainer from "../../../Components/Common/TableContainer";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../../Components/Common/Loader";

// Export Modal
import ExportCSVModal from "../../../Components/Common/ExportCSVModal";
import { createSelector } from "reselect";

const InspectionReportListView = () => {
  const {
    data: inspectionReportData,
    isLoading: inspectionReportloading,
    refetch: refetchinspectionReport,
  } = useGetInspectionReportsQuery(undefined);

  const navigate = useNavigate();

  const dispatch: any = useDispatch();

  const selectLayoutState = (state: any) => state.Ecommerce;
  const ecomCustomerProperties = createSelector(selectLayoutState, (ecom) => ({
    customers: ecom.customers,
    isCustomerSuccess: ecom.isCustomerSuccess,
    error: ecom.error,
  }));

  const { customers, error } = useSelector(ecomCustomerProperties);
  const [customer, setCustomer] = useState<any>([]);
  const [modal, setModal] = useState<boolean>(false);

  const toggle = useCallback(() => {
    setModal((prevModal) => !prevModal);
    if (!modal) {
      setCustomer(null);
    }
  }, [modal]);

  useEffect(() => {
    if (!customers) {
      setCustomer(customers);
    }
  }, [customers]);

  // Add Customer
  const handleCustomerClicks = () => {
    setCustomer(null);
    toggle();
  };

  // Delete Multiple
  const [selectedCheckBoxDelete, setSelectedCheckBoxDelete] = useState([]);
  const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);
  const [reportData, setReportData] = useState<any>([]);

  useEffect(() => {
    if (inspectionReportData) {
      setReportData(inspectionReportData);
    }
  }, [inspectionReportData]);

  const deleteMultiple = () => {
    const checkall: any = document.getElementById("checkBoxAll");
    selectedCheckBoxDelete.forEach((element: any) => {
      console.log("value", element.value);
    });
    setIsMultiDeleteButton(false);
    checkall.checked = false;
  };

  // Checked All
  const checkedAll = useCallback(() => {
    const checkall: any = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".contactCheckBox");

    if (checkall.checked) {
      ele.forEach((ele: any) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele: any) => {
        ele.checked = false;
      });
    }
    deleteCheckbox();
  }, []);

  const deleteCheckbox = () => {
    const ele: any = document.querySelectorAll(".contactCheckBox:checked");
    ele.length > 0
      ? setIsMultiDeleteButton(true)
      : setIsMultiDeleteButton(false);
    setSelectedCheckBoxDelete(ele);
  };

  const columns = useMemo(
    () => [
      {
        Header: (
          <input
            type="checkbox"
            className="form-check-input"
            id="checkBoxAll"
            onClick={() => checkedAll()}
          />
        ),
        Cell: (cellProps: any) => {
          return (
            <input
              type="checkbox"
              className="contactCheckBox form-check-input"
              value={cellProps.row.original.id}
              onChange={() => deleteCheckbox()}
            />
          );
        },
        id: "#",
      },
      {
        Header: "Report ID",
        accessor: "report_id",
        filterable: false,
      },
      {
        Header: "Parent Company",
        accessor: "parent_company_name",
        filterable: false,
      },
      {
        Header: "Project Name",
        accessor: "project_name",
        filterable: false,
      },
      {
        Header: "Due Date",
        accessor: "due_date",
        filterable: false,
      },
      {
        Header: "Report Status",
        accessor: "report_status",
        filterable: false,
        Cell: (cell: any) => {
          const statusId = cell.value as ReportStatusId;
          const statusName = REPORT_STATUS[statusId] || "Unknown";
          let badgeClass = "badge bg-secondary text-dark";

          switch (statusId) {
            case 1: // Draft
              badgeClass = "badge bg-primary-subtle text-primary";
              break;
            case 2: // In Progress
            case 3: // Assigned
              badgeClass = "badge bg-info-subtle text-info";
              break;
            case 4: // Pending Review
            case 5: // In Review
              badgeClass = "badge bg-warning-subtle text-warning";
              break;
            case 6: // Completed
              badgeClass = "badge bg-success-subtle text-success";
              break;
            case 7: // Cancelled
            case 8: // On Hold
              badgeClass = "badge bg-danger-subtle text-danger";
              break;
            default:
              badgeClass = "badge bg-secondary text-dark";
          }

          return <span className={badgeClass}>{statusName}</span>;
        },
      },
      {
        Header: "Actions",
        accessor: "actions",
        Cell: ({ row }: any) => {
          console.log("row", row?.original?.id);
          const handleView = () => {
            navigate(`/report-view/${row?.original?.id}`);
          };
          const handleReportEdit = () => {
            navigate(`/report-edit/${row?.original?.id}`);
          };

          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <li className="list-inline-item edit" title="View">
                <div
                  className="text-primary d-inline-block edit-item-btn"
                  onClick={handleView}
                >
                  <i className="ri-eye-fill fs-16"></i>
                </div>
              </li>
              <li className="list-inline-item edit" title="View">
                <div
                  className="text-primary d-inline-block edit-item-btn"
                  onClick={handleReportEdit}
                >
                  <i className="ri-file-fill fs-16"></i>
                </div>
              </li>
            </ul>
          );
        },
      },
    ],
    [checkedAll, deleteCheckbox, navigate]
  );

  // Export Modal
  const [isExportCSV, setIsExportCSV] = useState<boolean>(false);

  document.title = "Customers | Velzon - React Admin & Dashboard Template";
  return (
    <React.Fragment>
      <div className="page-content">
        <ExportCSVModal
          show={isExportCSV}
          onCloseClick={() => setIsExportCSV(false)}
          data={reportData}
        />
        <DeleteModal
          show={modal}
          onDeleteClick={handleCustomerClicks}
          onCloseClick={() => setModal(false)}
        />
        <Container fluid>
          <BreadCrumb title="Report List" pageTitle="Report" />
          <Row>
            <Col lg={12}>
              <Card id="customerList">
                <CardHeader className="border-0">
                  <Row className="g-4 align-items-center">
                    <div className="col-sm">
                      <div>
                        <h5 className="card-title mb-0">Report List</h5>
                      </div>
                    </div>
                    <div className="col-sm-auto">
                      <div>
                        <button
                          type="button"
                          className="btn btn-success add-btn me-1"
                          id="create-btn"
                          onClick={() => navigate("/inspection-report")}
                          disabled={inspectionReportloading}
                        >
                          <i className="ri-add-line align-bottom me-1"></i> Add
                          Report
                        </button>{" "}
                        <button
                          type="button"
                          className="btn btn-secondary"
                          onClick={() => setIsExportCSV(true)}
                          disabled={inspectionReportloading}
                        >
                          <i className="ri-file-download-line align-bottom me-1"></i>{" "}
                          Export
                        </button>
                      </div>
                    </div>
                  </Row>
                </CardHeader>
                <div className="card-body pt-0">
                  <div>
                    <TableContainer
                      columns={columns}
                      data={reportData}
                      isGlobalFilter={true}
                      isAddUserList={false}
                      customPageSize={10}
                      className="custom-header-css"
                      isCustomerFilter={true}
                      SearchPlaceholder={"Search for members..."}
                      isPagination={true}
                      isLoading={inspectionReportloading}
                    />
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
};

export default InspectionReportListView;
