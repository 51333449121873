import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { PriorityType, TaskType } from "./type";

const getToken = () => {
  const authUser = sessionStorage.getItem("authUser");
  return authUser ? JSON.parse(authUser).access : null;
};

const convertToFormData = (obj: any) => {
  const formData = new FormData();
  Object.keys(obj).forEach(key => {
    if (Array.isArray(obj[key])) {
      obj[key].forEach(item => formData.append(key, item));
    } else {
      formData.append(key, obj[key]);
    }
  });
  return formData;
};

export const taskSlice = createApi({
  reducerPath: "task",
  baseQuery: fetchBaseQuery({
    baseUrl: window.__RUNTIME_CONFIG__.REACT_APP_API_URL,
    prepareHeaders: (headers) => {
      const token = getToken();
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    createTask: builder.mutation({
      query: (newTask) => {
        const formData = convertToFormData(newTask);
        return {
          url: "/task/api/v1/task/",
          method: "POST",
          body: formData,
        };
      },
    }),
    updateTask: builder.mutation({
      query: ({ id, newTask }: { id: number; newTask: any }) => {
        const formData = convertToFormData(newTask);
        return {
          url: `/task/api/v1/task/${id}/`,
          method: "PATCH",
          body: formData,
        };
      },
    }),
    getAllTasks: builder.query<TaskType[], void>({
      query: () => "/task/api/v1/task/",
    }),
    getTasksById: builder.query<TaskType, string|undefined>({
      query: (id) => `/task/api/v1/task/${id}/`,
    }),
    getAllPriority: builder.query<PriorityType[], void>({
      query: () => "/task/api/v1/task_priority/",
    }),
  }),
});

export const {
  useCreateTaskMutation,
  useUpdateTaskMutation,
  useGetAllTasksQuery,
  useGetTasksByIdQuery,
  useGetAllPriorityQuery,
} = taskSlice;
