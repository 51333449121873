import React, { useState, useEffect, ChangeEvent, FormEvent } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  Input,
  Label,
  Row,
  Button,
  Spinner,
} from "reactstrap";
import Select from "react-select";
import Flatpickr from "react-flatpickr";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import {
  useGetEmployeeByUuidQuery,
  usePatchEmployeeMutation,
  useGetWorkLocationsQuery,
  useGetEmployeeDropDownQuery,
  useGetPositionsQuery,
  useGetProjectsQuery,
} from "slices/employee/employeeSlice";
import { Title } from "utils/CommonUtils";
import { WorkProfile } from "../EmployeeProfileView/WorkProfileView";
import LoadingOverlay from 'react-loading-overlay-ts'

interface OptionType {
  value: string;
  label: string;
  id: string;
  name: string;
  project_name: string;
  employee_fullname: string;
}

interface EmployeeProfileData {
  first_name: string;
  last_name: string;
  work_phone: string;
  work_email: string;
  employee_start_date: string;
  skills: string;
  position: number | null;
  work_location: number | null;
  onboarding_mentor: number | null;
  title: number | null;
  direct_manager: number | null;
  projects_m2m: number[];
  middle_name: string;
  employment_status: string;
  employee_number: string;
  clients: string;
  company: string;
  teams: string;
  is_sponsored_employee: string;
  ni_number: string;
  soc: string;
  weekly_working_hours: string;
  job_contract: File | null;
}

interface WorkProfileEditProps {
  employee_uuid: string | undefined;
  setCardView: React.Dispatch<React.SetStateAction<Record<string, boolean>>>;
}

export const WorkProfileEdit: React.FC<WorkProfileEditProps> = ({
  employee_uuid,
  setCardView,
}) => {
  const {
    data: employeeData,
    isLoading: isLoadingEmployee,
    isError,
  } = useGetEmployeeByUuidQuery(employee_uuid);
  const [patchEmployee, { isLoading: patchEmployeeloading }] =
    usePatchEmployeeMutation();
  const { data: projectsData } = useGetProjectsQuery(undefined);
  const { data: positionData } = useGetPositionsQuery(undefined);
  const { data: employeedropdownData,isLoading:isLoadingReporter } = useGetEmployeeDropDownQuery(undefined);
  const { data: workLocationData } = useGetWorkLocationsQuery(undefined);

  const titleOptions = Title?.map((title) => ({
    value: title.id.toString(),
    label: title.name,
  }));

  const [employeeProfileData, setEmployeeProfileData] =
    useState<EmployeeProfileData>({
      first_name: "",
      last_name: "",
      work_phone: "",
      work_email: "",
      employee_start_date: "",
      skills: "",
      position: null,
      work_location: null,
      onboarding_mentor: null,
      title: null,
      direct_manager: null,
      projects_m2m: [],
      middle_name: "",
      employment_status: "",
      employee_number: "",
      clients: "",
      company: "",
      teams: "",
      is_sponsored_employee: "",
      ni_number: "",
      soc: "",
      weekly_working_hours: "",
      job_contract: null,
    });

  useEffect(() => {
    if (employeeData) {
      setEmployeeProfileData({
        first_name: employeeData.first_name || "",
        last_name: employeeData.last_name || "",
        work_phone: employeeData.work_phone || "",
        work_email: employeeData.work_email || "",
        employee_start_date: employeeData.employee_start_date || "",
        skills: employeeData.skills || "",
        position: employeeData.position
          ? parseInt(employeeData.position, 10)
          : null,
        work_location: employeeData.work_location
          ? parseInt(employeeData.work_location, 10)
          : null,
        onboarding_mentor: employeeData.onboarding_mentor
          ? parseInt(employeeData.onboarding_mentor, 10)
          : null,
        title: employeeData.title ? parseInt(employeeData.title, 10) : null,
        direct_manager: employeeData.direct_manager
          ? parseInt(employeeData.direct_manager, 10)
          : null,
        projects_m2m: employeeData.projects_m2m
          ? employeeData.projects_m2m.map((id: number) => id)
          : [],
        middle_name: employeeData.middle_name || "",
        employment_status: employeeData.employment_status || "",
        employee_number: employeeData.employee_number || "",
        clients: employeeData.clients || "",
        company: employeeData.company || "",
        teams: employeeData.teams || "",
        is_sponsored_employee: employeeData.is_sponsored_employee || "",
        ni_number: employeeData.ni_number || "",
        soc: employeeData.soc || "",
        weekly_working_hours: employeeData.weekly_working_hours || "",
        job_contract: employeeData.job_contract || null,
      });
    }
  }, [employeeData]);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setEmployeeProfileData({ ...employeeProfileData, [name]: value });
  };

  const handleTagsChange = (newTags: string[]) => {
    setEmployeeProfileData({
      ...employeeProfileData,
      skills: newTags.join(","),
    });
  };

  const handleSingleSelectChange = (
    name: string,
    selectedOption: OptionType | null
  ): void => {
    setEmployeeProfileData({
      ...employeeProfileData,
      [name]: selectedOption ? selectedOption.value : "",
    });
  };

  const handleMultiSelectChange = (
    name: string,
    selectedOptions: OptionType[] | null
  ): void => {
    setEmployeeProfileData({
      ...employeeProfileData,
      [name]: selectedOptions?.map((option) => option.value) || [],
    });
  };

  const projectOptions =
    projectsData?.map((project) => ({
      value: project.id.toString(), // Convert to string
      label: project.project_name,
    })) || [];

  const positionOptions =
    positionData?.map((position) => ({
      value: position.id.toString(), // Convert to string
      label: position.position_name,
    })) || [];

  const workLocationOptions =
    workLocationData?.map((worklocation) => ({
      value: worklocation.id.toString(), // Convert to string
      label: worklocation.location_name,
    })) || [];

  const employeeOptions =
    employeedropdownData?.map((employee) => ({
      value: employee.id.toString(), // Convert to string
      label: employee.employee_fullname,
    })) || [];

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Convert the relevant fields to integers
    const updatedData = {
      ...employeeProfileData,
      position: employeeProfileData.position
        ? parseInt(employeeProfileData.position as unknown as string, 10)
        : null,
      work_location: employeeProfileData.work_location
        ? parseInt(employeeProfileData.work_location as unknown as string, 10)
        : null,
      onboarding_mentor: employeeProfileData.onboarding_mentor
        ? parseInt(
            employeeProfileData.onboarding_mentor as unknown as string,
            10
          )
        : null,
      title: employeeProfileData.title
        ? parseInt(employeeProfileData.title as unknown as string, 10)
        : null,
      direct_manager: employeeProfileData.direct_manager
        ? parseInt(employeeProfileData.direct_manager as unknown as string, 10)
        : null,
      projects_m2m: employeeProfileData.projects_m2m.map((id) =>
        parseInt(id as unknown as string, 10)
      ),
    };

    patchEmployee({ employee_uuid, updatedData })
      .unwrap()
      .then(() => {
        // Handle successful update
        setCardView({ profile: true });
      })
      .catch((error) => {
        // Handle error
      });
  };

  return (
    <Card>
      <CardHeader className="d-flex">
        <h4 className="card-title flex-grow-1 mb-0">Work Profile Edit</h4>
      </CardHeader>
      <LoadingOverlay
        active={isLoadingEmployee}
        spinner
        text="Fetching..."
      >
        <CardBody>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="title" className="form-label">
                    Title
                  </Label>
                  <Select
                    options={titleOptions}
                    value={
                      employeeProfileData.title
                        ? {
                            value: employeeProfileData.title.toString(),
                            label: Title.find(
                              (title) =>
                                title.id.toString() ===
                                employeeProfileData.title?.toString()
                            )?.name,
                          }
                        : null
                    }
                    onChange={(option: OptionType | null) =>
                      handleSingleSelectChange("title", option)
                    }
                  />
                </div>
              </Col>
              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="first_name" className="form-label">
                    First Name
                  </Label>
                  <Input
                    type="text"
                    className="form-control"
                    id="first_name"
                    name="first_name"
                    placeholder="Enter your first name"
                    value={employeeProfileData.first_name}
                    onChange={handleInputChange}
                  />
                </div>
              </Col>
              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="middle_name" className="form-label">
                    Middle Name
                  </Label>
                  <Input
                    type="text"
                    className="form-control"
                    id="middle_name"
                    name="middle_name"
                    placeholder="Enter your middle name"
                    value={employeeProfileData.middle_name}
                    onChange={handleInputChange}
                  />
                </div>
              </Col>
              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="last_name" className="form-label">
                    Last Name
                  </Label>
                  <Input
                    type="text"
                    className="form-control"
                    id="last_name"
                    name="last_name"
                    placeholder="Enter your last name"
                    value={employeeProfileData.last_name}
                    onChange={handleInputChange}
                  />
                </div>
              </Col>
              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="work_phone" className="form-label">
                    Work Phone
                  </Label>
                  <Input
                    type="text"
                    className="form-control"
                    id="work_phone"
                    name="work_phone"
                    placeholder="Enter your work phone"
                    value={employeeProfileData.work_phone}
                    onChange={handleInputChange}
                  />
                </div>
              </Col>
              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="work_email" className="form-label">
                    Work Email
                  </Label>
                  <Input
                    type="email"
                    className="form-control"
                    id="work_email"
                    name="work_email"
                    placeholder="Enter your work email"
                    value={employeeProfileData.work_email}
                    onChange={handleInputChange}
                  />
                </div>
              </Col>
              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="position" className="form-label">
                    Position
                  </Label>
                  <Select
                    options={positionOptions}
                    value={
                      employeeProfileData.position
                        ? {
                            value: employeeProfileData.position.toString(),
                            label: positionData?.find(
                              (position) =>
                                position.id.toString() ===
                                employeeProfileData.position?.toString()
                            )?.position_name,
                          }
                        : null
                    }
                    onChange={(option: OptionType | null) =>
                      handleSingleSelectChange("position", option)
                    }
                  />
                </div>
              </Col>
              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="work_location" className="form-label">
                    Work Location
                  </Label>
                  <Select
                    options={workLocationOptions}
                    value={
                      employeeProfileData.work_location
                        ? {
                            value: employeeProfileData.work_location.toString(),
                            label: workLocationData?.find(
                              (worklocation) =>
                                worklocation.id.toString() ===
                                employeeProfileData.work_location?.toString()
                            )?.location_name,
                          }
                        : null
                    }
                    onChange={(option: OptionType | null) =>
                      handleSingleSelectChange("work_location", option)
                    }
                  />
                </div>
              </Col>
              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="onboarding_mentor" className="form-label">
                    Onboarding Mentor
                  </Label>
                  <Select
                    options={employeeOptions}
                    value={
                      employeeProfileData.onboarding_mentor
                        ? {
                            value:
                              employeeProfileData.onboarding_mentor.toString(),
                            label: employeedropdownData?.find(
                              (employee: any) =>
                                employee.id.toString() ===
                                employeeProfileData.onboarding_mentor?.toString()
                            )?.employee_fullname,
                          }
                        : null
                    }
                    onChange={(option: OptionType | null) =>
                      handleSingleSelectChange("onboarding_mentor", option)
                    }
                  />
                </div>
              </Col>
              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="direct_manager" className="form-label">
                    Direct Manager
                  </Label>
                  <Select
                    options={employeeOptions}
                    value={
                      employeeProfileData.direct_manager
                        ? {
                            value:
                              employeeProfileData.direct_manager.toString(),
                            label: employeedropdownData?.find(
                              (employee: any) =>
                                employee.id.toString() ===
                                employeeProfileData.direct_manager?.toString()
                            )?.employee_fullname,
                          }
                        : null
                    }
                    onChange={(option: OptionType | null) =>
                      handleSingleSelectChange("direct_manager", option)
                    }
                  />
                </div>
              </Col>
              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="projects_m2m" className="form-label">
                    Projects
                  </Label>
                  <Select
                    isMulti
                    options={projectOptions}
                    value={projectOptions.filter((option: any) =>
                      employeeProfileData.projects_m2m.includes(option.value)
                    )}
                    onChange={(selectedOptions: OptionType[] | null) =>
                      handleMultiSelectChange("projects_m2m", selectedOptions)
                    }
                  />
                </div>
              </Col>
              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="employee_start_date" className="form-label">
                    Employee Start Date
                  </Label>
                  <Flatpickr
                    className="form-control"
                    id="employee_start_date"
                    name="employee_start_date"
                    value={employeeProfileData.employee_start_date}
                    onChange={(date: Date[]) =>
                      setEmployeeProfileData({
                        ...employeeProfileData,
                        employee_start_date: date[0]
                          .toISOString()
                          .split("T")[0],
                      })
                    }
                  />
                </div>
              </Col>
              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="skills" className="form-label">
                    Skills
                  </Label>
                  <TagsInput
                    value={employeeProfileData.skills.split(",")}
                    onChange={handleTagsChange}
                    inputProps={{ placeholder: "Add a skill" }}
                  />
                </div>
              </Col>
              <Col lg={12}>
                <div className="hstack gap-2 justify-content-end">
                  <Button type="submit" color="primary">
                    {patchEmployeeloading ? (
                      <span className="d-flex align-items-center">
                        <Spinner size="sm" className="flex-shrink-0" />
                        <span className="flex-grow-1 ms-2">Loading...</span>
                      </span>
                    ) : (
                      <>Update</>
                    )}
                  </Button>
                  <Button
                    type="button"
                    color="soft-success"
                    onClick={() => {
                      setCardView({ profile: true });
                    }}
                    disabled={patchEmployeeloading}
                  >
                    Cancel
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </LoadingOverlay>
    </Card>
  );
};
