import React from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from "reactstrap";

//import images
import avatar8 from "../../../../assets/images/users/avatar-8.jpg";
import avatar10 from "../../../../assets/images/users/avatar-10.jpg";
import avatar6 from "../../../../assets/images/users/avatar-6.jpg";
import avatar2 from "../../../../assets/images/users/avatar-2.jpg";
import avatar3 from "../../../../assets/images/users/avatar-3.jpg";
import avatar4 from "../../../../assets/images/users/avatar-4.jpg";
import avatar7 from "../../../../assets/images/users/avatar-7.jpg";
import TaskTracking from "../TaskTracking";

//SimpleBar
import SimpleBar from "simplebar-react";

interface ProjectProps {
  projectData: any;
}

const OverviewTab: React.FC<ProjectProps> = ({ projectData }) => {
  console.log("projectData", projectData);
  const Priority = (props: any) => {
    return (
      <React.Fragment>
        {props.value === "Medium" ? (
          <span className="badge bg-warning text-uppercase">{props.value}</span>
        ) : props.value === "High" ? (
          <span className="badge bg-danger text-uppercase">{props.value}</span>
        ) : props.value === "Low" ? (
          <span className="badge bg-success text-uppercase">{props.value}</span>
        ) : (
          <span className="badge bg-dark text-uppercase">{props.value}</span>
        )}
      </React.Fragment>
    );
  };

  const Status = (props: any) => {
    return (
      <React.Fragment>
        {props.value === "In Progress" ? (
          <span className="badge bg-secondary-subtle text-secondary text-uppercase">
            {props.value}
          </span>
        ) : props.value === "Assigned" ? (
          <span className="badge bg-info-subtle text-info text-uppercase">
            {props.value}
          </span>
        ) : props.value === "Completed" ? (
          <span className="badge bg-success-subtle text-success text-uppercase">
            {props.value}
          </span>
        ) : props.value === "Pending Review" ? (
          <span className="badge bg-warning-subtle text-warning text-uppercase">
            {props.value}
          </span>
        ) : props.value === "In Review" ? (
          <span className="badge bg-info-subtle text-info text-uppercase">
            {props.value}
          </span>
        ) : (
          <span className="badge bg-dark-subtle text-dark text-uppercase">
            {props.value}
          </span>
        )}
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <Row>
        <Col xl={9} lg={8}>
          <Card>
            <CardHeader>
              <h4>Task Title: &nbsp;&nbsp;{projectData?.title}</h4>
            </CardHeader>
            <CardBody>
              <div className="text-muted">
                <h6 className="mb-3 fw-semibold text-uppercase">Description</h6>
                <p>{projectData?.description}</p>

                <div className="pt-3 border-top border-top-dashed mt-4">
                  <Row>
                    <Col lg={3} sm={6}>
                      <div>
                        <p className="mb-2 text-uppercase fw-medium">
                          Project Name :
                        </p>
                        <h5 className="fs-15 mb-0">
                          {projectData?.get_employee_projects}
                        </h5>
                      </div>
                    </Col>
                    <Col lg={3} sm={6}>
                      <div>
                        <p className="mb-2 text-uppercase fw-medium">
                          Reporter :
                        </p>
                        <h5 className="fs-15 mb-0">
                          {projectData?.get_employee_reporter}
                        </h5>
                      </div>
                    </Col>
                    <Col lg={3} sm={6}>
                      <div>
                        <p className="mb-2 text-uppercase fw-medium">
                          Assigned To :
                        </p>
                        <h5 className="fs-15 mb-0">
                          <div className="avatar-group">
                            {projectData?.get_employee_assignee_obj.map(
                              (item: any, index: any) =>
                                item.assignee_img_url ? (
                                  <Link
                                    key={index}
                                    to="#"
                                    className="avatar-group-item"
                                    title={item.assignee_name}
                                  >
                                    <img
                                      src={item.assignee_img_url}
                                      alt=""
                                      className="rounded-circle avatar-xxs"
                                    />
                                  </Link>
                                ) : (
                                  <Link
                                    key={index}
                                    to="#"
                                    className="avatar-group-item"
                                    title={item.assignee_name}
                                  >
                                    <div className="flex-shrink-0 avatar-xxs">
                                      <div className="avatar-title bg-success-subtle text-success rounded-circle fs-13">
                                        {item.assignee_name.charAt(0)}
                                      </div>
                                    </div>
                                  </Link>
                                )
                            )}
                          </div>
                        </h5>
                      </div>
                    </Col>
                    {/* <Col lg={3} sm={6}>
                      <div>
                        <p className="mb-2 text-uppercase fw-medium">
                          Priority :
                        </p>
                        <h5 className="fs-15 mb-0">
                          <Priority
                            value={projectData?.get_employee_priority}
                          />
                        </h5>
                      </div>
                    </Col> */}
                    {/* <Col lg={3} sm={6}>
                                            <div>
                                                <p className="mb-2 text-uppercase fw-medium">Priority :</p>
                                                <div className="badge bg-danger fs-12">High</div>
                                            </div>
                                        </Col> */}
                    {/* <Col lg={3} sm={6}>
                      <div>
                        <p className="mb-2 text-uppercase fw-medium">
                          Status :
                        </p>
                        <div className="badge fs-12">
                          <Status value={projectData?.task_status_name} />
                        </div>
                      </div>
                    </Col> */}
                  </Row>
                </div>
                <div className="pt-3 border-top border-top-dashed mt-4">
                  <Row>
                    <Col lg={3} sm={6}>
                      <div>
                        <p className="mb-2 text-uppercase fw-medium">
                          Due Date :
                        </p>
                        <h5 className="fs-15 mb-0">
                          {projectData?.due_date}
                        </h5>
                      </div>
                    </Col>

                    <Col lg={3} sm={6}>
                      <div>
                        <p className="mb-2 text-uppercase fw-medium">
                          Priority :
                        </p>
                        <h5 className="fs-15 mb-0">
                          <Priority
                            value={projectData?.get_employee_priority}
                          />
                        </h5>
                      </div>
                    </Col>

                    <Col lg={3} sm={6}>
                      <div>
                        <p className="mb-2 text-uppercase fw-medium">
                          Status :
                        </p>
                        <div className="badge fs-12">
                          <Status value={projectData?.task_status_name} />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>

                {/* <div className="pt-3 border-top border-top-dashed mt-4">
                  <h6 className="mb-3 fw-semibold text-uppercase">
                    Attachment
                  </h6>
                  <Row className="g-3">
                    <Col xxl={4} lg={6}>
                      <div className="border rounded border-dashed p-2">
                        <div className="d-flex align-items-center">
                          <div className="flex-shrink-0 me-3">
                            <div className="avatar-sm">
                              <div className="avatar-title bg-light text-secondary rounded fs-24 shadow">
                                <i className="ri-folder-zip-line"></i>
                              </div>
                            </div>
                          </div>
                          <div className="flex-grow-1 overflow-hidden">
                            <h5 className="fs-13 mb-1">
                              <Link
                                to="#"
                                className="text-body text-truncate d-block"
                              >
                                App pages.zip
                              </Link>
                            </h5>
                            <div>2.2MB</div>
                          </div>
                          <div className="flex-shrink-0 ms-2">
                            <div className="d-flex gap-1">
                              <button
                                type="button"
                                className="btn btn-icon text-muted btn-sm fs-18 shadow-none"
                              >
                                <i className="ri-download-2-line"></i>
                              </button>
                              <UncontrolledDropdown>
                                <DropdownToggle
                                  tag="button"
                                  className="btn btn-icon text-muted btn-sm fs-18 dropdown shadow-none"
                                >
                                  <i className="ri-more-fill"></i>
                                </DropdownToggle>
                                <DropdownMenu>
                                  <li>
                                    <DropdownItem>
                                      <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>{" "}
                                      Rename
                                    </DropdownItem>
                                  </li>
                                  <li>
                                    <DropdownItem>
                                      <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i>{" "}
                                      Delete
                                    </DropdownItem>
                                  </li>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>

                    <Col xxl={4} lg={6}>
                      <div className="border rounded border-dashed p-2">
                        <div className="d-flex align-items-center">
                          <div className="flex-shrink-0 me-3">
                            <div className="avatar-sm">
                              <div className="avatar-title bg-light text-secondary rounded fs-24 shadow">
                                <i className="ri-file-ppt-2-line"></i>
                              </div>
                            </div>
                          </div>
                          <div className="flex-grow-1 overflow-hidden">
                            <h5 className="fs-13 mb-1">
                              <Link
                                to="#"
                                className="text-body text-truncate d-block"
                              >
                                Velzon admin.ppt
                              </Link>
                            </h5>
                            <div>2.4MB</div>
                          </div>
                          <div className="flex-shrink-0 ms-2">
                            <div className="d-flex gap-1">
                              <button
                                type="button"
                                className="btn btn-icon text-muted btn-sm fs-18 shadow-none"
                              >
                                <i className="ri-download-2-line"></i>
                              </button>
                              <UncontrolledDropdown>
                                <DropdownToggle
                                  tag="button"
                                  className="btn btn-icon text-muted btn-sm fs-18 dropdown shadow-none"
                                >
                                  <i className="ri-more-fill"></i>
                                </DropdownToggle>
                                <DropdownMenu>
                                  <li>
                                    <DropdownItem>
                                      <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>{" "}
                                      Rename
                                    </DropdownItem>
                                  </li>
                                  <li>
                                    <DropdownItem>
                                      <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i>{" "}
                                      Delete
                                    </DropdownItem>
                                  </li>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div> */}
              </div>
            </CardBody>
          </Card>
        </Col>

        <Col xl={3} lg={4}>
          <TaskTracking getTaskById={projectData} />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default OverviewTab;
