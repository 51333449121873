import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  useGetProductByIdQuery,
  useCreateStockMutation,
  useGetStockByProductQuery,
  useUpdateStockMutation,
  useGetStockByIdQuery,
} from "slices/resourceInventory/inventorySlice";
import Loader from "../../../Components/Common/Loader";
import {
  Card,
  CardBody,
  CardHeader,
  Container,
  Button,
  Table,
  Row,
  Col,
  Spinner,
} from "reactstrap";
import BreadCrumb from "Components/Common/BreadCrumb";
import { useFormik } from "formik";
import * as Yup from "yup";
import LoadingOverlay from "react-loading-overlay-ts";

const ProductDetailView = () => {
  const { productId } = useParams();
  const navigate = useNavigate();

  const { data: product, isLoading: isProductLoading } =
    useGetProductByIdQuery(productId);
  const {
    data: stocks,
    isLoading: isStocksLoading,
    refetch: refetchStock,
  } = useGetStockByProductQuery(productId);

  const [createStock, { isLoading: createStockIsLoading }] =
    useCreateStockMutation();
  const [updateStock, { isLoading: updateStockIsLoading }] =
    useUpdateStockMutation();

  const [editStockId, setEditStockId] = useState<number | null>(null);

  const { data: stockToEdit, isLoading: editStockIsLoading } =
    useGetStockByIdQuery(editStockId, {
      skip: !editStockId,
    });

  const validationSchema = Yup.object({
    quantity: Yup.number()
      .required("Quantity is required")
      .positive("Quantity must be a positive number"),
    price: Yup.string().required("Price is required"),
    batch_number: Yup.string().nullable(""),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      quantity: stockToEdit?.quantity || "",
      price: stockToEdit?.price || "",
      batch_number: stockToEdit?.batch_number || "",
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        if (editStockId) {
          await updateStock({ id: editStockId, data: values }).unwrap();
        } else {
          await createStock({ product: productId, ...values }).unwrap();
        }
        formik.resetForm({});
        refetchStock();
        setEditStockId(null); // Reset the edit state
      } catch (error) {
        console.error("Error submitting the form:", error);
      }
    },
  });

  useEffect(() => {
    // Reset the form and editStockId when editStockId changes
    if (editStockId === null) {
      formik.resetForm();
    }
  }, [editStockId]);

  if (isProductLoading || isStocksLoading) return <Loader />;

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Product Detail" pageTitle="Product" />
          <Row>
            <Col className="col-md-8">
              <Card>
                <CardHeader className="d-flex">
                  <h4 className="card-title flex-grow-1 mb-0">
                    Product Detail
                  </h4>
                </CardHeader>
                <CardBody>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="table-card">
                        <table className="table mb-0">
                          <tbody>
                            <tr>
                              <td
                                className="fw-medium"
                                style={{ width: "300px" }}
                              >
                                Name
                              </td>
                              <td>{product?.name}</td>
                            </tr>
                            <tr>
                              <td className="fw-medium">Category</td>
                              <td>{product?.category}</td>
                            </tr>
                            <tr>
                              <td className="fw-medium">Supplier</td>
                              <td>{product?.supplier}</td>
                            </tr>
                            <tr>
                              <td className="fw-medium">Measurement</td>
                              <td>{product?.measurement}</td>
                            </tr>
                            <tr>
                              <td className="fw-medium">Supplier Address</td>
                              <td>{product?.supplier_address}</td>
                            </tr>
                            <tr>
                              <td className="fw-medium">Description</td>
                              <td>{product?.description}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col className="col-md-4">
              <Card>
                <CardHeader className="d-flex">
                  <h4 className="card-title flex-grow-1 mb-0">
                    {editStockId ? "Edit Stock" : "Add Stock"}
                  </h4>
                </CardHeader>
                <CardBody>
                  <div className="col-md-12">
                    <LoadingOverlay
                      active={editStockIsLoading}
                      spinner
                      text="Fetching..."
                    >
                      <div className="mx-4 border-secondary">
                        <form onSubmit={formik.handleSubmit}>
                          <div className="mb-3">
                            <label
                              htmlFor="batch_number"
                              className="form-label"
                            >
                              Batch Number
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="batch_number"
                              name="batch_number"
                              value={formik.values.batch_number}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            {formik.touched.batch_number &&
                            typeof formik.errors.batch_number === "string" ? (
                              <div className="text-danger">
                                {formik.errors.batch_number}
                              </div>
                            ) : null}
                          </div>
                          <div className="mb-3">
                            <label htmlFor="quantity" className="form-label">
                              Quantity
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              id="quantity"
                              name="quantity"
                              value={formik.values.quantity}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            {formik.touched.quantity &&
                            typeof formik.errors.quantity === "string" ? (
                              <div className="text-danger">
                                {formik.errors.quantity}
                              </div>
                            ) : null}
                          </div>
                          <div className="mb-3">
                            <label htmlFor="price" className="form-label">
                              Price
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="price"
                              name="price"
                              value={formik.values.price}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            {formik.touched.price &&
                            typeof formik.errors.price === "string" ? (
                              <div className="text-danger">
                                {formik.errors.price}
                              </div>
                            ) : null}
                          </div>
                          <Button
                            type="submit"
                            color="primary"
                            disabled={
                              createStockIsLoading || updateStockIsLoading
                            }
                          >
                            {createStockIsLoading || updateStockIsLoading ? (
                              <Spinner size="sm" />
                            ) : editStockId ? (
                              "Update Stock"
                            ) : (
                              "Add Stock"
                            )}
                          </Button>
                          &nbsp;&nbsp;
                          <Button
                            onClick={() => {
                              formik.resetForm();
                              setEditStockId(null);
                            }}
                            color="danger"
                            disabled={
                              createStockIsLoading || updateStockIsLoading
                            }
                          >
                            Clear
                          </Button>
                        </form>
                      </div>
                    </LoadingOverlay>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <Container fluid>
          <Card>
            <CardBody>
              <h5 className="mt-4">Stock List</h5>
              <hr />
              <Table striped>
                <thead>
                  <tr>
                    <th>Batch Number</th>
                    <th>Quantity</th>
                    <th>Price</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {stocks?.map((stock: any) => (
                    <tr key={stock.id}>
                      <td>{stock.batch_number}</td>
                      <td>{stock.quantity}</td>
                      <td>{stock.price}</td>
                      <td>
                        <Button
                          onClick={() => {
                            setEditStockId(stock.id);
                          }}
                          color="primary"
                        >
                          <i className="ri-pencil-fill fs-16"></i>
                        </Button>
                        &nbsp;
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Container>
      </div>
    </>
  );
};

export default ProductDetailView;
