import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  Spinner,
} from "reactstrap";
import { useCreateSectionDeliveredMutation } from "slices/resourceInventory/inventorySlice";
import { Formik, Form as FormikForm, ErrorMessage } from "formik";
import * as Yup from "yup";

interface DeliveredModalProps {
  isOpen: boolean;
  toggle: () => void;
  productId: number | string;
  refetchInventory: () => void;
}

const DeliveredModal: React.FC<DeliveredModalProps> = ({
  isOpen,
  toggle,
  productId,
  refetchInventory,
}) => {
  // Hook to perform the create section delivered mutation
  const [createSectionDelivered, { isLoading: isCreating }] =
    useCreateSectionDeliveredMutation();

  console.log('productId', productId);

  // Validation schema for the form
  const validationSchema = Yup.object({
    quantity: Yup.number().required("Quantity is required"),
    activity_log: Yup.string().required("Activity log is required"),
    comments: Yup.string().required("Comments are required"),
  });

  // Initial form values
  const initialValues = {
    quantity: "",
    activity_log: "",
    comments: "",
  };

  // Form submission handler
  const handleSubmit = async (
    values: typeof initialValues,
    { resetForm }: { resetForm: () => void }
  ) => {
    try {
      // Perform the API call to create the delivered section
      await createSectionDelivered({
        ...values,
        product: productId,
      });
      // Close the modal, refresh inventory, and reset the form
      toggle();
      refetchInventory();
      resetForm();
    } catch (error) {
      console.error("Error adding delivered section:", error);
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Add Delivered Section</ModalHeader>
      <hr/>
      <ModalBody>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ handleChange, handleBlur, values, handleSubmit }) => (
            <FormikForm onSubmit={handleSubmit}>
              <FormGroup>
                <Label for="quantity">Quantity</Label>
                <Input
                  type="text"
                  id="quantity"
                  name="quantity"
                  value={values.quantity}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <ErrorMessage
                  name="quantity"
                  component="div"
                  className="text-danger"
                />
              </FormGroup>
              <FormGroup>
                <Label for="activity_log">Activity Log</Label>
                <Input
                  type="textarea"
                  id="activity_log"
                  name="activity_log"
                  value={values.activity_log}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <ErrorMessage
                  name="activity_log"
                  component="div"
                  className="text-danger"
                />
              </FormGroup>
              <FormGroup>
                <Label for="comments">Comments</Label>
                <Input
                  type="textarea"
                  id="comments"
                  name="comments"
                  value={values.comments}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <ErrorMessage
                  name="comments"
                  component="div"
                  className="text-danger"
                />
              </FormGroup>
              <ModalFooter>
                <Button
                  color="primary"
                  type="submit"
                  disabled={isCreating}
                >
                  {isCreating ? (
                    <>
                      <Spinner size="sm" />
                      &nbsp;Saving...
                    </>
                  ) : (
                    "Save Changes"
                  )}
                </Button>
                <Button color="secondary" onClick={toggle} disabled={isCreating}>
                  Cancel
                </Button>
              </ModalFooter>
            </FormikForm>
          )}
        </Formik>
      </ModalBody>
    </Modal>
  );
};

export default DeliveredModal;
