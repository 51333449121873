import React, { useState, useEffect, ChangeEvent } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  Input,
  Label,
  Row,
  Button,
  Spinner,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import Select from "react-select";
import Flatpickr from "react-flatpickr";
import {
  useGetEmployeePersonalInfoQuery,
  usePatchEmployeePersonalInfoMutation,
  useGetEmployeeCountryOfIssueQuery,
} from "slices/employee/employeeSlice";
import { MaritialStatus, Gender } from "utils/CommonUtils";
import LoadingOverlay from "react-loading-overlay-ts";
import { format } from "date-fns";

interface OptionType {
  value: string;
  label: string;
  id: string;
  name: string;
  country_of_issue: string;
}

interface PersonalInfoEditProps {
  employee_uuid: string | undefined;
  setCardView: React.Dispatch<React.SetStateAction<Record<string, boolean>>>;
}

export const PersonalInfoEdit: React.FC<PersonalInfoEditProps> = ({
  employee_uuid,
  setCardView,
}) => {
  const {
    data: personalInfoData,
    isLoading: isLoadingPersonalInfo,
    isError,
    refetch,
  } = useGetEmployeePersonalInfoQuery(employee_uuid);
  const [patchPersonalInfo, { isLoading: patchPersonalInfoLoading }] =
    usePatchEmployeePersonalInfoMutation();
  const { data: countryData } = useGetEmployeeCountryOfIssueQuery(undefined);

  const genderOptions = Gender.map((gender) => ({
    value: gender.id.toString(),
    label: gender.name,
  }));

  const maritalStatusOptions = MaritialStatus.map((status) => ({
    value: status.id.toString(),
    label: status.name,
  }));

  const countryOptions =
    countryData?.map((country: OptionType) => ({
      value: country.id?.toString(),
      label: country.country_of_issue,
    })) || [];

  const validationSchema = Yup.object({
    personal_email: Yup.string()
      .email("Invalid email address")
      .required("Personal email is required"),
    mobile_phone: Yup.string()
      .required("Mobile phone is required")
      .matches(/^\d+$/, "Mobile phone must be digits only"),
    home_phone: Yup.string().matches(/^\d+$/, "Home phone must be digits only"),
    address1: Yup.string().required("Address Line 1 is required"),
    city: Yup.string().required("City is required"),
    state: Yup.string().required("State is required"),
    zip_code: Yup.string().required("Zip Code is required"),
    nationality: Yup.string().required("Nationality is required"),
    date_of_birth: Yup.string().required("Date of Birth is required"),
    gender: Yup.number().nullable().required("Gender is required"),
    marital_status: Yup.number()
      .nullable()
      .required("Marital Status is required"),
    country: Yup.number().nullable().required("Country is required"),
  });

  const formik = useFormik({
    initialValues: {
      personal_email: "",
      mobile_phone: "",
      home_phone: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      zip_code: "",
      nationality: "",
      date_of_birth: "",
      gender: null,
      marital_status: null,
      country: "",
    },
    validationSchema,
    onSubmit: (values) => {
      patchPersonalInfo({ employee_uuid, ...values })
        .unwrap()
        .then(() => {
          setCardView({ personalInfo: true });
          refetch();
        })
        .catch((error) => {
          console.error("Error updating personal info:", error);
        });
    },
  });

  useEffect(() => {
    if (personalInfoData) {
      formik.setValues({
        personal_email: personalInfoData.personal_email || "",
        mobile_phone: personalInfoData.mobile_phone || "",
        home_phone: personalInfoData.home_phone || "",
        address1: personalInfoData.address1 || "",
        address2: personalInfoData.address2 || "",
        city: personalInfoData.city || "",
        state: personalInfoData.state || "",
        zip_code: personalInfoData.zip_code || "",
        nationality: personalInfoData.nationality || "",
        date_of_birth: personalInfoData.date_of_birth || "",
        gender: personalInfoData.gender || null,
        marital_status: personalInfoData.marital_status || null,
        country: personalInfoData.country || null,
      });
    }
  }, [personalInfoData]);

  return (
    <Card>
      <CardHeader className="d-flex">
        <h4 className="card-title flex-grow-1 mb-0">Personal Info Edit</h4>
      </CardHeader>
      <LoadingOverlay active={isLoadingPersonalInfo} spinner text="Fetching...">
        <CardBody>
          <Form onSubmit={formik.handleSubmit}>
            <Row>
              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="personal_email" className="form-label">
                    Personal Email
                  </Label>
                  <Input
                    type="email"
                    className="form-control"
                    id="personal_email"
                    name="personal_email"
                    placeholder="Enter your personal email"
                    value={formik.values.personal_email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    invalid={
                      !!formik.errors.personal_email &&
                      formik.touched.personal_email
                    }
                  />
                  {formik.touched.personal_email &&
                    formik.errors.personal_email && (
                      <div className="text-danger">
                        {formik.errors.personal_email}
                      </div>
                    )}
                </div>
              </Col>
              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="mobile_phone" className="form-label">
                    Mobile Phone
                  </Label>
                  <Input
                    type="text"
                    className="form-control"
                    id="mobile_phone"
                    name="mobile_phone"
                    placeholder="Enter your mobile phone number"
                    value={formik.values.mobile_phone}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    invalid={
                      !!formik.errors.mobile_phone &&
                      formik.touched.mobile_phone
                    }
                  />
                  {formik.touched.mobile_phone &&
                    formik.errors.mobile_phone && (
                      <div className="text-danger">
                        {formik.errors.mobile_phone}
                      </div>
                    )}
                </div>
              </Col>
              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="home_phone" className="form-label">
                    Home Phone
                  </Label>
                  <Input
                    type="text"
                    className="form-control"
                    id="home_phone"
                    name="home_phone"
                    placeholder="Enter your home phone number"
                    value={formik.values.home_phone}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    invalid={
                      !!formik.errors.home_phone && formik.touched.home_phone
                    }
                  />
                  {formik.touched.home_phone && formik.errors.home_phone && (
                    <div className="text-danger">
                      {formik.errors.home_phone}
                    </div>
                  )}
                </div>
              </Col>
              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="address1" className="form-label">
                    Address Line 1
                  </Label>
                  <Input
                    type="text"
                    className="form-control"
                    id="address1"
                    name="address1"
                    placeholder="Enter your address line 1"
                    value={formik.values.address1}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    invalid={
                      !!formik.errors.address1 && formik.touched.address1
                    }
                  />
                  {formik.touched.address1 && formik.errors.address1 && (
                    <div className="text-danger">{formik.errors.address1}</div>
                  )}
                </div>
              </Col>
              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="address2" className="form-label">
                    Address Line 2
                  </Label>
                  <Input
                    type="text"
                    className="form-control"
                    id="address2"
                    name="address2"
                    placeholder="Enter your address line 2"
                    value={formik.values.address2}
                    onChange={formik.handleChange}
                  />
                </div>
              </Col>
              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="city" className="form-label">
                    City
                  </Label>
                  <Input
                    type="text"
                    className="form-control"
                    id="city"
                    name="city"
                    placeholder="Enter your city"
                    value={formik.values.city}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    invalid={!!formik.errors.city && formik.touched.city}
                  />
                  {formik.touched.city && formik.errors.city && (
                    <div className="text-danger">{formik.errors.city}</div>
                  )}
                </div>
              </Col>
              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="state" className="form-label">
                    State
                  </Label>
                  <Input
                    type="text"
                    className="form-control"
                    id="state"
                    name="state"
                    placeholder="Enter your state"
                    value={formik.values.state}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    invalid={!!formik.errors.state && formik.touched.state}
                  />
                  {formik.touched.state && formik.errors.state && (
                    <div className="text-danger">{formik.errors.state}</div>
                  )}
                </div>
              </Col>
              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="zip_code" className="form-label">
                    Zip Code
                  </Label>
                  <Input
                    type="text"
                    className="form-control"
                    id="zip_code"
                    name="zip_code"
                    placeholder="Enter your zip code"
                    value={formik.values.zip_code}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    invalid={
                      !!formik.errors.zip_code && formik.touched.zip_code
                    }
                  />
                  {formik.touched.zip_code && formik.errors.zip_code && (
                    <div className="text-danger">{formik.errors.zip_code}</div>
                  )}
                </div>
              </Col>
              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="nationality" className="form-label">
                    Nationality
                  </Label>
                  <Input
                    type="text"
                    className="form-control"
                    id="nationality"
                    name="nationality"
                    placeholder="Enter your nationality"
                    value={formik.values.nationality}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    invalid={
                      !!formik.errors.nationality && formik.touched.nationality
                    }
                  />
                  {formik.touched.nationality && formik.errors.nationality && (
                    <div className="text-danger">
                      {formik.errors.nationality}
                    </div>
                  )}
                </div>
              </Col>
              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="date_of_birth" className="form-label">
                    Date of Birth
                  </Label>
                  <Flatpickr
                    className="form-control"
                    id="date_of_birth"
                    name="date_of_birth"
                    placeholder="Select date of birth"
                    value={
                      formik.values.date_of_birth
                        ? new Date(formik.values.date_of_birth)
                        : null
                    }
                    options={{ dateFormat: "Y-m-d" }}
                    onChange={(date: Date[]) => {
                      formik.setFieldValue(
                        "date_of_birth",
                        format(date[0], "yyyy-MM-dd")
                      );
                    }}
                  />
                  {formik.touched.date_of_birth &&
                    formik.errors.date_of_birth && (
                      <div className="text-danger">
                        {formik.errors.date_of_birth}
                      </div>
                    )}
                </div>
              </Col>
              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="gender" className="form-label">
                    Gender
                  </Label>
                  <Select
                    id="gender"
                    name="gender"
                    options={genderOptions}
                    placeholder="Select gender"
                    value={genderOptions.find(
                      (option) =>
                        option.value === 
                      (formik.values.gender ?? "").toString()
                    )}
                    onChange={(option: any) =>
                      formik.setFieldValue("gender", option?.value)
                    }
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.gender && formik.errors.gender && (
                    <div className="text-danger">{formik.errors.gender}</div>
                  )}
                </div>
              </Col>
              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="marital_status" className="form-label">
                    Marital Status
                  </Label>
                  <Select
                    id="marital_status"
                    name="marital_status"
                    options={maritalStatusOptions}
                    placeholder="Select marital status"
                    value={maritalStatusOptions.find(
                      (option) =>
                        option.value ===
                        (formik.values.marital_status ?? "").toString()
                    )}
                    onChange={(option: any) =>
                      formik.setFieldValue("marital_status", option?.value)
                    }
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.marital_status &&
                    formik.errors.marital_status && (
                      <div className="text-danger">
                        {formik.errors.marital_status}
                      </div>
                    )}
                </div>
              </Col>
              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="country" className="form-label">
                    Country of Issue
                  </Label>
                  <Select
                    id="country"
                    name="country"
                    options={countryOptions}
                    placeholder="Select country"
                    value={countryOptions.find(
                      (option: any) =>
                        option.value ===
                        (formik.values.country ?? "").toString()
                    )}
                    onChange={(option: any) =>
                      formik.setFieldValue("country", option?.value)
                    }
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.country && formik.errors.country && (
                    <div className="text-danger">{formik.errors.country}</div>
                  )}
                </div>
              </Col>
            </Row>
            <div className="d-flex justify-content-end mt-4">
              <Button
                color="light"
                type="button"
                onClick={() => setCardView({ personalInfo: true })}
                className="me-2"
              >
                Cancel
              </Button>
              <Button
                color="primary"
                type="submit"
                disabled={patchPersonalInfoLoading}
              >
                {patchPersonalInfoLoading ? <Spinner size="sm" /> : "Save"}
              </Button>
            </div>
          </Form>
        </CardBody>
      </LoadingOverlay>
    </Card>
  );
};
