// WorkflowProjectModal.tsx
import React, { useState, useEffect } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from "reactstrap";
import { Tree, TreeNode } from 'react-organizational-chart';
import styled from "@emotion/styled";

interface WorkflowProjectModalProps {
  isOpen: boolean;
  toggle: () => void;
  workflowName: string;
  workflowId: number | null;
  projects: Array<{ value: number; label: string }>;
  selectedProjects: number[]; // Array of selected project IDs
  approvals: any; // Assuming the approvals are in a flat array
  onSave: (workflowId: number | null, selectedProjects: number[]) => void;
}

const WorkflowChartModal: React.FC<WorkflowProjectModalProps> = ({
  isOpen,
  toggle,
  workflowName,
  workflowId,
  projects,
  selectedProjects,
  approvals,
  onSave,
}) => {
  const [selectedProjectOptions, setSelectedProjectOptions] = useState<
    Array<{ value: number; label: string }>
  >([]);

  useEffect(() => {
    if (selectedProjects && projects) {
      const initialProjects = projects.filter((project) =>
        selectedProjects.includes(project.value)
      );
      setSelectedProjectOptions(initialProjects);
    }
  }, [selectedProjects, projects]);

  const handleSave = () => {
    const selectedProjectIds = selectedProjectOptions.map(
      (project) => project.value
    );
    onSave(workflowId, selectedProjectIds);
  };

  // Function to convert a flat list to a nested structure
function convertToNestedStructure(approvals: any[]) {
  if (approvals.length === 0) return [];

  // Base case: If there's only one item, it is the root with no children
  if (approvals.length === 1) return [{ ...approvals[0], children: [] }];

  // Create a nested structure
  const root = { ...approvals[0], children: [] };
  let currentLevel = [root];
  
  for (let i = 1; i < approvals.length; i++) {
    const item = { ...approvals[i], children: [] };
    // Determine parent for the current item
    // Each item is a child of the last item in the currentLevel
    currentLevel[currentLevel.length - 1].children.push(item);
    
    // Update currentLevel to include this new item
    currentLevel.push(item);
    
    // Maintain only the latest level of children
    // Remove all but the last level to ensure proper nesting
    if (currentLevel.length > 1) {
      currentLevel = [currentLevel[currentLevel.length - 1]];
    }
  }

  return [root];
}


  type StyledNodeProps = {
    isRequired?: boolean;
  };

  const StyledNode = styled.div<StyledNodeProps>`
    padding: 5px;
    border-radius: 8px;
    display: inline-block;
    border: 1px solid #b7b7b7;
    text-align: center;
    width: 200px;
    // background-color: ${({ isRequired }) => (isRequired ? 'white' : 'gray')};
    background-color: 'white';
    color: 'white';
  `;

  const StyledRootNode = styled.div<StyledNodeProps>`
    padding: 5px;
    border-radius: 0;
    display: inline-block;
    border: 1px solid #b7b7b7;
    text-align: center;
    width: 200px;
    background: #d8d8ff;
    color: 'white';
  `;

  type StyledTreeExampleProps = {
    approvals: any; // Expecting an array
  };

  const StyledTreeExample: React.FC<StyledTreeExampleProps> = ({ approvals }) => {
    const renderTreeNodes = (steps: any) => {
      return steps.map((step: any, i: number) => (
        <TreeNode
          key={step.id}
          label={
            <StyledNode isRequired={step.is_required}>
              <span>
                <span className="fs-6" style={{color: "#503bba"}}><b>Level {step.level} Approval</b></span> 
              <hr/>
              <span className="fs-6"><b>{step.step_name}</b></span> 
              <br/>
              {step.is_required ? <span className="badge border border-danger text-danger fs-6 mt-1">Approval Required</span> : ''}
              <hr/>
              {/* {step.role_name.join(", ")} */}
              {step.role_name?.map((n: any)=>{
                return <><span className="badge border border-primary text-primary fs-6" key={n}>{n}</span> &nbsp;</>
              })}
              </span>
            </StyledNode>
          }
        >
          {step.children && renderTreeNodes(step.children)}
        </TreeNode>
      ));
    };

    return (
      <Tree
        lineWidth={'2px'}
        lineColor={'green'}
        lineBorderRadius={'10px'}
        label={<StyledRootNode isRequired={true}><b>{workflowName}</b></StyledRootNode>}
      >
        {renderTreeNodes(convertToNestedStructure(approvals))}
      </Tree>
    );
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>WorkFlow Preview</ModalHeader>
      <ModalBody>
        <StyledTreeExample approvals={approvals} />
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default WorkflowChartModal;
