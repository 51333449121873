import React, { useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  Input,
  Label,
  Row,
  Button,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  useGetSupplierByIdQuery,
  useCreateSupplierMutation,
  useUpdateSupplierMutation,
  useGetSuppliersQuery,
} from "slices/resourceInventory/inventorySlice";

interface SupplierEditProps {
  supplierId: string | undefined;
  setCardView: React.Dispatch<React.SetStateAction<Record<string, boolean>>>;
}

export const SupplierEdit: React.FC<SupplierEditProps> = ({
  supplierId,
  setCardView,
}) => {
  const {
    data: supplierData,
    isLoading: isLoadingSupplier,
    refetch,
  } = useGetSupplierByIdQuery(supplierId, {
    skip: !supplierId,
  });
  const { refetch: refetchSuppliers } = useGetSuppliersQuery(undefined);
  const [createSupplier, { isLoading: createSupplierLoading }] =
    useCreateSupplierMutation();
  const [updateSupplier, { isLoading: updateSupplierLoading }] =
    useUpdateSupplierMutation();

  // Update validation schema for the new fields
  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    contact_name: Yup.string().required("Contact Name is required"),
    contact_email: Yup.string().email("Invalid email address").nullable(),
    contact_phone: Yup.string().nullable(),
    address: Yup.string().required("Address is required"),
    website: Yup.string().url("Invalid URL").nullable(),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      contact_name: "",
      contact_email: "",
      contact_phone: "",
      address: "",
      website: "",
    },
    validationSchema,
    onSubmit: (values) => {
      if (supplierId) {
        // Update existing supplier
        updateSupplier({ id: supplierId, data: values })
          .unwrap()
          .then(() => {
            refetchSuppliers();
            setCardView({ supplierInfo: true });
          })
          .catch((error) => {
            console.error("Error updating supplier:", error);
          });
      } else {
        // Create new supplier
        createSupplier(values)
          .unwrap()
          .then(() => {
            refetchSuppliers();
            setCardView({ supplierInfo: true });
          })
          .catch((error) => {
            console.error("Error creating supplier:", error);
          });
      }
    },
  });

  useEffect(() => {
    if (supplierData) {
      formik.setValues({
        name: supplierData.name || "",
        contact_name: supplierData.contact_name || "",
        contact_email: supplierData.contact_email || "",
        contact_phone: supplierData.contact_phone || "",
        address: supplierData.address || "",
        website: supplierData.website || "",
      });
    }
  }, [supplierData]);

  return (
    <Card>
      <CardHeader className="d-flex">
        <h4 className="card-title flex-grow-1 mb-0">Supplier Edit</h4>
      </CardHeader>
      <CardBody>
        <Form onSubmit={formik.handleSubmit}>
          <Row>
            <Col lg={6}>
              <div className="mb-3">
                <Label htmlFor="name" className="form-label">
                  Name
                </Label>
                <span className="text-danger">*</span>
                <Input
                  type="text"
                  className="form-control"
                  id="name"
                  name="name"
                  placeholder="Enter name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  invalid={!!formik.errors.name && formik.touched.name}
                />
                {formik.touched.name && formik.errors.name && (
                  <div className="text-danger">{formik.errors.name}</div>
                )}
              </div>
            </Col>
            <Col lg={6}>
              <div className="mb-3">
                <Label htmlFor="contact_name" className="form-label">
                  Contact Name
                </Label>
                <span className="text-danger">*</span>
                <Input
                  type="text"
                  className="form-control"
                  id="contact_name"
                  name="contact_name"
                  placeholder="Enter contact name"
                  value={formik.values.contact_name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  invalid={
                    !!formik.errors.contact_name && formik.touched.contact_name
                  }
                />
                {formik.touched.contact_name && formik.errors.contact_name && (
                  <div className="text-danger">
                    {formik.errors.contact_name}
                  </div>
                )}
              </div>
            </Col>
            <Col lg={6}>
              <div className="mb-3">
                <Label htmlFor="contact_email" className="form-label">
                  Contact Email
                </Label>
                <Input
                  type="email"
                  className="form-control"
                  id="contact_email"
                  name="contact_email"
                  placeholder="Enter contact email"
                  value={formik.values.contact_email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  invalid={
                    !!formik.errors.contact_email &&
                    formik.touched.contact_email
                  }
                />
                {formik.touched.contact_email &&
                  formik.errors.contact_email && (
                    <div className="text-danger">
                      {formik.errors.contact_email}
                    </div>
                  )}
              </div>
            </Col>
            <Col lg={6}>
              <div className="mb-3">
                <Label htmlFor="contact_phone" className="form-label">
                  Contact Phone
                </Label>
                <Input
                  type="text"
                  className="form-control"
                  id="contact_phone"
                  name="contact_phone"
                  placeholder="Enter contact phone"
                  value={formik.values.contact_phone}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  invalid={
                    !!formik.errors.contact_phone &&
                    formik.touched.contact_phone
                  }
                />
                {formik.touched.contact_phone &&
                  formik.errors.contact_phone && (
                    <div className="text-danger">
                      {formik.errors.contact_phone}
                    </div>
                  )}
              </div>
            </Col>
            <Col lg={6}>
              <div className="mb-3">
                <Label htmlFor="address" className="form-label">
                  Address
                </Label>
                <span className="text-danger">*</span>
                <Input
                  type="text"
                  className="form-control"
                  id="address"
                  name="address"
                  placeholder="Enter address"
                  value={formik.values.address}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  invalid={!!formik.errors.address && formik.touched.address}
                />
                {formik.touched.address && formik.errors.address && (
                  <div className="text-danger">{formik.errors.address}</div>
                )}
              </div>
            </Col>
            <Col lg={6}>
              <div className="mb-3">
                <Label htmlFor="website" className="form-label">
                  Website
                </Label>
                <Input
                  type="url"
                  className="form-control"
                  id="website"
                  name="website"
                  placeholder="Enter website"
                  value={formik.values.website}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  invalid={!!formik.errors.website && formik.touched.website}
                />
                {formik.touched.website && formik.errors.website && (
                  <div className="text-danger">{formik.errors.website}</div>
                )}
              </div>
            </Col>
          </Row>
          <Button
            type="submit"
            color="primary"
            disabled={
              isLoadingSupplier ||
              createSupplierLoading ||
              updateSupplierLoading
            }
          >
            {updateSupplierLoading || createSupplierLoading
              ? "Saving..."
              : supplierId
              ? "Update Supplier"
              : "Create Supplier"}
          </Button>
          &nbsp;&nbsp;
          <button
            type="button"
            className="btn btn-danger"
            disabled={createSupplierLoading || updateSupplierLoading}
            onClick={() => setCardView({ supplierInfo: true })}
          >
            Close
          </button>
        </Form>
      </CardBody>
    </Card>
  );
};
