import React, { useMemo, useState, useEffect } from "react";
import { Container, Row, Col, Card, CardHeader, Button } from "reactstrap";
import TableContainer from "../../../Components/Common/TableContainer";
import Loader from "../../../Components/Common/Loader";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { ToastContainer } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { useGetWorkFlowApprovalsQuery } from "slices/workflow/WorkFlowSlice.";

const WorkFlowApprovalsListView = () => {
  const [workflowListData, setWorkFlowListData] = useState<any>([]);
  const navigate = useNavigate();

  const {
    data: workflowData,
    isLoading: isLoadingWorkflows,
    refetch: refetchWorkflows,
  } = useGetWorkFlowApprovalsQuery(undefined);

  useEffect(() => {
    if (workflowData) {
      setWorkFlowListData(workflowData);
    }
  }, [workflowData]);

  const handleViewDetails = (projectId: number) => {
    navigate(`/workflow-approval-details/${projectId}`);
  };

  const getDistinctProducts = (products: [{product_name: string}]) => {
    const seen = new Set();
    return products.filter(product => {
      const isDuplicate = seen.has(product.product_name);
      seen.add(product.product_name);
      return !isDuplicate;
    });
  };

  const columns = useMemo(
    () => [
      {
        Header: "Approval ID",
        accessor: "approval_id",
        Cell: ({ row }: any) => (
          <div>
            {row.original.sections.map((section: any, index: number) => (
                <div>
                  {section.products.map(
                    (product: any, productIndex: number) => (
                      <span
                        key={productIndex}
                        className="badge text-uppercase bg-primary-subtle text-primary me-1"
                      >
                        {product.approval_id}
                      </span>
                    )
                  )}
                </div>
            ))}
          </div>
        ),
        filterable: false,
      },
      {
        Header: "Project Name",
        accessor: "project",
        filterable: false,
      },
      {
        Header: "Sections",
        accessor: "sections",
        Cell: ({ row }: any) => (
          <div>
            {row.original.sections.map((section: any, index: number) => (
              <div key={index} className="mb-2">
                <strong>{section.section_name}</strong>
                <div>
                  {getDistinctProducts(section.products).map((product, productIndex) => (
                    <span
                      key={productIndex}
                      className="badge text-uppercase bg-success-subtle text-success me-1"
                    >
                      {product.product_name}
                    </span>
                  ))}
                </div>
        </div>
      ))}
          </div>
        ),
        filterable: false,
      },
      {
        Header: "Pending Approval",
        accessor: "pending_approval_count",
        Cell: ({ row }: any) => {
          const pendingApprovalCount = row.original.sections.reduce(
            (count: number, section: any) => {
              const pendingProducts = section.products.filter(
                (product: any) => product.overall_status === "Pending Approval"
              );
              return count + pendingProducts.length;
            },
            0
          );
          return (
            <span className="badge bg-primary">{pendingApprovalCount}</span>
          );
        },
        filterable: false,
      },
      {
        Header: "Actions",
        accessor: "actions",
        Cell: ({ row }: any) => {
          const projectId = row.original.project_id;
          return (
            <div>
              <ul className="list-inline hstack gap-2 mb-0">
                <li className="list-inline-item view" title="View Details">
                  <Link
                    to={`/workflow-approval-details/${projectId}`}
                    className="text-info d-inline-block view-item-btn"
                    // onClick={() => handleViewDetails(projectId)}
                  >
                    <i className="ri-eye-fill fs-16"></i>
                  </Link>
                </li>
              </ul>
            </div>
          );
        },
      },
    ],
    []
  );

  document.title = "Workflow Approvals | Dashboard";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Workflow Approvals List" pageTitle="Approvals" />

          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader className="border-0 d-flex justify-content-between align-items-center">
                  <h5 className="card-title mb-0">Approvals List</h5>
                </CardHeader>
                <div className="card-body pt-0">
                  <TableContainer
                    columns={columns}
                    data={workflowListData}
                    isGlobalFilter={true}
                    isAddUserList={false}
                    customPageSize={10}
                    className="custom-header-css"
                    // isCustomerFilter={true}
                    SearchPlaceholder={"Search for approvals..."}
                    isPagination={true}
                    isLoading={isLoadingWorkflows}
                  />
                </div>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
};

export default WorkFlowApprovalsListView;
