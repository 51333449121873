import {
    Row,
    Col,
    Card,
    CardBody,
    CardHeader,
    Modal,
    Form,
    ModalBody,
    ModalHeader,
    Label,
    Input,
    FormFeedback,
    Button,
    Spinner
} from "reactstrap";
import * as Yup from "yup";
import { Link } from 'react-router-dom';
import { useFormik } from "formik";
import { toast } from 'react-toastify';

import Dropzone from "react-dropzone";

// Import Scroll Bar - SimpleBar
import SimpleBar from 'simplebar-react';

//Import Flatepicker
import Flatpickr from "react-flatpickr";
import moment from "moment";

import {
useGetProjectsQuery,
useGetEmployeeDropDownQuery
} from "slices/employee/employeeSlice";

import {
    useGetAllProjectsQuery,
  } from "slices/projects/projectSlice";

import { TASK_STATUS } from '../../../Constance';


interface projectFormModalProps {
    modal: any;
    toggle: any;
    isEdit: boolean;
    selectedFiles: any; 
    setselectedFiles: any;
    isLoadingCreateTask: boolean; 
    setModal: any;
    project: any;
    createProject: any;
    updateProject: any;
    refetchProject: any;
}

const ProjectFormModal: React.FC<projectFormModalProps> = ({ modal, toggle, isEdit, isLoadingCreateTask, selectedFiles, setselectedFiles, setModal, project, createProject, updateProject, refetchProject }) => {

    // const { data: projectsData } = useGetProjectsQuery(undefined);

    // const { data: employeedropdownData } = useGetEmployeeDropDownQuery(undefined);
  
    // const { data: priorityData } = useGetAllProjectsQuery(undefined);

    // console.log('allProjects', project);

    const handleAcceptedFiles = (files: any) => {
        const newFiles = files.map((file: any) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            formattedSize: formatBytes(file.size),
          })
        );
        setselectedFiles((prevFiles: any) => [...prevFiles, ...newFiles]);
      };
    
    const removeFile = (index: any) => {
    setselectedFiles((prevFiles: any) => prevFiles.filter((_: any, i: any) => i !== index));
    };

    /**
  * Formats the size
  */
  function formatBytes(bytes: any, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  const handleCheckboxChange = (e: any) => {
    const { value, checked } = e.target;
    const currentAssignees = validation.values.assignee;
    const intValue = parseInt(value);
  
    if (checked) {
      // If checked, add the value to the array
      validation.setFieldValue("assignee", [...currentAssignees, intValue]);
    } else {
      // If unchecked, remove the value from the array
      validation.setFieldValue("assignee", currentAssignees.filter((id: number) => id !== intValue));
    }
  };

  // Handle file input change
  const handleFileChange = (event: any) => {
    const file = event.currentTarget.files[0];
    validation.setFieldValue('thumbnail', file);
  };

  const validation: any = useFormik({
    enableReinitialize: true,

    initialValues: {
      project_name: project?.project_name || null,
      description: project?.description || null,
      project_actual_cost: project?.project_actual_cost || null,
      project_estimation_cost: project?.project_estimation_cost || null,
      project_allocated_budget: project?.project_allocated_budget || null,
      start_date: project?.start_date || null,
      end_date: project?.end_date || null,
      status: project?.status || null,
      thumbnail: null
    },
    validationSchema: Yup.object({
      project_name: Yup.string().required("Please Enter Project Name"),
      description: Yup.string().required("Please Enter Description"),
      project_actual_cost: Yup.number().required("Please Enter Actual Cost").integer(),
      project_estimation_cost: Yup.number().required("Please Enter Estimation Cost").integer(),
      project_allocated_budget: Yup.number().required("Please Enter Allocated Budget").integer(),
      start_date: Yup.string().required("Please Enter Start Date"),
      end_date: Yup.string().required("Please Enter End Date"),
      status: Yup.string().required("Please Select Status"),
      thumbnail: Yup.string().nullable().optional(),
    }),
    onSubmit: async (values) => {
      console.log('value', values)
      // Handle form submission
      let data = values;
      if (selectedFiles.length > 0) {
        data = { ...values, ...{ files: selectedFiles } }
      }

      if (!isEdit) {
        try {
          await createProject(data).unwrap();
          toast.success("Project created successfully!");
          refetchProject();
          validation.resetForm();
          setselectedFiles([]);
          toggle();
        } catch (error) {
          toast.error("Error creating project");
        }
    } else {
      try {
        await updateProject({ id: project?.id, newProject: data }).unwrap();
        toast.success("Project updated successfully!");
        refetchProject();
        validation.resetForm();
        setselectedFiles([]);
        toggle();
      } catch (error) {
        toast.error("Error updating project");
      }
    }
    },
  });
    
    return <Modal
    isOpen={modal}
    toggle={toggle}
    centered
    size="lg"
    className="border-0"
    modalClassName='modal fade zoomIn'
  >
    <ModalHeader className="p-3 bg-info-subtle" toggle={toggle}>
      {!!isEdit ? "Edit project" : "Create project"}
    </ModalHeader>
    <Form className="tablelist-form" onSubmit={(e: any) => {
      e.preventDefault();
      validation.handleSubmit();
      return false;
    }}>
      <ModalBody className="modal-body">
        <Row className="g-3">
          <Col lg={12}>
            <Label for="projectName-field" className="form-label">Project Name</Label>
            <Input
              name="project_name"
              id="projectName-field"
              className="form-control"
              placeholder="Project Name"
              type="text"
              validate={{
                required: { value: true },
              }}
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.project_name || ""}
              invalid={
                validation.touched.project_name && validation.errors.project_name ? true : false
              }
            />
            {validation.touched.project_name && validation.errors.project_name ? (
              <FormFeedback type="invalid">{validation.errors.project_name}</FormFeedback>
            ) : null}
          </Col>
          <Col lg={12}>
            <Label for="projectThumbnail-field" className="form-label">Project Thumbnail</Label>
            <Input
              className="form-control"
              id="projectThumbnail-img"
              type="file"
              accept="image/png, image/gif, image/jpeg"
              onChange={handleFileChange}
            />
            {validation.touched.thumbnail && validation.errors.thumbnail ? (
              <FormFeedback type="invalid">{validation.errors.thumbnail}</FormFeedback>
            ) : null}
          </Col>
          <Col lg={12}>
            <div>
              <Label for="projectDescription-field" className="form-label">Project Description</Label>
              <Input
                name="description"
                id="projectDescription-field"
                className="form-control"
                placeholder="Add Project Information here..."
                type="textarea"
                validate={{
                  required: { value: true },
                }}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.description || ""}
                invalid={
                  validation.touched.description && validation.errors.description ? true : false
                }
              />
              {validation.touched.description && validation.errors.description ? (
                <FormFeedback type="invalid">{validation.errors.description}</FormFeedback>
              ) : null}
            </div>
          </Col>

          <Col lg={6}>
            <Label for="startdate-field" className="form-label">Start Date</Label>

            <Flatpickr
              name="start_date"
              id="startdate-field"
              className="form-control"
              placeholder="Select a date"
              options={{
                altInput: true,
                altFormat: "d M, Y",
                dateFormat: "d M, Y",
              }}
              onChange={(start_date: any) => validation.setFieldValue("start_date", moment(start_date[0]).format("YYYY-MM-DD"))}
              value={validation.values.start_date || ''}
            />
            {validation.errors.start_date && validation.touched.start_date ? (
              <FormFeedback type="invalid" className='d-block'>{validation.errors.start_date}</FormFeedback>
            ) : null}
          </Col>

          <Col lg={6}>
            <Label for="enddate-field" className="form-label">End Date</Label>

            <Flatpickr
              name="end_date"
              id="endDate-field"
              className="form-control"
              placeholder="Select a date"
              options={{
                altInput: true,
                altFormat: "d M, Y",
                dateFormat: "d M, Y",
              }}
              onChange={(end_date: any) => validation.setFieldValue("end_date", moment(end_date[0]).format("YYYY-MM-DD"))}
              value={validation.values.end_date || ''}
            />
            {validation.errors.end_date && validation.touched.end_date ? (
              <FormFeedback type="invalid" className='d-block'>{validation.errors.end_date}</FormFeedback>
            ) : null}
          </Col>

          <Col lg={6}>
            <Label for="aactualCost-field" className="form-label">Actual Cost</Label>
            <Input
              name="project_actual_cost"
              id="actualCost-field"
              className="form-control"
              placeholder="Actual Cost"
              type="text"
              validate={{
                required: { value: true },
              }}
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.project_actual_cost || ""}
              invalid={
                validation.touched.project_actual_cost && validation.errors.project_actual_cost ? true : false
              }
            />
            {validation.touched.project_actual_cost && validation.errors.project_actual_cost ? (
              <FormFeedback type="invalid">{validation.errors.project_actual_cost}</FormFeedback>
            ) : null}
          </Col>

          <Col lg={6}>
            <Label for="estimation-field" className="form-label">Estimation Cost</Label>
            <Input
              name="project_estimation_cost"
              id="estimation-field"
              className="form-control"
              placeholder="Estimation Cost"
              type="text"
              validate={{
                required: { value: true },
              }}
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.project_estimation_cost || ""}
              invalid={
                validation.touched.project_estimation_cost && validation.errors.project_estimation_cost ? true : false
              }
            />
            {validation.touched.project_estimation_cost && validation.errors.project_estimation_cost ? (
              <FormFeedback type="invalid">{validation.errors.project_estimation_cost}</FormFeedback>
            ) : null}
          </Col>

          <Col lg={6}>
            <Label for="allocated-field" className="form-label">Allocated Budget</Label>
            <Input
              name="project_allocated_budget"
              id="allocated-field"
              className="form-control"
              placeholder="Allocated Budget"
              type="text"
              validate={{
                required: { value: true },
              }}
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.project_allocated_budget || ""}
              invalid={
                validation.touched.project_allocated_budget && validation.errors.project_allocated_budget ? true : false
              }
            />
            {validation.touched.project_allocated_budget && validation.errors.project_allocated_budget ? (
              <FormFeedback type="invalid">{validation.errors.project_allocated_budget}</FormFeedback>
            ) : null}
          </Col>

          <Col lg={6}>
            <Label for="project-status" className="form-label">Status</Label>
            <Input
              name="status"
              type="select"
              className="form-select"
              id="project-status"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.status || ""}
              invalid={
                validation.touched.status && validation.errors.status ? true : false
              }
            >
              <option value="">Status</option>
              {TASK_STATUS?.map((options: any) => (
                <option key={options.id} value={options.id}>
                  {options.name}
                </option>
              ))}
            </Input>
            {validation.touched.status && validation.errors.status ? (
              <FormFeedback type="invalid">{validation.errors.status}</FormFeedback>
            ) : null}
          </Col>

          {!isEdit ?
          <Card>
            <CardHeader >
              <h5 className="card-title mb-0">Attached files</h5>
            </CardHeader>
            <CardBody>
              <div>
                <p className="text-muted">Add Attached files here.</p>

                <Dropzone
                  onDrop={acceptedFiles => {
                    handleAcceptedFiles(acceptedFiles);
                  }}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div className="dropzone dz-clickable">
                      <div
                        className="dz-message needsclick"
                        {...getRootProps()}
                      >
                        <div className="mb-3">
                          <i className="display-4 text-muted ri-upload-cloud-2-fill" />
                        </div>
                        <h4>Drop files here or click to upload.</h4>
                      </div>
                    </div>
                  )}
                </Dropzone>

                <ul className="list-unstyled mb-0" id="dropzone-preview">

                  {selectedFiles.map((f: any, i: any) => {
                    return (
                      <Card
                        className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                        key={i + "-file"}
                      >
                        <div className="p-2">
                          <Row className="align-items-center">
                            <Col className="col-auto">
                              <img
                                data-dz-thumbnail=""
                                height="80"
                                className="avatar-sm rounded bg-light"
                                alt={f.name}
                                src={f.preview}
                              />
                            </Col>
                            <Col>
                              <div style={{ "display": "flex", "justifyContent": "space-between" }}>
                                <div>
                                  <Link
                                    to="#"
                                    className="text-muted font-weight-bold"
                                  >
                                    {f.name}
                                  </Link>
                                  <p className="mb-0">
                                    <strong>{f.formattedSize}</strong>
                                  </p>
                                </div>
                                <div style={{ "float": "left" }} className="flex-shrink-0 ms-3">
                                  <button onClick={() => removeFile(i)} className="btn btn-sm btn-danger">Delete</button>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Card>
                    );
                  })}
                </ul>

              </div>
            </CardBody>
          </Card>
          : ''}

        </Row>
      </ModalBody>
      <div className="modal-footer">
        <div className="hstack gap-2 justify-content-end">
          <Button
            type="button"
            disabled={isLoadingCreateTask}
            onClick={() => {
              setModal(false);
              validation.resetForm();
              setselectedFiles([]);
            }}
            className="btn-light"
          >Close</Button>
          <Button color="success" className="btn-load" disabled={isLoadingCreateTask} type='submit'>
            {isLoadingCreateTask ? <span className="d-flex align-items-center">
              <Spinner size="sm" className="flex-shrink-0" />
              <span className="flex-grow-1 ms-2">
                Loading...
              </span>
            </span> :
              <>{!!isEdit ? "Update project" : "Add project"}</>
            }
          </Button>
        </div>
      </div>
    </Form>
  </Modal>
}

export { ProjectFormModal }