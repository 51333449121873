import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Container,
} from "reactstrap";
import classnames from "classnames";

//import images
import slack from "../../../assets/images/brands/slack.png";
import InspectionReportEditView from "./InspectionReportEdit";
import DocumentsTab from "./DocumentsTab";
import ActivitiesTab from "./ActivitiesTab";
import TeamTab from "./TeamTab";
import { useParams } from "react-router-dom";
import { useGetInspectionReportByIdQuery } from "slices/InspectionReport/InspectionReportSlice";
import { InspectionReport } from "slices/InspectionReport/type";

const ReportEditOverview = () => {
  // Extract reportId from useParams
  const { reportId } = useParams<{reportId ?: string }>();
  const numericId = Number(reportId);
  const [reportData, setReportData] = useState<InspectionReport | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  // Query to fetch report data
  const {
    data,
    refetch,
    isFetching,
    error: queryError,
  } = useGetInspectionReportByIdQuery(numericId);

  useEffect(() => {
    if (reportId) {
      setLoading(true);
      refetch()
        .then((result) => {
          if (result.data) {
            setReportData(result.data);
          } else {
            setError("No data found");
          }
        })
        .catch((err) => {
          setError("An error occurred while fetching data");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [reportId, refetch]);

  //Tab state
  const [activeTab, setActiveTab] = useState("1");
  const toggleTab = (tab: string) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg={12}>
              <Card className="mt-n4 mx-n4">
                <div className="bg-warning-subtle">
                  <CardBody className="pb-0 px-4">
                    <Row className="mb-3">
                      <div className="col-md">
                        <Row className="align-items-center g-3">
                          <div className="col-md-auto">
                            <div className="avatar-md">
                              <div className="avatar-title bg-white rounded-circle">
                                <img src={slack} alt="" className="avatar-xs" />
                              </div>
                            </div>
                          </div>
                          <div className="col-md">
                            <div>
                              <h4 className="fw-bold">
                                {loading
                                  ? "Loading..."
                                  : error
                                  ? "Error"
                                  : reportData?.parent_company_name}
                              </h4>
                              <div className="hstack gap-3 flex-wrap">
                                <div>
                                  <i className="ri-building-line align-bottom me-1"></i>{" "}
                                  {loading
                                    ? "Loading..."
                                    : error
                                    ? "Error"
                                    : reportData?.project_name}
                                </div>
                                <div className="vr"></div>
                                <div>
                                  Start Date :{" "}
                                  <span className="fw-medium">
                                    {loading
                                      ? "Loading..."
                                      : error
                                      ? "Error"
                                      : reportData?.start_date}
                                  </span>
                                </div>
                                <div className="vr"></div>
                                <div>
                                  Due Date :{" "}
                                  <span className="fw-medium">
                                    {loading
                                      ? "Loading..."
                                      : error
                                      ? "Error"
                                      : reportData?.due_date}
                                  </span>
                                </div>
                                <div className="vr"></div>
                                <div className="badge rounded-pill bg-info fs-12">
                                  {loading
                                    ? "Loading..."
                                    : error
                                    ? "Error"
                                    : reportData?.report_status}
                                </div>
                                <div className="badge rounded-pill bg-danger fs-12">
                                  High
                                </div>
                              </div>
                            </div>
                          </div>
                        </Row>
                      </div>
                      <div className="col-md-auto">
                        <div className="hstack gap-1 flex-wrap">
                          <button
                            type="button"
                            className="btn py-0 fs-16 favourite-btn active shadow-none"
                          >
                            <i className="ri-star-fill"></i>
                          </button>
                          <button
                            type="button"
                            className="btn py-0 fs-16 text-body shadow-none"
                          >
                            <i className="ri-share-line"></i>
                          </button>
                          <button
                            type="button"
                            className="btn py-0 fs-16 text-body shadow-none"
                          >
                            <i className="ri-flag-line"></i>
                          </button>
                        </div>
                      </div>
                    </Row>

                    <Nav
                      className="nav-tabs-custom border-bottom-0"
                      role="tablist"
                    >
                      <NavItem>
                        <NavLink
                          className={classnames(
                            { active: activeTab === "1" },
                            "fw-semibold"
                          )}
                          onClick={() => {
                            toggleTab("1");
                          }}
                          href="#"
                        >
                          Overview
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames(
                            { active: activeTab === "2" },
                            "fw-semibold"
                          )}
                          onClick={() => {
                            toggleTab("2");
                          }}
                          href="#"
                        >
                          Documents
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames(
                            { active: activeTab === "3" },
                            "fw-semibold"
                          )}
                          onClick={() => {
                            toggleTab("3");
                          }}
                          href="#"
                        >
                          Activities
                        </NavLink>
                      </NavItem>
                      {/* <NavItem>
                        <NavLink
                          className={classnames(
                            { active: activeTab === "4" },
                            "fw-semibold"
                          )}
                          onClick={() => {
                            toggleTab("4");
                          }}
                          href="#"
                        >
                          Team
                        </NavLink>
                      </NavItem> */}
                    </Nav>
                  </CardBody>
                </div>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              {loading && <div>Loading...</div>}
              {error && <div>{error}</div>}
              <TabContent activeTab={activeTab} className="text-muted">
                <TabPane tabId="1">
                  <InspectionReportEditView reportData={reportData} reportId={numericId} refetch={refetch} />
                </TabPane>
                <TabPane tabId="2">
                  <DocumentsTab />
                </TabPane>
                <TabPane tabId="3">
                  <ActivitiesTab />
                </TabPane>
                <TabPane tabId="4">
                  <TeamTab />
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ReportEditOverview;
