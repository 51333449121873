import React, { useState, useCallback, useEffect } from "react";
import { Container, Col, Row } from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
// import Summary from './Summary';
import Summary from "./TaskOverview/Section";
import TaskTracking from "./TaskTracking";
import { useParams } from "react-router-dom";
// Formik
import { ToastContainer } from "react-toastify";
import { TaskFormModal } from "../TaskList/Modal/TaskForm";
import {
  useGetTasksByIdQuery,
  useUpdateTaskMutation,
} from "slices/tasks/taskSlice";

const TaskDetails = () => {
  document.title = "Task Details | Velzon - React Admin & Dashboard Template";
  const [task, setTask] = useState<any>([]);
  //Dropzone file upload
  const [selectedFiles, setselectedFiles] = useState<any>([]);
  const [isEdit] = useState<boolean>(true);
  const { task_id } = useParams<{ task_id: string }>();
  const [modal, setModal] = useState<boolean>(false);
  const {
    data: getTaskById,
    isLoading,
    refetch: refetchTasks,
    error: taskError,
  } = useGetTasksByIdQuery(task_id);
  const [updateTask, { isLoading: isLoadingUpdateTask }] =
    useUpdateTaskMutation();

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      setTask(null);
    } else {
      setModal(true);
    }
  }, [modal]);

  useEffect(() => {
    refetchTasks();
  }, []);

  return (
    <React.Fragment>
      <Container fluid>
        {/* <BreadCrumb title="Task Details" pageTitle="Tasks" /> */}

        <Summary getTaskById={getTaskById} setTask={setTask} toggle={toggle} />

        <TaskFormModal
          modal={modal}
          toggle={toggle}
          isEdit={isEdit}
          isLoadingCreateTask={isLoadingUpdateTask}
          selectedFiles={selectedFiles}
          setselectedFiles={setselectedFiles}
          setModal={setModal}
          task={task}
          createTask={null}
          updateTask={updateTask}
          refetchTasks={refetchTasks}
        />
        <ToastContainer closeButton={false} limit={1} />
      </Container>
    </React.Fragment>
  );
};

export default TaskDetails;
