import React from "react";
import { Navigate } from "react-router-dom";

//Dashboard
import DashboardProject from "../pages/DashboardProject";

// Project
import ProjectList from "../pages/Projects/ProjectList";
import CreateProject from "../pages/Projects/CreateProject";
import ProjectOverview from "pages/Projects/ProjectOverview/Section";

//Task
import TaskDetails from "../pages/Tasks/TaskDetails";
import TaskList from "../pages/Tasks/TaskList";

//AuthenticationInner pages
import BasicSignIn from "../pages/AuthenticationInner/Login/BasicSignIn";
import CoverSignIn from "../pages/AuthenticationInner/Login/CoverSignIn";
import BasicSignUp from "../pages/AuthenticationInner/Register/BasicSignUp";
import CoverSignUp from "../pages/AuthenticationInner/Register/CoverSignUp";
import BasicPasswReset from "../pages/AuthenticationInner/PasswordReset/BasicPasswReset";
//pages
import SimplePage from "../pages/Pages/Profile/SimplePage/SimplePage";
import Settings from "../pages/Pages/Profile/Settings/Settings";

import CoverPasswReset from "../pages/AuthenticationInner/PasswordReset/CoverPasswReset";
import BasicLockScreen from "../pages/AuthenticationInner/LockScreen/BasicLockScr";
import CoverLockScreen from "../pages/AuthenticationInner/LockScreen/CoverLockScr";
import BasicLogout from "../pages/AuthenticationInner/Logout/BasicLogout";
import CoverLogout from "../pages/AuthenticationInner/Logout/CoverLogout";
import BasicSuccessMsg from "../pages/AuthenticationInner/SuccessMessage/BasicSuccessMsg";
import CoverSuccessMsg from "../pages/AuthenticationInner/SuccessMessage/CoverSuccessMsg";
import BasicTwosVerify from "../pages/AuthenticationInner/TwoStepVerification/BasicTwosVerify";
import CoverTwosVerify from "../pages/AuthenticationInner/TwoStepVerification/CoverTwosVerify";
import Basic404 from "../pages/AuthenticationInner/Errors/Basic404";
import Cover404 from "../pages/AuthenticationInner/Errors/Cover404";
import Alt404 from "../pages/AuthenticationInner/Errors/Alt404";
import Error500 from "../pages/AuthenticationInner/Errors/Error500";

import EcommerceOrders from "../pages/Ecommerce/EcommerceOrders/index";

import BasicPasswCreate from "../pages/AuthenticationInner/PasswordCreate/BasicPasswCreate";
import CoverPasswCreate from "../pages/AuthenticationInner/PasswordCreate/CoverPasswCreate";
import Offlinepage from "../pages/AuthenticationInner/Errors/Offlinepage";

//login
import Login from "../pages/Authentication/Login";
import ForgetPasswordPage from "../pages/Authentication/ForgetPassword";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";

// User Profile
import UserProfile from "../pages/Authentication/user-profile";

import Kanbanboard from "pages/Tasks/KanbanBoard";

import EcommerceCustomers from "../pages/Ecommerce/EcommerceCustomers/index";

import EmployeeProfile from "pages/Ecommerce/EmployeeProfile";
import UiRatings from "../pages/AdvanceUi/UiRatings/UiRatings";

import InspectionReportListView from "pages/InspectionReport/InspectionReportList/InspectionReportListView";
import ReportFormWizard from "pages/InspectionReport/InspectionReportWizard/ReportWizard";

import ReportOverview from "pages/InspectionReport/InspectionReportView/Section";

import ReportEditOverview from "pages/InspectionReport/InspectionReportEdit/Section";

import WorkflowListView from "pages/WorkFlow/WorkFlowList/WorkFlowListView";
import RoleAccessList from "pages/Roles/RoleAccessList/RoleAccessList";

import MeasurementsListView from "pages/Inventory/Measurements/MeasurementsList";
import SupplierListView from "pages/Inventory/Suppliers/SuppliersListView";
import CategoriesListView from "pages/Inventory/categories/CategoriesListView";
import ProductListView from "pages/Inventory/Products/ProductListView";
import StockListView from "pages/Inventory/Stocks/StocksListView";
import SupplierDetails from "pages/Inventory/Suppliers/SuppliersView";
import ProductDetailView from "pages/Inventory/Products/ProductView";

import InventoryDashBoard from "pages/Inventory/DashBoard/InventoryDashbord";

import PageAccessListView from "pages/Roles/pageAcess/pageAcessList";

import WorkFlowApprovalsListView from "pages/WorkFlow/WorkFlowApprovels/WorkFlowApprovelsList";
import WorkFlowApprovalsOverview from "pages/WorkFlow/WorkFlowApprovels/WorkFlowApprovelsOverview/Section";
import SelectRole from "pages/Authentication/selectRoles";

const authProtectedRoutes = [
  { path: "/dashboard", component: <DashboardProject /> },
  { path: "/index", component: <DashboardProject /> },

  //page_access
  { path: "/page-access-list", component: <PageAccessListView /> },

  //inventory
  {
    path: "/inventory-dashboard/:projectId",
    component: <InventoryDashBoard />,
  },
  { path: "/stock-list", component: <StockListView /> },
  { path: "/measurment-list", component: <MeasurementsListView /> },
  { path: "/supplier-list", component: <SupplierListView /> },
  { path: "/supplier-view/:supplierId", component: <SupplierDetails /> },
  { path: "/category-list", component: <CategoriesListView /> },
  { path: "/product-list", component: <ProductListView /> },
  { path: "/product-view/:productId", component: <ProductDetailView /> },

  //roleAccess
  { path: "/roles-list", component: <RoleAccessList /> },

  //workflow
  { path: "/workflow-list", component: <WorkflowListView /> },
  { path: "/workflowaccess-list", component: <WorkFlowApprovalsListView /> },
  {
    path: "/workflow-approval-details/:projectId",
    component: <WorkFlowApprovalsOverview />,
  },

  //reports
  { path: "/report-edit/:reportId", component: <ReportEditOverview /> },
  { path: "/report-view/:id", component: <ReportOverview /> },
  { path: "/report-list", component: <InspectionReportListView /> },
  { path: "/inspection-report", component: <ReportFormWizard /> },

  //Projects
  { path: "/apps-projects-list", component: <ProjectList /> },
  {
    path: "/apps-projects-overview/:project_id",
    component: <ProjectOverview />,
  },
  { path: "/apps-projects-create", component: <CreateProject /> },

  //Task
  // { path:"/apps-tasks-kanban", component: <Kanbanboard/> },

  { path: "/apps-tasks-list-view-create", component: <TaskList /> },
  { path: "/apps-tasks-list-view", component: <TaskList /> },
  { path: "/apps-tasks-details/:task_id", component: <TaskDetails /> },

  //Pages
  { path: "/pages-profile/:employee_uuid", component: <SimplePage /> },
  { path: "/profile/:employee_uuid", component: <EmployeeProfile /> },
  { path: "/pages-profile-settings/:employee_uuid", component: <Settings /> },

  //User Profile
  { path: "/profile", component: <UserProfile /> },

  { path: "/apps-ecommerce-orders", component: <EcommerceOrders /> },
  { path: "/apps-ecommerce-customers", component: <EcommerceCustomers /> },

  { path: "/advance-ui-ratings", component: <UiRatings /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
  { path: "*", component: <Navigate to="/dashboard" /> },
];

const publicRoutes = [
  // Authentication Page
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPasswordPage /> },
  { path: "/register", component: <Register /> },

  //role selection
  { path: "/role-select", component: <SelectRole /> },

  //AuthenticationInner pages
  { path: "/auth-signin-basic", component: <BasicSignIn /> },
  { path: "/auth-signin-cover", component: <CoverSignIn /> },
  { path: "/auth-signup-basic", component: <BasicSignUp /> },
  { path: "/auth-signup-cover", component: <CoverSignUp /> },
  { path: "/auth-pass-reset-basic", component: <BasicPasswReset /> },
  { path: "/auth-pass-reset-cover", component: <CoverPasswReset /> },
  { path: "/auth-lockscreen-basic", component: <BasicLockScreen /> },
  { path: "/auth-lockscreen-cover", component: <CoverLockScreen /> },
  { path: "/auth-logout-basic", component: <BasicLogout /> },
  { path: "/auth-logout-cover", component: <CoverLogout /> },
  { path: "/auth-success-msg-basic", component: <BasicSuccessMsg /> },
  { path: "/auth-success-msg-cover", component: <CoverSuccessMsg /> },
  { path: "/auth-twostep-basic", component: <BasicTwosVerify /> },
  { path: "/auth-twostep-cover", component: <CoverTwosVerify /> },
  { path: "/auth-404-basic", component: <Basic404 /> },
  { path: "/auth-404-cover", component: <Cover404 /> },
  { path: "/auth-404-alt", component: <Alt404 /> },
  { path: "/auth-500", component: <Error500 /> },

  { path: "/auth-pass-change-basic", component: <BasicPasswCreate /> },
  { path: "/auth-pass-change-cover", component: <CoverPasswCreate /> },
  { path: "/auth-offline", component: <Offlinepage /> },
];

export { authProtectedRoutes, publicRoutes };
