import React, { useState } from "react";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
} from "reactstrap";
import classnames from "classnames";

//import Components
import BreadCrumb from "../../../Components/Common/BreadCrumb";

import { Accordion, AccordionItem, Collapse } from "reactstrap";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

const UiRatings = () => {
  const [fillCol1, setfillCol1] = useState(true);
  const [fillCol2, setfillCol2] = useState<boolean>(false);
  const [fillCol3, setfillCol3] = useState<boolean>(false);

  const t_fillCol1 = () => {
    setfillCol1(!fillCol1);
    setfillCol2(false);
    setfillCol3(false);
  };

  const t_fillCol2 = () => {
    setfillCol2(!fillCol2);
    setfillCol1(false);
    setfillCol3(false);
  };

  const t_fillCol3 = () => {
    setfillCol3(!fillCol3);
    setfillCol1(false);
    setfillCol2(false);
  };

  document.title = "Ratings | Velzon - React Admin & Dashboard Template";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Resource Dashboard" pageTitle="Dashboard" />
          <Row>
            <Card>
              <CardHeader>
                <div className="d-flex align-items-center flex-wrap gap-2">
                  <div className="flex-grow-1">
                    <button
                      className="btn btn-info add-btn"
                      onClick={() => {
                        //   setModal(true);
                      }}
                    >
                      <i className="ri-add-fill me-1 align-bottom"></i> Add
                      Resource
                    </button>
                  </div>
                  <div className="flex-shrink-0">
                    <div className="hstack text-nowrap gap-2">
                      {/* {isMultiDeleteButton && <button className="btn btn-soft-danger" id="remove-actions"
                          onClick={() => setDeleteModalMulti(true)}
                        ><i className="ri-delete-bin-2-line"></i></button>} */}
                      <button className="btn btn-secondary">
                        <i className="ri-filter-2-line me-1 align-bottom"></i>{" "}
                        Filters
                      </button>
                      {/* <button className="btn btn-soft-success" onClick={() => setIsExportCSV(true)}>Export</button> */}

                      <UncontrolledDropdown>
                        <DropdownToggle
                          href="#"
                          className="btn btn-soft-info"
                          tag="button"
                        >
                          <i className="ri-more-2-fill"></i>
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-end">
                          <DropdownItem className="dropdown-item" href="#">
                            All
                          </DropdownItem>
                          <DropdownItem className="dropdown-item" href="#">
                            Last Week
                          </DropdownItem>
                          <DropdownItem className="dropdown-item" href="#">
                            Last Month
                          </DropdownItem>
                          <DropdownItem className="dropdown-item" href="#">
                            Last Year
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </div>
                </div>
              </CardHeader>
            </Card>

            <Col lg={12}>
              <Accordion
                open={"1"}
                toggle={() => "1"}
                className="custom-accordionwithicon accordion-secondary"
                id="accordionFill"
              >
                <AccordionItem>
                  <h2 className="accordion-header" id="accordionFillExample1">
                    <button
                      className={classnames("accordion-button", {
                        collapsed: !fillCol1,
                      })}
                      type="button"
                      onClick={t_fillCol1}
                      style={{ cursor: "pointer" }}
                    >
                      <h4>Section 1</h4>
                    </button>
                  </h2>
                  <Collapse
                    isOpen={fillCol1}
                    className="accordion-collapse"
                    id="accor_fill1"
                  >
                    <Card>
                      <CardBody>
                        {/* <p className="text-muted">Rater-js is a star rating widget for the browser. here are the various examples of rating shown below with variation.</p> */}
                        <div className="live-preview">
                          <div className="table-responsive">
                            <table className="table align-middle mb-0">
                              <thead>
                                <tr>
                                  <th scope="col" style={{ minWidth: "200px" }}>
                                    Item Name
                                  </th>
                                  <th scope="col" style={{ minWidth: "200px" }}>
                                    Quoted
                                  </th>
                                  <th scope="col" style={{ minWidth: "200px" }}>
                                    Planned
                                  </th>
                                  <th scope="col" style={{ minWidth: "200px" }}>
                                    Procured
                                  </th>
                                  <th scope="col" style={{ minWidth: "200px" }}>
                                    Delivered
                                  </th>
                                  <th scope="col" style={{ minWidth: "200px" }}>
                                    Implemented
                                  </th>
                                  <th scope="col" style={{ minWidth: "200px" }}>
                                    Status
                                  </th>
                                </tr>
                                <tr style={{ border: "white" }}>
                                  <th scope="col" style={{ minWidth: "200px" }}>
                                    <div className="d-flex align-items-center my-4">
                                      <div className="flex-grow-1">
                                        <h4 className="mb-0 fs-11 text-muted text-uppercase">
                                          Product
                                        </h4>
                                      </div>
                                      <div className="flex-shrink-0">
                                        <div
                                          data-bs-toggle="tooltip"
                                          data-bs-trigger="hover"
                                          data-bs-placement="bottom"
                                          aria-label="Add Product"
                                          data-bs-original-title="Add Product"
                                        >
                                          <button
                                            type="button"
                                            className="btn btn-soft-success btn-sm shadow-none"
                                          >
                                            <i className="ri-add-line align-bottom"></i>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </th>
                                  <th
                                    scope="col"
                                    style={{ minWidth: "200px" }}
                                  ></th>
                                  <th
                                    scope="col"
                                    style={{ minWidth: "200px" }}
                                  ></th>
                                  <th
                                    scope="col"
                                    style={{ minWidth: "200px" }}
                                  ></th>
                                  <th
                                    scope="col"
                                    style={{ minWidth: "200px" }}
                                  ></th>
                                  <th
                                    scope="col"
                                    style={{ minWidth: "200px" }}
                                  ></th>
                                  <th scope="col" style={{ minWidth: "200px" }}>
                                    <div className="d-flex align-items-center my-4">
                                      <div className="flex-grow-1">
                                        <h4 className="mb-0 fs-11 text-muted text-uppercase">
                                          Status
                                        </h4>
                                      </div>
                                      <div className="flex-shrink-0">
                                        <div
                                          data-bs-toggle="tooltip"
                                          data-bs-trigger="hover"
                                          data-bs-placement="bottom"
                                          aria-label="Add Product"
                                          data-bs-original-title="Add Product"
                                        >
                                          <button
                                            type="button"
                                            className="btn btn-soft-success btn-sm shadow-none"
                                          >
                                            <i className="ri-add-line align-bottom"></i>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  style={{
                                    background: "aliceblue",
                                    border: "white",
                                  }}
                                >
                                  <th scope="row">
                                    <span className="badge border border-primary text-primary fs-6">
                                      Product 1
                                    </span>
                                  </th>

                                  <th className="text-center" scope="row">
                                    <div className="mb-3">
                                      <div className="badge border border-primary text-primary d-inline-flex align-items-center fs-6 mb-1">
                                        Test Quoted 1
                                        <i
                                          style={{
                                            cursor: "pointer",
                                            fontSize: "15px",
                                            marginLeft: "10px",
                                          }}
                                          className="ri-close-circle-fill"
                                        ></i>
                                      </div>

                                      <div className="badge border border-primary text-primary d-inline-flex align-items-center fs-6 mb-1">
                                        Test Quoted 2
                                        <i
                                          style={{
                                            cursor: "pointer",
                                            fontSize: "15px",
                                            marginLeft: "10px",
                                          }}
                                          className="ri-close-circle-fill"
                                        ></i>
                                      </div>

                                      <div className="badge border border-primary text-primary d-inline-flex align-items-center fs-6 mb-1">
                                        Test Quoted 3
                                        <i
                                          style={{
                                            cursor: "pointer",
                                            fontSize: "15px",
                                            marginLeft: "10px",
                                          }}
                                          className="ri-close-circle-fill"
                                        ></i>
                                      </div>

                                      <div className="badge border border-primary text-primary d-inline-flex align-items-center fs-6 mb-1">
                                        Test Quoted 4
                                        <i
                                          style={{
                                            cursor: "pointer",
                                            fontSize: "15px",
                                            marginLeft: "10px",
                                          }}
                                          className="ri-close-circle-fill"
                                        ></i>
                                      </div>
                                    </div>

                                    <button
                                      type="button"
                                      className="btn btn-soft-primary btn-sm shadow-none"
                                    >
                                      <i className="ri-add-line align-bottom"></i>{" "}
                                      QUOTED
                                    </button>
                                  </th>
                                  <th className="text-center" scope="row">
                                    <div className="mb-3">
                                      <div className="badge border border-primary text-primary d-inline-flex align-items-center fs-6">
                                        Test Planned
                                        <i
                                          style={{
                                            cursor: "pointer",
                                            fontSize: "15px",
                                            marginLeft: "10px",
                                          }}
                                          className="ri-close-circle-fill"
                                        ></i>
                                      </div>
                                    </div>
                                    <button
                                      type="button"
                                      className="btn btn-soft-primary btn-sm shadow-none"
                                    >
                                      <i className="ri-add-line align-bottom"></i>{" "}
                                      PLANNED
                                    </button>
                                  </th>
                                  <th className="text-center" scope="row">
                                    <div className="mb-3">
                                      <div className="badge border border-primary text-primary d-inline-flex align-items-center fs-6">
                                        Test Procured
                                        <i
                                          style={{
                                            cursor: "pointer",
                                            fontSize: "15px",
                                            marginLeft: "10px",
                                          }}
                                          className="ri-close-circle-fill"
                                        ></i>
                                      </div>
                                    </div>
                                    <button
                                      type="button"
                                      className="btn btn-soft-primary btn-sm shadow-none"
                                    >
                                      <i className="ri-add-line align-bottom"></i>{" "}
                                      PROCURED
                                    </button>
                                  </th>
                                  <th className="text-center" scope="row">
                                    <div className="mb-3">
                                      <div className="badge border border-primary text-primary d-inline-flex align-items-center fs-6">
                                        Test Delivered
                                        <i
                                          style={{
                                            cursor: "pointer",
                                            fontSize: "15px",
                                            marginLeft: "10px",
                                          }}
                                          className="ri-close-circle-fill"
                                        ></i>
                                      </div>
                                    </div>
                                    <button
                                      type="button"
                                      className="btn btn-soft-primary btn-sm shadow-none"
                                    >
                                      <i className="ri-add-line align-bottom"></i>{" "}
                                      Delivered
                                    </button>
                                  </th>
                                  <th className="text-center" scope="row">
                                    <div className="mb-3">
                                      <div className="badge border border-primary text-primary d-inline-flex align-items-center fs-6">
                                        Test Implemented
                                        <i
                                          style={{
                                            cursor: "pointer",
                                            fontSize: "15px",
                                            marginLeft: "10px",
                                          }}
                                          className="ri-close-circle-fill"
                                        ></i>
                                      </div>
                                    </div>
                                    <button
                                      type="button"
                                      className="btn btn-soft-primary btn-sm shadow-none"
                                    >
                                      <i className="ri-add-line align-bottom"></i>{" "}
                                      IMPLEMENTED
                                    </button>
                                  </th>
                                  <th className="text-center" scope="row">
                                    <div className="badge border border-primary text-primary d-inline-flex align-items-center fs-6">
                                      Test Status
                                      <i
                                        style={{
                                          cursor: "pointer",
                                          fontSize: "15px",
                                          marginLeft: "10px",
                                        }}
                                        className="ri-close-circle-fill"
                                      ></i>
                                    </div>
                                  </th>
                                </tr>
                                <tr
                                  style={{
                                    background: "aliceblue",
                                    border: "white",
                                  }}
                                >
                                  <th scope="row">
                                    <span className="badge border border-primary text-primary fs-6">
                                      Product 1
                                    </span>
                                  </th>

                                  <th className="text-center" scope="row">
                                    <div className="mb-3">
                                      <div className="badge border border-primary text-primary d-inline-flex align-items-center fs-6 mb-1">
                                        Test Quoted 1
                                        <i
                                          style={{
                                            cursor: "pointer",
                                            fontSize: "15px",
                                            marginLeft: "10px",
                                          }}
                                          className="ri-close-circle-fill"
                                        ></i>
                                      </div>

                                      <div className="badge border border-primary text-primary d-inline-flex align-items-center fs-6 mb-1">
                                        Test Quoted 2
                                        <i
                                          style={{
                                            cursor: "pointer",
                                            fontSize: "15px",
                                            marginLeft: "10px",
                                          }}
                                          className="ri-close-circle-fill"
                                        ></i>
                                      </div>

                                      <div className="badge border border-primary text-primary d-inline-flex align-items-center fs-6 mb-1">
                                        Test Quoted 3
                                        <i
                                          style={{
                                            cursor: "pointer",
                                            fontSize: "15px",
                                            marginLeft: "10px",
                                          }}
                                          className="ri-close-circle-fill"
                                        ></i>
                                      </div>

                                      <div className="badge border border-primary text-primary d-inline-flex align-items-center fs-6 mb-1">
                                        Test Quoted 4
                                        <i
                                          style={{
                                            cursor: "pointer",
                                            fontSize: "15px",
                                            marginLeft: "10px",
                                          }}
                                          className="ri-close-circle-fill"
                                        ></i>
                                      </div>
                                    </div>

                                    <button
                                      type="button"
                                      className="btn btn-soft-primary btn-sm shadow-none"
                                    >
                                      <i className="ri-add-line align-bottom"></i>{" "}
                                      QUOTED
                                    </button>
                                  </th>
                                  <th className="text-center" scope="row">
                                    <div className="mb-3">
                                      <div className="badge border border-primary text-primary d-inline-flex align-items-center fs-6">
                                        Test Planned
                                        <i
                                          style={{
                                            cursor: "pointer",
                                            fontSize: "15px",
                                            marginLeft: "10px",
                                          }}
                                          className="ri-close-circle-fill"
                                        ></i>
                                      </div>
                                    </div>
                                    <button
                                      type="button"
                                      className="btn btn-soft-primary btn-sm shadow-none"
                                    >
                                      <i className="ri-add-line align-bottom"></i>{" "}
                                      PLANNED
                                    </button>
                                  </th>
                                  <th className="text-center" scope="row">
                                    <div className="mb-3">
                                      <div className="badge border border-primary text-primary d-inline-flex align-items-center fs-6">
                                        Test Procured
                                        <i
                                          style={{
                                            cursor: "pointer",
                                            fontSize: "15px",
                                            marginLeft: "10px",
                                          }}
                                          className="ri-close-circle-fill"
                                        ></i>
                                      </div>
                                    </div>
                                    <button
                                      type="button"
                                      className="btn btn-soft-primary btn-sm shadow-none"
                                    >
                                      <i className="ri-add-line align-bottom"></i>{" "}
                                      PROCURED
                                    </button>
                                  </th>
                                  <th className="text-center" scope="row">
                                    <div className="mb-3">
                                      <div className="badge border border-primary text-primary d-inline-flex align-items-center fs-6">
                                        Test Delivered
                                        <i
                                          style={{
                                            cursor: "pointer",
                                            fontSize: "15px",
                                            marginLeft: "10px",
                                          }}
                                          className="ri-close-circle-fill"
                                        ></i>
                                      </div>
                                    </div>
                                    <button
                                      type="button"
                                      className="btn btn-soft-primary btn-sm shadow-none"
                                    >
                                      <i className="ri-add-line align-bottom"></i>{" "}
                                      Delivered
                                    </button>
                                  </th>
                                  <th className="text-center" scope="row">
                                    <div className="mb-3">
                                      <div className="badge border border-primary text-primary d-inline-flex align-items-center fs-6">
                                        Test Implemented
                                        <i
                                          style={{
                                            cursor: "pointer",
                                            fontSize: "15px",
                                            marginLeft: "10px",
                                          }}
                                          className="ri-close-circle-fill"
                                        ></i>
                                      </div>
                                    </div>
                                    <button
                                      type="button"
                                      className="btn btn-soft-primary btn-sm shadow-none"
                                    >
                                      <i className="ri-add-line align-bottom"></i>{" "}
                                      IMPLEMENTED
                                    </button>
                                  </th>
                                  <th className="text-center" scope="row">
                                    <div className="badge border border-primary text-primary d-inline-flex align-items-center fs-6">
                                      Test Status
                                      <i
                                        style={{
                                          cursor: "pointer",
                                          fontSize: "15px",
                                          marginLeft: "10px",
                                        }}
                                        className="ri-close-circle-fill"
                                      ></i>
                                    </div>
                                  </th>
                                </tr>
                                <tr
                                  style={{
                                    background: "aliceblue",
                                    border: "white",
                                  }}
                                >
                                  <th scope="row">
                                    <span className="badge border border-primary text-primary fs-6">
                                      Product 1
                                    </span>
                                  </th>

                                  <th className="text-center" scope="row">
                                    <div className="mb-3">
                                      <div className="badge border border-primary text-primary d-inline-flex align-items-center fs-6 mb-1">
                                        Test Quoted 1
                                        <i
                                          style={{
                                            cursor: "pointer",
                                            fontSize: "15px",
                                            marginLeft: "10px",
                                          }}
                                          className="ri-close-circle-fill"
                                        ></i>
                                      </div>

                                      <div className="badge border border-primary text-primary d-inline-flex align-items-center fs-6 mb-1">
                                        Test Quoted 2
                                        <i
                                          style={{
                                            cursor: "pointer",
                                            fontSize: "15px",
                                            marginLeft: "10px",
                                          }}
                                          className="ri-close-circle-fill"
                                        ></i>
                                      </div>

                                      <div className="badge border border-primary text-primary d-inline-flex align-items-center fs-6 mb-1">
                                        Test Quoted 3
                                        <i
                                          style={{
                                            cursor: "pointer",
                                            fontSize: "15px",
                                            marginLeft: "10px",
                                          }}
                                          className="ri-close-circle-fill"
                                        ></i>
                                      </div>

                                      <div className="badge border border-primary text-primary d-inline-flex align-items-center fs-6 mb-1">
                                        Test Quoted 4
                                        <i
                                          style={{
                                            cursor: "pointer",
                                            fontSize: "15px",
                                            marginLeft: "10px",
                                          }}
                                          className="ri-close-circle-fill"
                                        ></i>
                                      </div>
                                    </div>

                                    <button
                                      type="button"
                                      className="btn btn-soft-primary btn-sm shadow-none"
                                    >
                                      <i className="ri-add-line align-bottom"></i>{" "}
                                      QUOTED
                                    </button>
                                  </th>
                                  <th className="text-center" scope="row">
                                    <div className="mb-3">
                                      <div className="badge border border-primary text-primary d-inline-flex align-items-center fs-6">
                                        Test Planned
                                        <i
                                          style={{
                                            cursor: "pointer",
                                            fontSize: "15px",
                                            marginLeft: "10px",
                                          }}
                                          className="ri-close-circle-fill"
                                        ></i>
                                      </div>
                                    </div>
                                    <button
                                      type="button"
                                      className="btn btn-soft-primary btn-sm shadow-none"
                                    >
                                      <i className="ri-add-line align-bottom"></i>{" "}
                                      PLANNED
                                    </button>
                                  </th>
                                  <th className="text-center" scope="row">
                                    <div className="mb-3">
                                      <div className="badge border border-primary text-primary d-inline-flex align-items-center fs-6">
                                        Test Procured
                                        <i
                                          style={{
                                            cursor: "pointer",
                                            fontSize: "15px",
                                            marginLeft: "10px",
                                          }}
                                          className="ri-close-circle-fill"
                                        ></i>
                                      </div>
                                    </div>
                                    <button
                                      type="button"
                                      className="btn btn-soft-primary btn-sm shadow-none"
                                    >
                                      <i className="ri-add-line align-bottom"></i>{" "}
                                      PROCURED
                                    </button>
                                  </th>
                                  <th className="text-center" scope="row">
                                    <div className="mb-3">
                                      <div className="badge border border-primary text-primary d-inline-flex align-items-center fs-6">
                                        Test Delivered
                                        <i
                                          style={{
                                            cursor: "pointer",
                                            fontSize: "15px",
                                            marginLeft: "10px",
                                          }}
                                          className="ri-close-circle-fill"
                                        ></i>
                                      </div>
                                    </div>
                                    <button
                                      type="button"
                                      className="btn btn-soft-primary btn-sm shadow-none"
                                    >
                                      <i className="ri-add-line align-bottom"></i>{" "}
                                      Delivered
                                    </button>
                                  </th>
                                  <th className="text-center" scope="row">
                                    <div className="mb-3">
                                      <div className="badge border border-primary text-primary d-inline-flex align-items-center fs-6">
                                        Test Implemented
                                        <i
                                          style={{
                                            cursor: "pointer",
                                            fontSize: "15px",
                                            marginLeft: "10px",
                                          }}
                                          className="ri-close-circle-fill"
                                        ></i>
                                      </div>
                                    </div>
                                    <button
                                      type="button"
                                      className="btn btn-soft-primary btn-sm shadow-none"
                                    >
                                      <i className="ri-add-line align-bottom"></i>{" "}
                                      IMPLEMENTED
                                    </button>
                                  </th>
                                  <th className="text-center" scope="row">
                                    <div className="badge border border-primary text-primary d-inline-flex align-items-center fs-6">
                                      Test Status
                                      <i
                                        style={{
                                          cursor: "pointer",
                                          fontSize: "15px",
                                          marginLeft: "10px",
                                        }}
                                        className="ri-close-circle-fill"
                                      ></i>
                                    </div>
                                  </th>
                                </tr>
                                <tr
                                  style={{
                                    background: "aliceblue",
                                    border: "white",
                                  }}
                                >
                                  <th scope="row">
                                    <span className="badge border border-primary text-primary fs-6">
                                      Product 1
                                    </span>
                                  </th>

                                  <th className="text-center" scope="row">
                                    <div className="mb-3">
                                      <div className="badge border border-primary text-primary d-inline-flex align-items-center fs-6 mb-1">
                                        Test Quoted 1
                                        <i
                                          style={{
                                            cursor: "pointer",
                                            fontSize: "15px",
                                            marginLeft: "10px",
                                          }}
                                          className="ri-close-circle-fill"
                                        ></i>
                                      </div>

                                      <div className="badge border border-primary text-primary d-inline-flex align-items-center fs-6 mb-1">
                                        Test Quoted 2
                                        <i
                                          style={{
                                            cursor: "pointer",
                                            fontSize: "15px",
                                            marginLeft: "10px",
                                          }}
                                          className="ri-close-circle-fill"
                                        ></i>
                                      </div>

                                      <div className="badge border border-primary text-primary d-inline-flex align-items-center fs-6 mb-1">
                                        Test Quoted 3
                                        <i
                                          style={{
                                            cursor: "pointer",
                                            fontSize: "15px",
                                            marginLeft: "10px",
                                          }}
                                          className="ri-close-circle-fill"
                                        ></i>
                                      </div>

                                      <div className="badge border border-primary text-primary d-inline-flex align-items-center fs-6 mb-1">
                                        Test Quoted 4
                                        <i
                                          style={{
                                            cursor: "pointer",
                                            fontSize: "15px",
                                            marginLeft: "10px",
                                          }}
                                          className="ri-close-circle-fill"
                                        ></i>
                                      </div>
                                    </div>

                                    <button
                                      type="button"
                                      className="btn btn-soft-primary btn-sm shadow-none"
                                    >
                                      <i className="ri-add-line align-bottom"></i>{" "}
                                      QUOTED
                                    </button>
                                  </th>
                                  <th className="text-center" scope="row">
                                    <div className="mb-3">
                                      <div className="badge border border-primary text-primary d-inline-flex align-items-center fs-6">
                                        Test Planned
                                        <i
                                          style={{
                                            cursor: "pointer",
                                            fontSize: "15px",
                                            marginLeft: "10px",
                                          }}
                                          className="ri-close-circle-fill"
                                        ></i>
                                      </div>
                                    </div>
                                    <button
                                      type="button"
                                      className="btn btn-soft-primary btn-sm shadow-none"
                                    >
                                      <i className="ri-add-line align-bottom"></i>{" "}
                                      PLANNED
                                    </button>
                                  </th>
                                  <th className="text-center" scope="row">
                                    <div className="mb-3">
                                      <div className="badge border border-primary text-primary d-inline-flex align-items-center fs-6">
                                        Test Procured
                                        <i
                                          style={{
                                            cursor: "pointer",
                                            fontSize: "15px",
                                            marginLeft: "10px",
                                          }}
                                          className="ri-close-circle-fill"
                                        ></i>
                                      </div>
                                    </div>
                                    <button
                                      type="button"
                                      className="btn btn-soft-primary btn-sm shadow-none"
                                    >
                                      <i className="ri-add-line align-bottom"></i>{" "}
                                      PROCURED
                                    </button>
                                  </th>
                                  <th className="text-center" scope="row">
                                    <div className="mb-3">
                                      <div className="badge border border-primary text-primary d-inline-flex align-items-center fs-6">
                                        Test Delivered
                                        <i
                                          style={{
                                            cursor: "pointer",
                                            fontSize: "15px",
                                            marginLeft: "10px",
                                          }}
                                          className="ri-close-circle-fill"
                                        ></i>
                                      </div>
                                    </div>
                                    <button
                                      type="button"
                                      className="btn btn-soft-primary btn-sm shadow-none"
                                    >
                                      <i className="ri-add-line align-bottom"></i>{" "}
                                      Delivered
                                    </button>
                                  </th>
                                  <th className="text-center" scope="row">
                                    <div className="mb-3">
                                      <div className="badge border border-primary text-primary d-inline-flex align-items-center fs-6">
                                        Test Implemented
                                        <i
                                          style={{
                                            cursor: "pointer",
                                            fontSize: "15px",
                                            marginLeft: "10px",
                                          }}
                                          className="ri-close-circle-fill"
                                        ></i>
                                      </div>
                                    </div>
                                    <button
                                      type="button"
                                      className="btn btn-soft-primary btn-sm shadow-none"
                                    >
                                      <i className="ri-add-line align-bottom"></i>{" "}
                                      IMPLEMENTED
                                    </button>
                                  </th>
                                  <th className="text-center" scope="row">
                                    <div className="badge border border-primary text-primary d-inline-flex align-items-center fs-6">
                                      Test Status
                                      <i
                                        style={{
                                          cursor: "pointer",
                                          fontSize: "15px",
                                          marginLeft: "10px",
                                        }}
                                        className="ri-close-circle-fill"
                                      ></i>
                                    </div>
                                  </th>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Collapse>
                </AccordionItem>
                <AccordionItem>
                  <h2 className="accordion-header" id="accordionFillExample2">
                    <button
                      className={classnames("accordion-button", {
                        collapsed: !fillCol2,
                      })}
                      type="button"
                      onClick={t_fillCol2}
                      style={{ cursor: "pointer" }}
                    >
                      <h4>Section 2</h4>
                    </button>
                  </h2>
                  <Collapse
                    isOpen={fillCol2}
                    className="accordion-collapse"
                    id="accor_fill2"
                  >
                    <Card>
                      <CardBody>
                        {/* <p className="text-muted">Rater-js is a star rating widget for the browser. here are the various examples of rating shown below with variation.</p> */}
                        <div className="live-preview">
                          <div className="table-responsive">
                            <table className="table align-middle mb-0">
                              <thead>
                                <tr>
                                  <th scope="col" style={{ minWidth: "200px" }}>
                                    Item Name
                                  </th>
                                  <th scope="col" style={{ minWidth: "200px" }}>
                                    Quoted
                                  </th>
                                  <th scope="col" style={{ minWidth: "200px" }}>
                                    Planned
                                  </th>
                                  <th scope="col" style={{ minWidth: "200px" }}>
                                    Procured
                                  </th>
                                  <th scope="col" style={{ minWidth: "200px" }}>
                                    Delivered
                                  </th>
                                  <th scope="col" style={{ minWidth: "200px" }}>
                                    Implemented
                                  </th>
                                  <th scope="col" style={{ minWidth: "200px" }}>
                                    Status
                                  </th>
                                </tr>
                                <tr style={{ border: "white" }}>
                                  <th scope="col" style={{ minWidth: "200px" }}>
                                    <div className="d-flex align-items-center my-4">
                                      <div className="flex-grow-1">
                                        <h4 className="mb-0 fs-11 text-muted text-uppercase">
                                          Product
                                        </h4>
                                      </div>
                                      <div className="flex-shrink-0">
                                        <div
                                          data-bs-toggle="tooltip"
                                          data-bs-trigger="hover"
                                          data-bs-placement="bottom"
                                          aria-label="Add Product"
                                          data-bs-original-title="Add Product"
                                        >
                                          <button
                                            type="button"
                                            className="btn btn-soft-success btn-sm shadow-none"
                                          >
                                            <i className="ri-add-line align-bottom"></i>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </th>
                                  <th scope="col" style={{ minWidth: "200px" }}>
                                    <div className="d-flex align-items-center my-4">
                                      <div className="flex-grow-1">
                                        <h4 className="mb-0 fs-11 text-muted text-uppercase">
                                          Product
                                        </h4>
                                      </div>
                                      <div className="flex-shrink-0">
                                        <div
                                          data-bs-toggle="tooltip"
                                          data-bs-trigger="hover"
                                          data-bs-placement="bottom"
                                          aria-label="Add Product"
                                          data-bs-original-title="Add Product"
                                        >
                                          <button
                                            type="button"
                                            className="btn btn-soft-success btn-sm shadow-none"
                                          >
                                            <i className="ri-add-line align-bottom"></i>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </th>
                                  <th scope="col" style={{ minWidth: "200px" }}>
                                    <div className="d-flex align-items-center my-4">
                                      <div className="flex-grow-1">
                                        <h4 className="mb-0 fs-11 text-muted text-uppercase">
                                          Product
                                        </h4>
                                      </div>
                                      <div className="flex-shrink-0">
                                        <div
                                          data-bs-toggle="tooltip"
                                          data-bs-trigger="hover"
                                          data-bs-placement="bottom"
                                          aria-label="Add Product"
                                          data-bs-original-title="Add Product"
                                        >
                                          <button
                                            type="button"
                                            className="btn btn-soft-success btn-sm shadow-none"
                                          >
                                            <i className="ri-add-line align-bottom"></i>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </th>
                                  <th scope="col" style={{ minWidth: "200px" }}>
                                    <div className="d-flex align-items-center my-4">
                                      <div className="flex-grow-1">
                                        <h4 className="mb-0 fs-11 text-muted text-uppercase">
                                          Product
                                        </h4>
                                      </div>
                                      <div className="flex-shrink-0">
                                        <div
                                          data-bs-toggle="tooltip"
                                          data-bs-trigger="hover"
                                          data-bs-placement="bottom"
                                          aria-label="Add Product"
                                          data-bs-original-title="Add Product"
                                        >
                                          <button
                                            type="button"
                                            className="btn btn-soft-success btn-sm shadow-none"
                                          >
                                            <i className="ri-add-line align-bottom"></i>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </th>

                                  <th
                                    scope="col"
                                    style={{ minWidth: "200px" }}
                                  ></th>
                                  <th
                                    scope="col"
                                    style={{ minWidth: "200px" }}
                                  ></th>
                                  <th scope="col" style={{ minWidth: "200px" }}>
                                    <div className="d-flex align-items-center my-4">
                                      <div className="flex-grow-1">
                                        <h4 className="mb-0 fs-11 text-muted text-uppercase">
                                          Status
                                        </h4>
                                      </div>
                                      <div className="flex-shrink-0">
                                        <div
                                          data-bs-toggle="tooltip"
                                          data-bs-trigger="hover"
                                          data-bs-placement="bottom"
                                          aria-label="Add Product"
                                          data-bs-original-title="Add Product"
                                        >
                                          <button
                                            type="button"
                                            className="btn btn-soft-success btn-sm shadow-none"
                                          >
                                            <i className="ri-add-line align-bottom"></i>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  style={{
                                    background: "aliceblue",
                                    border: "white",
                                  }}
                                >
                                  <th scope="row">
                                    <span className="badge border border-primary text-primary fs-6">
                                      Product 1
                                    </span>
                                  </th>

                                  <th className="text-center" scope="row">
                                    <div className="mb-3">
                                      <div className="badge border border-primary text-primary d-inline-flex align-items-center fs-6 mb-1">
                                        Test Quoted 1
                                        <i
                                          style={{
                                            cursor: "pointer",
                                            fontSize: "15px",
                                            marginLeft: "10px",
                                          }}
                                          className="ri-close-circle-fill"
                                        ></i>
                                      </div>

                                      <div className="badge border border-primary text-primary d-inline-flex align-items-center fs-6 mb-1">
                                        Test Quoted 2
                                        <i
                                          style={{
                                            cursor: "pointer",
                                            fontSize: "15px",
                                            marginLeft: "10px",
                                          }}
                                          className="ri-close-circle-fill"
                                        ></i>
                                      </div>

                                      <div className="badge border border-primary text-primary d-inline-flex align-items-center fs-6 mb-1">
                                        Test Quoted 3
                                        <i
                                          style={{
                                            cursor: "pointer",
                                            fontSize: "15px",
                                            marginLeft: "10px",
                                          }}
                                          className="ri-close-circle-fill"
                                        ></i>
                                      </div>

                                      <div className="badge border border-primary text-primary d-inline-flex align-items-center fs-6 mb-1">
                                        Test Quoted 4
                                        <i
                                          style={{
                                            cursor: "pointer",
                                            fontSize: "15px",
                                            marginLeft: "10px",
                                          }}
                                          className="ri-close-circle-fill"
                                        ></i>
                                      </div>
                                    </div>

                                    <button
                                      type="button"
                                      className="btn btn-soft-primary btn-sm shadow-none"
                                    >
                                      <i className="ri-add-line align-bottom"></i>{" "}
                                      QUOTED
                                    </button>
                                  </th>
                                  <th className="text-center" scope="row">
                                    <div className="mb-3">
                                      <div className="badge border border-primary text-primary d-inline-flex align-items-center fs-6">
                                        Test Planned
                                        <i
                                          style={{
                                            cursor: "pointer",
                                            fontSize: "15px",
                                            marginLeft: "10px",
                                          }}
                                          className="ri-close-circle-fill"
                                        ></i>
                                      </div>
                                    </div>
                                    <button
                                      type="button"
                                      className="btn btn-soft-primary btn-sm shadow-none"
                                    >
                                      <i className="ri-add-line align-bottom"></i>{" "}
                                      PLANNED
                                    </button>
                                  </th>
                                  <th className="text-center" scope="row">
                                    <div className="mb-3">
                                      <div className="badge border border-primary text-primary d-inline-flex align-items-center fs-6">
                                        Test Procured
                                        <i
                                          style={{
                                            cursor: "pointer",
                                            fontSize: "15px",
                                            marginLeft: "10px",
                                          }}
                                          className="ri-close-circle-fill"
                                        ></i>
                                      </div>
                                    </div>
                                    <button
                                      type="button"
                                      className="btn btn-soft-primary btn-sm shadow-none"
                                    >
                                      <i className="ri-add-line align-bottom"></i>{" "}
                                      PROCURED
                                    </button>
                                  </th>
                                  <th className="text-center" scope="row">
                                    <div className="mb-3">
                                      <div className="badge border border-primary text-primary d-inline-flex align-items-center fs-6">
                                        Test Delivered
                                        <i
                                          style={{
                                            cursor: "pointer",
                                            fontSize: "15px",
                                            marginLeft: "10px",
                                          }}
                                          className="ri-close-circle-fill"
                                        ></i>
                                      </div>
                                    </div>
                                    <button
                                      type="button"
                                      className="btn btn-soft-primary btn-sm shadow-none"
                                    >
                                      <i className="ri-add-line align-bottom"></i>{" "}
                                      Delivered
                                    </button>
                                  </th>
                                  <th className="text-center" scope="row">
                                    <div className="mb-3">
                                      <div className="badge border border-primary text-primary d-inline-flex align-items-center fs-6">
                                        Test Implemented
                                        <i
                                          style={{
                                            cursor: "pointer",
                                            fontSize: "15px",
                                            marginLeft: "10px",
                                          }}
                                          className="ri-close-circle-fill"
                                        ></i>
                                      </div>
                                    </div>
                                    <button
                                      type="button"
                                      className="btn btn-soft-primary btn-sm shadow-none"
                                    >
                                      <i className="ri-add-line align-bottom"></i>{" "}
                                      IMPLEMENTED
                                    </button>
                                  </th>
                                  <th className="text-center" scope="row">
                                    <div className="badge border border-primary text-primary d-inline-flex align-items-center fs-6">
                                      Test Status
                                      <i
                                        style={{
                                          cursor: "pointer",
                                          fontSize: "15px",
                                          marginLeft: "10px",
                                        }}
                                        className="ri-close-circle-fill"
                                      ></i>
                                    </div>
                                  </th>
                                </tr>
                                <tr
                                  style={{
                                    background: "aliceblue",
                                    border: "white",
                                  }}
                                >
                                  <th scope="row">
                                    <span className="badge border border-primary text-primary fs-6">
                                      Product 1
                                    </span>
                                  </th>

                                  <th className="text-center" scope="row">
                                    <div className="mb-3">
                                      <div className="badge border border-primary text-primary d-inline-flex align-items-center fs-6 mb-1">
                                        Test Quoted 1
                                        <i
                                          style={{
                                            cursor: "pointer",
                                            fontSize: "15px",
                                            marginLeft: "10px",
                                          }}
                                          className="ri-close-circle-fill"
                                        ></i>
                                      </div>

                                      <div className="badge border border-primary text-primary d-inline-flex align-items-center fs-6 mb-1">
                                        Test Quoted 2
                                        <i
                                          style={{
                                            cursor: "pointer",
                                            fontSize: "15px",
                                            marginLeft: "10px",
                                          }}
                                          className="ri-close-circle-fill"
                                        ></i>
                                      </div>

                                      <div className="badge border border-primary text-primary d-inline-flex align-items-center fs-6 mb-1">
                                        Test Quoted 3
                                        <i
                                          style={{
                                            cursor: "pointer",
                                            fontSize: "15px",
                                            marginLeft: "10px",
                                          }}
                                          className="ri-close-circle-fill"
                                        ></i>
                                      </div>

                                      <div className="badge border border-primary text-primary d-inline-flex align-items-center fs-6 mb-1">
                                        Test Quoted 4
                                        <i
                                          style={{
                                            cursor: "pointer",
                                            fontSize: "15px",
                                            marginLeft: "10px",
                                          }}
                                          className="ri-close-circle-fill"
                                        ></i>
                                      </div>
                                    </div>

                                    <button
                                      type="button"
                                      className="btn btn-soft-primary btn-sm shadow-none"
                                    >
                                      <i className="ri-add-line align-bottom"></i>{" "}
                                      QUOTED
                                    </button>
                                  </th>
                                  <th className="text-center" scope="row">
                                    <div className="mb-3">
                                      <div className="badge border border-primary text-primary d-inline-flex align-items-center fs-6">
                                        Test Planned
                                        <i
                                          style={{
                                            cursor: "pointer",
                                            fontSize: "15px",
                                            marginLeft: "10px",
                                          }}
                                          className="ri-close-circle-fill"
                                        ></i>
                                      </div>
                                    </div>
                                    <button
                                      type="button"
                                      className="btn btn-soft-primary btn-sm shadow-none"
                                    >
                                      <i className="ri-add-line align-bottom"></i>{" "}
                                      PLANNED
                                    </button>
                                  </th>
                                  <th className="text-center" scope="row">
                                    <div className="mb-3">
                                      <div className="badge border border-primary text-primary d-inline-flex align-items-center fs-6">
                                        Test Procured
                                        <i
                                          style={{
                                            cursor: "pointer",
                                            fontSize: "15px",
                                            marginLeft: "10px",
                                          }}
                                          className="ri-close-circle-fill"
                                        ></i>
                                      </div>
                                    </div>
                                    <button
                                      type="button"
                                      className="btn btn-soft-primary btn-sm shadow-none"
                                    >
                                      <i className="ri-add-line align-bottom"></i>{" "}
                                      PROCURED
                                    </button>
                                  </th>
                                  <th className="text-center" scope="row">
                                    <div className="mb-3">
                                      <div className="badge border border-primary text-primary d-inline-flex align-items-center fs-6">
                                        Test Delivered
                                        <i
                                          style={{
                                            cursor: "pointer",
                                            fontSize: "15px",
                                            marginLeft: "10px",
                                          }}
                                          className="ri-close-circle-fill"
                                        ></i>
                                      </div>
                                    </div>
                                    <button
                                      type="button"
                                      className="btn btn-soft-primary btn-sm shadow-none"
                                    >
                                      <i className="ri-add-line align-bottom"></i>{" "}
                                      Delivered
                                    </button>
                                  </th>
                                  <th className="text-center" scope="row">
                                    <div className="mb-3">
                                      <div className="badge border border-primary text-primary d-inline-flex align-items-center fs-6">
                                        Test Implemented
                                        <i
                                          style={{
                                            cursor: "pointer",
                                            fontSize: "15px",
                                            marginLeft: "10px",
                                          }}
                                          className="ri-close-circle-fill"
                                        ></i>
                                      </div>
                                    </div>
                                    <button
                                      type="button"
                                      className="btn btn-soft-primary btn-sm shadow-none"
                                    >
                                      <i className="ri-add-line align-bottom"></i>{" "}
                                      IMPLEMENTED
                                    </button>
                                  </th>
                                  <th className="text-center" scope="row">
                                    <div className="badge border border-primary text-primary d-inline-flex align-items-center fs-6">
                                      Test Status
                                      <i
                                        style={{
                                          cursor: "pointer",
                                          fontSize: "15px",
                                          marginLeft: "10px",
                                        }}
                                        className="ri-close-circle-fill"
                                      ></i>
                                    </div>
                                  </th>
                                </tr>
                                <tr
                                  style={{
                                    background: "aliceblue",
                                    border: "white",
                                  }}
                                >
                                  <th scope="row">
                                    <span className="badge border border-primary text-primary fs-6">
                                      Product 1
                                    </span>
                                  </th>

                                  <th className="text-center" scope="row">
                                    <div className="mb-3">
                                      <div className="badge border border-primary text-primary d-inline-flex align-items-center fs-6 mb-1">
                                        Test Quoted 1
                                        <i
                                          style={{
                                            cursor: "pointer",
                                            fontSize: "15px",
                                            marginLeft: "10px",
                                          }}
                                          className="ri-close-circle-fill"
                                        ></i>
                                      </div>

                                      <div className="badge border border-primary text-primary d-inline-flex align-items-center fs-6 mb-1">
                                        Test Quoted 2
                                        <i
                                          style={{
                                            cursor: "pointer",
                                            fontSize: "15px",
                                            marginLeft: "10px",
                                          }}
                                          className="ri-close-circle-fill"
                                        ></i>
                                      </div>

                                      <div className="badge border border-primary text-primary d-inline-flex align-items-center fs-6 mb-1">
                                        Test Quoted 3
                                        <i
                                          style={{
                                            cursor: "pointer",
                                            fontSize: "15px",
                                            marginLeft: "10px",
                                          }}
                                          className="ri-close-circle-fill"
                                        ></i>
                                      </div>

                                      <div className="badge border border-primary text-primary d-inline-flex align-items-center fs-6 mb-1">
                                        Test Quoted 4
                                        <i
                                          style={{
                                            cursor: "pointer",
                                            fontSize: "15px",
                                            marginLeft: "10px",
                                          }}
                                          className="ri-close-circle-fill"
                                        ></i>
                                      </div>
                                    </div>

                                    <button
                                      type="button"
                                      className="btn btn-soft-primary btn-sm shadow-none"
                                    >
                                      <i className="ri-add-line align-bottom"></i>{" "}
                                      QUOTED
                                    </button>
                                  </th>
                                  <th className="text-center" scope="row">
                                    <div className="mb-3">
                                      <div className="badge border border-primary text-primary d-inline-flex align-items-center fs-6">
                                        Test Planned
                                        <i
                                          style={{
                                            cursor: "pointer",
                                            fontSize: "15px",
                                            marginLeft: "10px",
                                          }}
                                          className="ri-close-circle-fill"
                                        ></i>
                                      </div>
                                    </div>
                                    <button
                                      type="button"
                                      className="btn btn-soft-primary btn-sm shadow-none"
                                    >
                                      <i className="ri-add-line align-bottom"></i>{" "}
                                      PLANNED
                                    </button>
                                  </th>
                                  <th className="text-center" scope="row">
                                    <div className="mb-3">
                                      <div className="badge border border-primary text-primary d-inline-flex align-items-center fs-6">
                                        Test Procured
                                        <i
                                          style={{
                                            cursor: "pointer",
                                            fontSize: "15px",
                                            marginLeft: "10px",
                                          }}
                                          className="ri-close-circle-fill"
                                        ></i>
                                      </div>
                                    </div>
                                    <button
                                      type="button"
                                      className="btn btn-soft-primary btn-sm shadow-none"
                                    >
                                      <i className="ri-add-line align-bottom"></i>{" "}
                                      PROCURED
                                    </button>
                                  </th>
                                  <th className="text-center" scope="row">
                                    <div className="mb-3">
                                      <div className="badge border border-primary text-primary d-inline-flex align-items-center fs-6">
                                        Test Delivered
                                        <i
                                          style={{
                                            cursor: "pointer",
                                            fontSize: "15px",
                                            marginLeft: "10px",
                                          }}
                                          className="ri-close-circle-fill"
                                        ></i>
                                      </div>
                                    </div>
                                    <button
                                      type="button"
                                      className="btn btn-soft-primary btn-sm shadow-none"
                                    >
                                      <i className="ri-add-line align-bottom"></i>{" "}
                                      Delivered
                                    </button>
                                  </th>
                                  <th className="text-center" scope="row">
                                    <div className="mb-3">
                                      <div className="badge border border-primary text-primary d-inline-flex align-items-center fs-6">
                                        Test Implemented
                                        <i
                                          style={{
                                            cursor: "pointer",
                                            fontSize: "15px",
                                            marginLeft: "10px",
                                          }}
                                          className="ri-close-circle-fill"
                                        ></i>
                                      </div>
                                    </div>
                                    <button
                                      type="button"
                                      className="btn btn-soft-primary btn-sm shadow-none"
                                    >
                                      <i className="ri-add-line align-bottom"></i>{" "}
                                      IMPLEMENTED
                                    </button>
                                  </th>
                                  <th className="text-center" scope="row">
                                    <div className="badge border border-primary text-primary d-inline-flex align-items-center fs-6">
                                      Test Status
                                      <i
                                        style={{
                                          cursor: "pointer",
                                          fontSize: "15px",
                                          marginLeft: "10px",
                                        }}
                                        className="ri-close-circle-fill"
                                      ></i>
                                    </div>
                                  </th>
                                </tr>
                                <tr
                                  style={{
                                    background: "aliceblue",
                                    border: "white",
                                  }}
                                >
                                  <th scope="row">
                                    <span className="badge border border-primary text-primary fs-6">
                                      Product 1
                                    </span>
                                  </th>

                                  <th className="text-center" scope="row">
                                    <div className="mb-3">
                                      <div className="badge border border-primary text-primary d-inline-flex align-items-center fs-6 mb-1">
                                        Test Quoted 1
                                        <i
                                          style={{
                                            cursor: "pointer",
                                            fontSize: "15px",
                                            marginLeft: "10px",
                                          }}
                                          className="ri-close-circle-fill"
                                        ></i>
                                      </div>

                                      <div className="badge border border-primary text-primary d-inline-flex align-items-center fs-6 mb-1">
                                        Test Quoted 2
                                        <i
                                          style={{
                                            cursor: "pointer",
                                            fontSize: "15px",
                                            marginLeft: "10px",
                                          }}
                                          className="ri-close-circle-fill"
                                        ></i>
                                      </div>

                                      <div className="badge border border-primary text-primary d-inline-flex align-items-center fs-6 mb-1">
                                        Test Quoted 3
                                        <i
                                          style={{
                                            cursor: "pointer",
                                            fontSize: "15px",
                                            marginLeft: "10px",
                                          }}
                                          className="ri-close-circle-fill"
                                        ></i>
                                      </div>

                                      <div className="badge border border-primary text-primary d-inline-flex align-items-center fs-6 mb-1">
                                        Test Quoted 4
                                        <i
                                          style={{
                                            cursor: "pointer",
                                            fontSize: "15px",
                                            marginLeft: "10px",
                                          }}
                                          className="ri-close-circle-fill"
                                        ></i>
                                      </div>
                                    </div>

                                    <button
                                      type="button"
                                      className="btn btn-soft-primary btn-sm shadow-none"
                                    >
                                      <i className="ri-add-line align-bottom"></i>{" "}
                                      QUOTED
                                    </button>
                                  </th>
                                  <th className="text-center" scope="row">
                                    <div className="mb-3">
                                      <div className="badge border border-primary text-primary d-inline-flex align-items-center fs-6">
                                        Test Planned
                                        <i
                                          style={{
                                            cursor: "pointer",
                                            fontSize: "15px",
                                            marginLeft: "10px",
                                          }}
                                          className="ri-close-circle-fill"
                                        ></i>
                                      </div>
                                    </div>
                                    <button
                                      type="button"
                                      className="btn btn-soft-primary btn-sm shadow-none"
                                    >
                                      <i className="ri-add-line align-bottom"></i>{" "}
                                      PLANNED
                                    </button>
                                  </th>
                                  <th className="text-center" scope="row">
                                    <div className="mb-3">
                                      <div className="badge border border-primary text-primary d-inline-flex align-items-center fs-6">
                                        Test Procured
                                        <i
                                          style={{
                                            cursor: "pointer",
                                            fontSize: "15px",
                                            marginLeft: "10px",
                                          }}
                                          className="ri-close-circle-fill"
                                        ></i>
                                      </div>
                                    </div>
                                    <button
                                      type="button"
                                      className="btn btn-soft-primary btn-sm shadow-none"
                                    >
                                      <i className="ri-add-line align-bottom"></i>{" "}
                                      PROCURED
                                    </button>
                                  </th>
                                  <th className="text-center" scope="row">
                                    <div className="mb-3">
                                      <div className="badge border border-primary text-primary d-inline-flex align-items-center fs-6">
                                        Test Delivered
                                        <i
                                          style={{
                                            cursor: "pointer",
                                            fontSize: "15px",
                                            marginLeft: "10px",
                                          }}
                                          className="ri-close-circle-fill"
                                        ></i>
                                      </div>
                                    </div>
                                    <button
                                      type="button"
                                      className="btn btn-soft-primary btn-sm shadow-none"
                                    >
                                      <i className="ri-add-line align-bottom"></i>{" "}
                                      Delivered
                                    </button>
                                  </th>
                                  <th className="text-center" scope="row">
                                    <div className="mb-3">
                                      <div className="badge border border-primary text-primary d-inline-flex align-items-center fs-6">
                                        Test Implemented
                                        <i
                                          style={{
                                            cursor: "pointer",
                                            fontSize: "15px",
                                            marginLeft: "10px",
                                          }}
                                          className="ri-close-circle-fill"
                                        ></i>
                                      </div>
                                    </div>
                                    <button
                                      type="button"
                                      className="btn btn-soft-primary btn-sm shadow-none"
                                    >
                                      <i className="ri-add-line align-bottom"></i>{" "}
                                      IMPLEMENTED
                                    </button>
                                  </th>
                                  <th className="text-center" scope="row">
                                    <div className="badge border border-primary text-primary d-inline-flex align-items-center fs-6">
                                      Test Status
                                      <i
                                        style={{
                                          cursor: "pointer",
                                          fontSize: "15px",
                                          marginLeft: "10px",
                                        }}
                                        className="ri-close-circle-fill"
                                      ></i>
                                    </div>
                                  </th>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Collapse>
                </AccordionItem>
              </Accordion>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default UiRatings;
