export const TASK_STATUS = [
    { id: 1, name: 'ToDo' },
    { id: 2, name: 'Assigned' },
    { id: 3, name: 'In Progress' },
    { id: 4, name: 'On Hold' },
    { id: 5, name: 'Completed' },
    { id: 6, name: 'Cancelled' },
    { id: 7, name: 'Pending Review' },
    { id: 8, name: 'Blocked' },
    { id: 9, name: 'Resolved' },
];


export const STATUS_CODE = {
    'To Initiate': 'border-warning text-warning',
    'Requested': 'border-danger text-danger',
    'Approved': 'border-primary text-primary',
    'Rejected': 'border-danger text-danger',
    'Procured': 'border-primary text-primary',
    'Completed': 'border-primary text-primary',
    'In Progress': 'border-primary text-primary',
    'Delivered': 'border-primary text-primary',
    'Implemented': 'border-primary text-primary',
}