import React, { useMemo, useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import TableContainer from "../../../Components/Common/TableContainer";
import Loader from "../../../Components/Common/Loader";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import {
  useGetCategoriesQuery,
  useGetCategoryByIdQuery,
} from "slices/resourceInventory/inventorySlice";
import { toast, ToastContainer } from "react-toastify";
import { Link } from "react-router-dom";
import { CategoryEdit } from "./CategoriesEdit";

const CategoriesListView = () => {
  const [editingCategoryId, setEditingCategoryId] = useState<
    string | undefined
  >(undefined); // State to manage add/edit mode
  const [isAdding, setIsAdding] = useState(false); // State to track if adding a new category

  // State for view modal
  const [viewCategoryId, setViewCategoryId] = useState<string | undefined>(
    undefined
  );
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);

  // Fetch category data
  const {
    data: categoryData,
    isLoading: isLoadingCategories,
    refetch: refetchCategories,
  } = useGetCategoriesQuery(undefined);

  // Fetch category details for view modal
  const { data: categoryDetails, isLoading: isLoadingCategoryDetails } =
    useGetCategoryByIdQuery(viewCategoryId, {
      skip: !viewCategoryId, // Skip query if no category is selected
    });

  // Handle Add and Edit actions
  const handleAddCategory = () => {
    setEditingCategoryId(undefined); // Clear editing ID for new category
    setIsAdding(true); // Set to adding mode
  };

  const handleEditCategory = (id: string) => {
    setEditingCategoryId(id); // Set the ID for editing
    setIsAdding(false); // Set to editing mode
  };

  const handleViewCategory = (id: string) => {
    setViewCategoryId(id); // Set the ID for viewing
    setIsViewModalOpen(true); // Open the modal
  };

  // Handle closing the view modal
  const toggleViewModal = () => {
    setIsViewModalOpen(!isViewModalOpen);
    setViewCategoryId(undefined); // Clear the state when closing the modal
  };

  // Handle returning to list view
  const handleBackToList = () => {
    setEditingCategoryId(undefined); // Clear the state
    setIsAdding(false); // Exit adding/editing mode
  };

  // Define table columns for categories
  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        filterable: false,
      },
      {
        Header: "Description",
        accessor: "description",
        filterable: false,
      },
      {
        Header: "Actions",
        accessor: "actions",
        Cell: ({ row }: any) => {
          const categoryId = row.original.id;
          return (
            <div>
              <ul className="list-inline hstack gap-2 mb-0">
                <li className="list-inline-item view" title="View">
                  <Link
                    to="#"
                    className="text-primary d-inline-block view-item-btn"
                    onClick={() => handleViewCategory(categoryId)}
                  >
                    <i className="ri-eye-fill fs-16"></i>
                  </Link>
                </li>
                <li className="list-inline-item edit" title="Edit">
                  <Link
                    to="#"
                    className="text-primary d-inline-block edit-item-btn"
                    onClick={() => handleEditCategory(categoryId)}
                  >
                    <i className="ri-pencil-fill fs-16"></i>
                  </Link>
                </li>
              </ul>
            </div>
          );
        },
      },
    ],
    []
  );

  document.title = "Categories | Dashboard";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Categories List" pageTitle="Categories" />
          {(editingCategoryId || isAdding) && (
            <Row className="mt-4">
              <Col lg={12}>
                <CategoryEdit
                  categoryId={editingCategoryId}
                  setCardView={handleBackToList} // Pass function to go back to list view
                />
              </Col>
            </Row>
          )}

          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader className="border-0 d-flex justify-content-between align-items-center">
                  <h5 className="card-title mb-0">Categories List</h5>
                  <Button
                    color="success"
                    onClick={handleAddCategory}
                    disabled={isLoadingCategories}
                  >
                    <i className="ri-add-line align-bottom me-1"></i> Add
                    Category
                  </Button>
                </CardHeader>
                <div className="card-body pt-0">
                  {isLoadingCategories ? (
                    <Loader />
                  ) : (
                    <TableContainer
                      columns={columns}
                      data={categoryData?.results || []}
                      isGlobalFilter={true}
                      isAddUserList={false}
                      customPageSize={10}
                      className="custom-header-css"
                      // isCustomerFilter={true}
                      SearchPlaceholder={"Search for categories..."}
                      isPagination={true}
                    />
                  )}
                </div>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      {/* View Category Modal */}
      <Modal isOpen={isViewModalOpen} toggle={toggleViewModal}>
        <ModalHeader toggle={toggleViewModal}>Category Details</ModalHeader>
        <ModalBody>
          {isLoadingCategoryDetails ? (
            <Loader />
          ) : categoryDetails ? (
            <div className="table-card">
              <table className="table mb-0">
                <tbody>
                  <tr>
                    <td className="fw-medium">Name</td>
                    <td>{categoryDetails?.name}</td>
                  </tr>
                  <tr>
                    <td className="fw-medium">Description</td>
                    <td>{categoryDetails?.description}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          ) : (
            <p>No details available.</p>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleViewModal}>
            Close
          </Button>
        </ModalFooter>
      </Modal>

      <ToastContainer />
    </React.Fragment>
  );
};

export default CategoriesListView;
