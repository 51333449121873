import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Row,
  TabContent,
  TabPane,
  Spinner,
  Table,
} from "reactstrap";
import classnames from "classnames";
import { useParams } from "react-router-dom";
import {
  useGetEmployeeByUuidQuery,
  useGetEmergencyContactInfoQuery,
  useGetEmployeePersonalInfoQuery,
  usePatchEmployeeProfileImageMutation,
  useGetEmployeeProfileImageQuery,
} from "slices/employee/employeeSlice";

import { useDropzone } from "react-dropzone";

import { EmployeeSideBar } from "./SideBar/SideBar";
import { WorkProfile } from "./EmployeeProfileView/WorkProfileView";
import { WorkProfileEdit } from "./EmployeeEditView/WorkProfileEdit";
import { PersonalInfoView } from "./EmployeeProfileView/PersonalInfoView";
import { PersonalInfoEdit } from "./EmployeeEditView/PersonalInfoEdit";
import { EmergencyContactInfoView } from "./EmployeeProfileView/EmergencyInfoView";
import { EmergencyContactEdit } from "./EmployeeEditView/EmergencyContactInfoEdit";
import EmployeeDocumentList from "./Documents/EmployeeDocuments";
//Images
import avatar3 from "../../assets/images/users/avatar-3.jpg";
import avatar4 from "../../assets/images/users/avatar-4.jpg";
import avatar5 from "../../assets/images/users/avatar-5.jpg";

import { projects, documents } from "common/data";

export interface Project {
  project_name: string;
  assigned_date: string;
  current_active: boolean;
  project_actual_cost: string | null;
  project_estimation_cost: string | null;
  project_allocated_budget: string | null;
  start_date: string;
  end_date: string;
  status: number;
}

export interface EmployeeData {
  employee_fullname: string;
  work_email: string;
  employee_uuid: string;
  role_name: string[];
  work_phone: string;
  employee_start_date: string;
  position_name: string;
  skills: string;
  first_name: string;
  last_name: string;
  onboarding_mentor_name: string;
  direct_manager_name: string;
  project_name: string;
  employee_number: string;
  employee_profile_image_url: {
    profile_image: string;
    profile_image_url: string;
  };
  overall_status: {
    is_employee_work_info_completed: boolean;
    is_employee_personal_info_completed: boolean;
    is_employee_emergency_contact_completed: boolean;
    is_employee_profile_image_completed: boolean;
    average_percentage: number;
    is_profile_completed: boolean;
  };
  project_list: Project[];
}

export interface PersonalInfo {
  personal_email: string;
  address2: string;
  gender: string | null;
  home_phone: string;
  state: string;
  address1: string;
  city: string;
  marital_status: string | null;
  nationality: string;
  zip_code: string;
  mobile_phone: string;
  date_of_birth: string | null;
  country: string | null;
  marital_status_name: string;
  gender_name: string;
  country_name: string;
}

export interface EmergencyContactInfo {
  full_name: string;
  email: string;
  relationship: string;
  address2: string;
  mobile_phone: string;
  state: string;
  zip_code: string;
  work_phone: string;
  address1: string;
  home_phone: string;
  city: string;
  country: number | null;
  country_name: string;
}

type CardViewState = Record<string, boolean>;

const EmployeeProfile: React.FC = () => {
  //   SwiperCore.use([Autoplay]);

  const [employeeProfileData, setEmployeeProfileData] =
    useState<EmployeeData | null>(null);

  const [personalInfo, setPersonalInfo] = useState<PersonalInfo | null>(null);

  const [emergencyContactInfo, setEmergencyContactInfo] =
    useState<EmergencyContactInfo | null>(null);

  const [cardView, setCardView] = useState<CardViewState>({
    summary: true,
    profile: false,
    personalInfo: false,
    emergencyContact: false,
  });

  const [hovered, setHovered] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [copiedText, setCopiedText] = useState(""); // To track the copied field (username/password)
  const [showTooltip, setShowTooltip] = useState(false); // To manage tooltip visibility

  const { employee_uuid } = useParams<{ employee_uuid: string }>();

  const { data, error, isLoading, refetch } =
    useGetEmployeeByUuidQuery(employee_uuid);

  const {
    data: personalInfoData,
    error: personalInfoError,
    isLoading: personalInfoLoading,
    refetch: personalInfoFetch,
  } = useGetEmployeePersonalInfoQuery(employee_uuid);

  const {
    data: emergencyContactInfoData,
    error: emergencyContactInfoError,
    isLoading: emergencyContactInfoLoading,
    refetch: emergencyContactInfoFetch,
  } = useGetEmergencyContactInfoQuery(employee_uuid);

  const {
    data: profileImageData,
    error: profileImageError,
    isLoading: profileImageLoading,
    refetch: profileImageFetch,
  } = useGetEmployeeProfileImageQuery(employee_uuid);

  const [patchEmployeeProfileImage, { isLoading: patchProfileImageLoading }] =
    usePatchEmployeeProfileImageMutation();

  useEffect(() => {
    if (employee_uuid) {
      refetch();
    }
  }, [employee_uuid, refetch]);

  useEffect(() => {
    if (employee_uuid) {
      personalInfoFetch();
    }
  }, [employee_uuid, personalInfoFetch]);

  useEffect(() => {
    if (employee_uuid) {
      emergencyContactInfoFetch();
    }
  }, [employee_uuid, emergencyContactInfoFetch]);

  useEffect(() => {
    if (data) {
      setEmployeeProfileData(data);
    }
  }, [data]);

  useEffect(() => {
    if (personalInfoData) {
      setPersonalInfo(personalInfoData);
      personalInfoFetch();
    }
  }, [personalInfoData, personalInfoFetch]);

  useEffect(() => {
    if (emergencyContactInfoData) {
      setEmergencyContactInfo(emergencyContactInfoData);
      emergencyContactInfoFetch();
    }
  }, [emergencyContactInfoData, emergencyContactInfoFetch]);

  const profileImageUrl = profileImageData?.profile_image_url;
  const initials = `${employeeProfileData?.first_name.charAt(
    0
  )}${employeeProfileData?.last_name.charAt(0)}`;

  const [activeTab, setActiveTab] = useState("1");
  const [activityTab, setActivityTab] = useState("1");

  const toggleTab = (tab: any) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const toggleActivityTab = (tab: any) => {
    if (activityTab !== tab) {
      setActivityTab(tab);
    }
  };

  const onDrop = async (acceptedFiles: File[]) => {
    if (acceptedFiles.length) {
      const imageFile = acceptedFiles[0];
      const updateData = { profile_image: imageFile };

      try {
        await patchEmployeeProfileImage({
          employee_uuid,
          updateData,
        });
        // Optionally refetch the profile data to update the UI
        refetch();
        profileImageFetch();
      } catch (error) {
        console.error("Failed to update profile image:", error);
      }
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: { "image/*": [] },
    multiple: false,
  });

  document.title = "Profile | Velzon - React Admin & Dashboard Template";

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error fetching data</div>;

  const skillsString = employeeProfileData?.skills || "";
  const skillsArray = skillsString ? skillsString.split(",") : [];

  const rolesArray = employeeProfileData?.role_name || [];

  // Function to copy text to clipboard
  const copyToClipboard = (text: string, fieldName: string) => {
    navigator.clipboard.writeText(text);
    setCopiedText(fieldName);
    setShowTooltip(true);

    // Hide the tooltip after 2 seconds
    setTimeout(() => {
      setShowTooltip(false);
    }, 2000);
  };

  const userName = "sundar";
  const password = "sundar@123";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="profile-foreground position-relative mx-n4 mt-n3">
            <div className="profile-wid-bg"></div>
          </div>
          <div className="pt-4 mb-4 mb-lg-3 pb-lg-4">
            <Row className="g-4">
              <div
                className="col-auto position-relative"
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
                {...getRootProps()}
                style={{ cursor: "pointer" }}
              >
                <input {...getInputProps()} style={{ display: "none" }} />
                <div className="avatar-lg">
                  {profileImageUrl ? (
                    <img
                      src={profileImageUrl}
                      alt="user-img"
                      className="img-thumbnail rounded-circle"
                      style={{ width: "100px", height: "100px" }}
                    />
                  ) : (
                    <div
                      className="img-thumbnail rounded-circle bg-primary text-white d-flex align-items-center justify-content-center"
                      style={{ width: "100px", height: "100px" }}
                    >
                      <span className="fs-24">{initials}</span>
                    </div>
                  )}

                  {patchProfileImageLoading && (
                    <div
                      className="position-absolute top-50 start-50 translate-middle"
                      style={{
                        width: "100px",
                        height: "100px",
                        backgroundColor: "rgba(0, 0, 0, 0.6)",
                        borderRadius: "50%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Spinner size="sm" color="light" />
                    </div>
                  )}

                  {/* Edit icon that shows on hover */}
                  {!patchProfileImageLoading && hovered && (
                    <div
                      className="position-absolute top-50 start-50 translate-middle"
                      style={{
                        width: "90px",
                        height: "90px",
                        backgroundColor: "rgba(0, 0, 0, 0.6)",
                        borderRadius: "50%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <i className="ri ri-pencil-line text-white fs-5"></i>
                    </div>
                  )}
                </div>
              </div>

              <Col>
                <div className="p-2">
                  <h3 className="text-white mb-2">
                    {employeeProfileData?.employee_fullname}
                  </h3>
                  <p className="text-white text-opacity-75 fs-5">
                    {employeeProfileData?.position_name}
                    {/* {employeeProfileData?.role_name &&
                      employeeProfileData.role_name.join("& ")} */}
                  </p>
                  {/* <p className="text-white text-opacity-75">
                    {employeeProfileData?.employee_fullname}Owner & Founder
                  </p> */}
                  <div className="hstack text-white-50 gap-1">
                    <div className="me-2 fs-5">
                      <i className="ri-account-box-line me-1 text-white text-opacity-75 fs-16 align-middle"></i>
                      {data?.employee_number}
                    </div>
                    <div className="me-2 fs-5">
                      <i className="ri-mail-line me-1 text-white text-opacity-75 fs-16 align-middle"></i>
                      {data?.work_email}
                    </div>
                    <div className="me-2 fs-5">
                      <i className="ri-phone-line me-1 text-white text-opacity-75 fs-16 align-middle"></i>
                      {data?.work_phone}
                    </div>
                  </div>
                </div>
              </Col>

              {/* <Col xs={12} className="col-lg-auto order-last order-lg-0">
                <Row className="text text-white-50 text-center">
                  <Col lg={6} xs={4}>
                    <div className="p-2">
                      <h4 className="text-white mb-1">24.3K</h4>
                      <p className="fs-14 mb-0">Followers</p>
                    </div>
                  </Col>
                  <Col lg={6} xs={4}>
                    <div className="p-2">
                      <h4 className="text-white mb-1">1.3K</h4>
                      <p className="fs-14 mb-0">Following</p>
                    </div>
                  </Col>
                </Row>
              </Col> */}
            </Row>
          </div>

          <Row>
            <Col lg={12}>
              <div>
                <div className="d-flex">
                  <Nav
                    pills
                    className="animation-nav profile-nav gap-2 gap-lg-3 flex-grow-1"
                    role="tablist"
                  >
                    <NavItem>
                      <NavLink
                        href="#overview-tab"
                        className={classnames(
                          { active: activeTab === "1" },
                          "fs-14"
                        )}
                        onClick={() => {
                          toggleTab("1");
                        }}
                      >
                        <i className="ri-airplay-fill d-inline-block d-md-none"></i>{" "}
                        <span className="d-none d-md-inline-block">
                          Overview
                        </span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        href="#activities"
                        className={classnames(
                          { active: activeTab === "2" },
                          "fs-14"
                        )}
                        onClick={() => {
                          toggleTab("2");
                        }}
                      >
                        <i className="ri-list-unordered d-inline-block d-md-none"></i>{" "}
                        <span className="d-none d-md-inline-block">
                          Activities
                        </span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        href="#projects"
                        className={classnames(
                          { active: activeTab === "3" },
                          "fs-14"
                        )}
                        onClick={() => {
                          toggleTab("3");
                        }}
                      >
                        <i className="ri-price-tag-line d-inline-block d-md-none"></i>{" "}
                        <span className="d-none d-md-inline-block">
                          Projects
                        </span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        href="#documents"
                        className={classnames(
                          { active: activeTab === "4" },
                          "fs-14"
                        )}
                        onClick={() => {
                          toggleTab("4");
                        }}
                      >
                        <i className="ri-folder-4-line d-inline-block d-md-none"></i>{" "}
                        <span className="d-none d-md-inline-block">
                          Documents
                        </span>
                      </NavLink>
                    </NavItem>
                  </Nav>
                  {/* <div className="flex-shrink-0">
                    <Link
                      to={`/pages-profile-settings/${employeeProfileData?.employee_uuid}`}
                      className="btn btn-success"
                    >
                      <i className="ri-edit-box-line align-bottom"></i> Edit
                      Profile
                    </Link>
                  </div> */}
                </div>

                <TabContent activeTab={activeTab} className="pt-4">
                  <TabPane tabId="1">
                    <Row>
                      <Col xxl={3}>
                        <Card>
                          <CardBody>
                            <h5 className="card-title mb-5">
                              Complete Your Profile
                            </h5>
                            <Progress
                              value={
                                employeeProfileData?.overall_status
                                  ?.average_percentage
                              }
                              color="danger"
                              className="animated-progess custom-progress progress-label"
                            >
                              <div className="label">
                                {
                                  employeeProfileData?.overall_status
                                    ?.average_percentage
                                }
                                %
                              </div>{" "}
                            </Progress>
                          </CardBody>
                        </Card>

                        {/* <EmployeeSideBar
                          cardView={cardView}
                          setCardView={setCardView}
                        /> */}

                        {/* <Card>
                          <CardBody>
                            <h5 className="card-title mb-3">Info</h5>
                            <div className="table-responsive">
                              <Table className="table-borderless mb-0">
                                <tbody>
                                  <tr>
                                    <th className="ps-0" scope="row">
                                      Full Name :
                                    </th>
                                    <td className="text-muted">
                                      {employeeProfileData?.employee_fullname}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th className="ps-0" scope="row">
                                      Mobile :
                                    </th>
                                    <td className="text-muted">
                                      {employeeProfileData?.work_phone}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th className="ps-0" scope="row">
                                      E-mail :
                                    </th>
                                    <td className="text-muted">
                                      {employeeProfileData?.work_email}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th className="ps-0" scope="row">
                                      Location :
                                    </th>
                                    <td className="text-muted">
                                      California, United States
                                    </td>
                                  </tr>
                                  <tr>
                                    <th className="ps-0" scope="row">
                                      Joining Date
                                    </th>
                                    <td className="text-muted">
                                      {employeeProfileData?.employee_start_date}
                                    </td>
                                  </tr>
                                </tbody>
                              </Table>
                            </div>
                          </CardBody>
                        </Card> */}

                        <Card>
                          <CardBody>
                            <div className="d-flex align-items-center mb-4">
                              <div className="flex-grow-1">
                                <h5 className="card-title mb-0">
                                  Assigned Projects
                                </h5>
                              </div>
                              {/* <div className="flex-shrink-0">
                                <UncontrolledDropdown direction="start">
                                  <DropdownToggle
                                    tag="a"
                                    id="dropdownMenuLink2"
                                    role="button"
                                  >
                                    <i className="ri-more-2-fill fs-14"></i>
                                  </DropdownToggle>
                                  <DropdownMenu>
                                    <DropdownItem>View</DropdownItem>
                                    <DropdownItem>Edit</DropdownItem>
                                    <DropdownItem>Delete</DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </div> */}
                            </div>

                            {/* Check if project_list has data */}
                            {employeeProfileData?.project_list &&
                            employeeProfileData?.project_list.length > 0 ? (
                              employeeProfileData?.project_list.map(
                                (project, index) => (
                                  <div
                                    className="d-flex align-items-center py-3"
                                    key={index}
                                  >
                                    <div className="avatar-xs flex-shrink-0 me-3">
                                      <img
                                        src={avatar3}
                                        alt=""
                                        className="img-fluid rounded-circle"
                                      />
                                    </div>
                                    <div className="flex-grow-1">
                                      <div>
                                        <h5 className="fs-14 mb-1">
                                          {project?.project_name}
                                        </h5>
                                        <p className="fs-11 text-muted mb-0">
                                          Assigned on: {project?.assigned_date}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="flex-shrink-0 ms-2">
                                      <p className="btn btn-sm btn-outline-success shadow-none">
                                        {project.status === 1
                                          ? "Active"
                                          : "Inactive"}
                                      </p>
                                      {/* <button
                                        type="button"
                                        className="btn btn-sm btn-outline-success shadow-none"
                                      >
                                        <i className="ri-user-add-line align-middle"></i>
                                      </button> */}
                                    </div>
                                  </div>
                                )
                              )
                            ) : (
                              // Fallback content if no projects are found
                              <p className="text-muted">No projects assigned</p>
                            )}
                          </CardBody>
                        </Card>

                        <Card>
                          <CardBody>
                            <h5 className="card-title mb-4">Skills</h5>
                            <div className="d-flex flex-wrap gap-2 fs-15">
                              {skillsArray?.length > 0 ? (
                                <>
                                  {skillsArray.map((skill, index) => (
                                    <div
                                      key={index}
                                      className="badge bg-primary-subtle text-primary"
                                    >
                                      {skill}
                                    </div>
                                  ))}
                                </>
                              ) : (
                                <div className="text-muted">
                                  No skills added yet..
                                </div>
                              )}
                            </div>
                          </CardBody>
                        </Card>

                        <Card>
                          <CardBody>
                            {/* Username Section */}
                            <h5 className="card-title  mb-4">User Details</h5>
                            <div className="d-flex flex-wrap gap-2 fs-15">
                              <div>
                                <strong>Username: </strong> {userName}
                              </div>
                              <i
                                className="ri-file-copy-fill text-primary cursor-pointer"
                                onClick={() =>
                                  copyToClipboard(userName, "Username")
                                }
                                style={{ cursor: "pointer" }}
                              ></i>
                              {/* <FaCopy
                                className="text-primary cursor-pointer"
                                
                              /> */}
                            </div>
                            {/* Tooltip for Username */}
                            {showTooltip && copiedText === "Username" && (
                              <div className="text-success mt-2">
                                Username copied!
                              </div>
                            )}

                            {/* Password Section */}
                            <div className="d-flex align-items-center justify-content-between mt-3">
                              <div>
                                <strong>Password: </strong>
                                {showPassword ? password : "******"}
                              </div>
                              <div className="d-flex align-items-center">
                                {/* Show/Hide Password */}

                                {showPassword ? (
                                  <i
                                    className="bx bx-hide text-primary cursor-pointer"
                                    onClick={() => setShowPassword(false)}
                                    style={{ cursor: "pointer" }}
                                  ></i>
                                ) : (
                                  <i
                                    className="bx bx-show text-primary cursor-pointer"
                                    onClick={() => setShowPassword(true)}
                                    style={{ cursor: "pointer" }}
                                  ></i>
                                )}
                                {/* Copy Password */}
                                <i
                                  className="ri-file-copy-fill text-primary cursor-pointer"
                                  onClick={() =>
                                    copyToClipboard(password, "Password")
                                  }
                                  style={{ cursor: "pointer" }}
                                ></i>
                              </div>
                              {/* Tooltip for Password */}
                            </div>
                            {showTooltip && copiedText === "Password" && (
                              <div className="text-success mt-2">
                                Password copied!
                              </div>
                            )}
                          </CardBody>
                        </Card>

                        <Card>
                          <CardBody>
                            <h5 className="card-title mb-4">Role Permission</h5>
                            <div className="d-flex flex-wrap gap-2 fs-15">
                              {rolesArray?.length > 0 ? (
                                <>
                                  {rolesArray?.map((roles, index) => (
                                    <div
                                      key={index}
                                      className="badge bg-primary-subtle text-primary"
                                    >
                                      {roles}
                                    </div>
                                  ))}
                                </>
                              ) : (
                                <div className="text-muted">
                                  No permission's assigned yet..
                                </div>
                              )}
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col xxl={9}>
                        {(cardView?.summary || cardView?.profile) && (
                          <WorkProfile
                            setCardView={setCardView}
                            employeeProfileData={employeeProfileData}
                          />
                        )}
                        {cardView?.profileEdit && (
                          <WorkProfileEdit
                            setCardView={setCardView}
                            employee_uuid={employee_uuid}
                          />
                        )}

                        {(cardView?.summary || cardView?.personalInfo) && (
                          <PersonalInfoView
                            setCardView={setCardView}
                            personalInfoData={personalInfo}
                          />
                        )}

                        {cardView?.personalInfoEdit && (
                          <PersonalInfoEdit
                            setCardView={setCardView}
                            employee_uuid={employee_uuid}
                          />
                        )}

                        {(cardView?.summary || cardView?.emergencyContact) && (
                          <EmergencyContactInfoView
                            setCardView={setCardView}
                            emergencyContactData={emergencyContactInfo}
                          />
                        )}

                        {cardView?.emergencyContactEdit && (
                          <EmergencyContactEdit
                            setCardView={setCardView}
                            employee_uuid={employee_uuid}
                          />
                        )}
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="2">
                    <Card>
                      <CardBody>
                        <h5 className="card-title mb-3">Activities</h5>
                        <div className="text-center">
                          <p className="text-muted">
                            No activities founded yet..
                          </p>
                        </div>
                        {/* <div className="acitivity-timeline">
                          <div className="acitivity-item d-flex">
                            <div className="flex-shrink-0">
                              <img
                                src={avatar1}
                                alt=""
                                className="avatar-xs rounded-circle acitivity-avatar shadow"
                              />
                            </div>
                            <div className="flex-grow-1 ms-3">
                              <h6 className="mb-1">
                                Oliver Phillips{" "}
                                <span className="badge bg-primary-subtle text-primary align-middle">
                                  New
                                </span>
                              </h6>
                              <p className="text-muted mb-2">
                                We talked about a project on linkedin.
                              </p>
                              <small className="mb-0 text-muted">Today</small>
                            </div>
                          </div>
                          <div className="acitivity-item py-3 d-flex">
                            <div className="flex-shrink-0 avatar-xs acitivity-avatar">
                              <div className="avatar-title bg-success-subtle text-success rounded-circle shadow">
                                {" "}
                                N{" "}
                              </div>
                            </div>
                            <div className="flex-grow-1 ms-3">
                              <h6 className="mb-1">
                                Nancy Martino{" "}
                                <span className="badge bg-secondary-subtle text-secondary align-middle">
                                  In Progress
                                </span>
                              </h6>
                              <p className="text-muted mb-2">
                                <i className="ri-file-text-line align-middle ms-2"></i>
                                Create new project Buildng product
                              </p>
                              <div className="avatar-group mb-2">
                                <Link
                                  to="#"
                                  className="avatar-group-item"
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="top"
                                  title=""
                                  data-bs-original-title="Christi"
                                >
                                  <img
                                    src={avatar4}
                                    alt=""
                                    className="rounded-circle avatar-xs"
                                  />
                                </Link>
                                <Link
                                  to="#"
                                  className="avatar-group-item"
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="top"
                                  title=""
                                  data-bs-original-title="Frank Hook"
                                >
                                  <img
                                    src={avatar3}
                                    alt=""
                                    className="rounded-circle avatar-xs"
                                  />
                                </Link>
                                <Link
                                  to="#"
                                  className="avatar-group-item"
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="top"
                                  title=""
                                  data-bs-original-title=" Ruby"
                                >
                                  <div className="avatar-xs">
                                    <div className="avatar-title rounded-circle bg-light text-primary">
                                      R
                                    </div>
                                  </div>
                                </Link>
                                <Link
                                  to="#"
                                  className="avatar-group-item"
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="top"
                                  title=""
                                  data-bs-original-title="more"
                                >
                                  <div className="avatar-xs">
                                    <div className="avatar-title rounded-circle">
                                      2+
                                    </div>
                                  </div>
                                </Link>
                              </div>
                              <small className="mb-0 text-muted">
                                Yesterday
                              </small>
                            </div>
                          </div>
                          <div className="acitivity-item py-3 d-flex">
                            <div className="flex-shrink-0">
                              <img
                                src={avatar2}
                                alt=""
                                className="avatar-xs rounded-circle acitivity-avatar shadow"
                              />
                            </div>
                            <div className="flex-grow-1 ms-3">
                              <h6 className="mb-1">
                                Natasha Carey{" "}
                                <span className="badge bg-success-subtle text-success align-middle">
                                  Completed
                                </span>
                              </h6>
                              <p className="text-muted mb-2">
                                Adding a new event with attachments
                              </p>
                              <Row>
                                <Col xxl={4}>
                                  <div className="row border border-dashed gx-2 p-2 mb-2">
                                    <div className="col-4">
                                      <img
                                        src={smallImage2}
                                        alt=""
                                        className="img-fluid rounded"
                                      />
                                    </div>

                                    <div className="col-4">
                                      <img
                                        src={smallImage3}
                                        alt=""
                                        className="img-fluid rounded"
                                      />
                                    </div>

                                    <div className="col-4">
                                      <img
                                        src={smallImage4}
                                        alt=""
                                        className="img-fluid rounded"
                                      />
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                              <small className="mb-0 text-muted">25 Nov</small>
                            </div>
                          </div>
                          <div className="acitivity-item py-3 d-flex">
                            <div className="flex-shrink-0">
                              <img
                                src={avatar6}
                                alt=""
                                className="avatar-xs rounded-circle acitivity-avatar shadow"
                              />
                            </div>
                            <div className="flex-grow-1 ms-3">
                              <h6 className="mb-1">Bethany Johnson</h6>
                              <p className="text-muted mb-2">
                                added a new member to velzon dashboard
                              </p>
                              <small className="mb-0 text-muted">19 Nov</small>
                            </div>
                          </div>
                          <div className="acitivity-item py-3 d-flex">
                            <div className="flex-shrink-0">
                              <div className="avatar-xs acitivity-avatar">
                                <div className="avatar-title rounded-circle bg-danger-subtle text-danger shadow">
                                  <i className="ri-shopping-bag-line"></i>
                                </div>
                              </div>
                            </div>
                            <div className="flex-grow-1 ms-3">
                              <h6 className="mb-1">
                                Your order is placed{" "}
                                <span className="badge bg-danger-subtle text-danger align-middle ms-1">
                                  Out of Delivery
                                </span>
                              </h6>
                              <p className="text-muted mb-2">
                                These customers can rest assured their order has
                                been placed.
                              </p>
                              <small className="mb-0 text-muted">16 Nov</small>
                            </div>
                          </div>
                          <div className="acitivity-item py-3 d-flex">
                            <div className="flex-shrink-0">
                              <img
                                src={avatar7}
                                alt=""
                                className="avatar-xs rounded-circle acitivity-avatar shadow"
                              />
                            </div>
                            <div className="flex-grow-1 ms-3">
                              <h6 className="mb-1">Lewis Pratt</h6>
                              <p className="text-muted mb-2">
                                They all have something to say beyond the words
                                on the page. They can come across as casual or
                                neutral, exotic or graphic.{" "}
                              </p>
                              <small className="mb-0 text-muted">22 Oct</small>
                            </div>
                          </div>
                          <div className="acitivity-item py-3 d-flex">
                            <div className="flex-shrink-0">
                              <div className="avatar-xs acitivity-avatar">
                                <div className="avatar-title rounded-circle bg-info-subtle text-info shadow">
                                  <i className="ri-line-chart-line"></i>
                                </div>
                              </div>
                            </div>
                            <div className="flex-grow-1 ms-3">
                              <h6 className="mb-1">Monthly sales report</h6>
                              <p className="text-muted mb-2">
                                <span className="text-danger">2 days left</span>{" "}
                                notification to submit the monthly sales report.{" "}
                                <Link
                                  to="#"
                                  className="link-warning text-decoration-underline"
                                >
                                  Reports Builder
                                </Link>
                              </p>
                              <small className="mb-0 text-muted">15 Oct</small>
                            </div>
                          </div>
                          <div className="acitivity-item d-flex">
                            <div className="flex-shrink-0">
                              <img
                                src={avatar8}
                                alt=""
                                className="avatar-xs rounded-circle acitivity-avatar shadow"
                              />
                            </div>
                            <div className="flex-grow-1 ms-3">
                              <h6 className="mb-1">
                                New ticket received{" "}
                                <span className="badge bg-success-subtle text-success align-middle">
                                  Completed
                                </span>
                              </h6>
                              <p className="text-muted mb-2">
                                User{" "}
                                <span className="text-secondary">Erica245</span>{" "}
                                submitted a ticket.
                              </p>
                              <small className="mb-0 text-muted">26 Aug</small>
                            </div>
                          </div>
                        </div> */}
                      </CardBody>
                    </Card>
                  </TabPane>

                  <TabPane tabId="3">
                    <Card>
                      <CardBody>
                        <Row>
                          {/* Check if project_list has data */}
                          {employeeProfileData?.project_list &&
                          employeeProfileData?.project_list.length > 0 ? (
                            <div className="table-responsive">
                              <Table className="table-borderless align-middle mb-0">
                                <thead className="table-light">
                                  <tr>
                                    <th scope="col">Project</th>
                                    {/* <th scope="col">Description</th> */}
                                    <th scope="col">Assigned Date</th>
                                    <th scope="col">End Date</th>
                                    <th scope="col">Status</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {employeeProfileData.project_list.map(
                                    (project: any, index) => (
                                      <tr key={index}>
                                        <td>{project.project_name}</td>
                                        {/* <td>{project.description}</td> */}
                                        <td>{project.assigned_date}</td>
                                        <td>{project.end_date}</td>
                                        <td>
                                          <p
                                            className={`badge bg-${
                                              project.current_active
                                                ? "success"
                                                : "danger"
                                            }`}
                                          >
                                            {project.current_active
                                              ? "Active"
                                              : "Inactive"}
                                          </p>
                                        </td>
                                      </tr>
                                    )
                                  )}
                                </tbody>
                              </Table>
                            </div>
                          ) : (
                            // Fallback content if no projects are found
                            <p className="text-muted">No projects assigned</p>
                          )}
                        </Row>
                      </CardBody>
                    </Card>
                  </TabPane>

                  <TabPane tabId="4">
                    <EmployeeDocumentList employee_uuid={employee_uuid} />
                  </TabPane>
                </TabContent>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EmployeeProfile;
