import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { useCreateSectionImplementedMutation } from "slices/resourceInventory/inventorySlice";
import LoadingOverlay from "react-loading-overlay-ts";

interface PlannedModalProps {
  isOpen: boolean;
  toggle: () => void;
  productId: number | string;
  refetchInventory: () => void;
  initialQuantity?: number;
  productProcured: any;
}

const ImplementedToInitateModal: React.FC<PlannedModalProps> = ({
  isOpen,
  toggle,
  productId,
  refetchInventory,
  initialQuantity = 1,
  productProcured
}) => {

  const [createSectionImplemented, { isLoading: isCreating }] = useCreateSectionImplementedMutation();

  const handleSubmit = async (inv_product: number, toinitate_id: number, procuredQty: number) => {
    console.log("Submitting form with values:", inv_product, 'toinitate_id', toinitate_id, procuredQty ); // Debugging
    try {
      await createSectionImplemented({
        product: inv_product,
        quantity: procuredQty,
        toinitate_id: toinitate_id,
        next_action: "Implemented.To_Initiate",
      });
      toggle();
      refetchInventory();
    } catch (error) {
      console.error("Error adding procured section:", error);
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>Add To InProgress</ModalHeader>
      <hr />
      <LoadingOverlay
        active={isCreating}
        spinner
        text="Loading..."
      >
      <ModalBody>
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Product</th>
              <th>Planned</th>
              <th>Quoted</th>
              <th>Procured</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {/* Iterate through the toinitate_list and filter by procured_status == "Approved" */}
            {productProcured?.toinitate_list
              ?.filter((item: any) => item.delivered_status === "Delivered")
              .map((item: any, index: number) => (
                <tr key={index}>
                  {/* Display the product name */}
                  <td>{productProcured.product_name}</td>
                  
                  {/* Planned, Quoted, Procured quantities */}
                  <td>{item.planned_quantity_sum}</td>
                  <td>{item.quoted_quantity_sum}</td>
                  <td>{item.procured_quantity_sum}</td>

                  {/* Procured Status */}
                  <td>{item.delivered_status}</td>

                  {/* Action Button */}
                  <td>
                    <Button color="primary" onClick={() => handleSubmit(productProcured.id, item.id, item.procured_quantity_sum)} disabled={isCreating}>
                      To Initate
                    </Button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </ModalBody>
      </LoadingOverlay>
    </Modal>
  );
};

export default ImplementedToInitateModal;
