import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Row,
  CardFooter,
  CardHeader,
  Button,
} from "reactstrap";
import classNames from "classnames";
import { EmployeeData } from "../EmployeeProfile";

interface WorkProfilelProps {
  employeeProfileData: EmployeeData | null;
  setCardView: React.Dispatch<React.SetStateAction<Record<string, boolean>>>;
}

export const WorkProfile: React.FC<WorkProfilelProps> = ({
  employeeProfileData,
  setCardView,
}) => {
  // Split the skills string into an array of individual skills
  const skillsString = employeeProfileData?.skills || "";
  const skillsArray = skillsString ? skillsString.split(",") : [];

  const projectsString = employeeProfileData?.project_name || "";
  const projectsArray = projectsString ? projectsString.split(",") : [];
  return (
    <React.Fragment>
      <Card>
        <CardHeader className="d-flex align-items-center">
          <i className="ri-user-fill fs-4"></i>
          <h4 className="card-title flex-grow-1 mb-0"> Work Profile</h4>
          <div className="flex-shrink-0">
            <Button
              onClick={() => {
                setCardView({ profileEdit: true });
              }}
              className="btn btn-soft-info btn-md shadow-none"
            >
              Edit
            </Button>
          </div>
        </CardHeader>
        <CardBody>
          <div className="table-card">
            <table className="table mb-0">
              <tbody>
                <tr>
                  <td className="fw-medium" style={{ width: "300px" }}>
                    Full Name
                  </td>
                  <td>{employeeProfileData?.employee_fullname}</td>
                </tr>
                <tr>
                  <td className="fw-medium">Employee Number</td>
                  <td>{employeeProfileData?.employee_number}</td>
                </tr>
                <tr>
                  <td className="fw-medium">E-mail</td>
                  <td>{employeeProfileData?.work_email}</td>
                </tr>
                <tr>
                  <td className="fw-medium">Mobile</td>
                  <td>{employeeProfileData?.work_phone}</td>
                </tr>
                <tr>
                  <td className="fw-medium">Joining Date</td>
                  <td>{employeeProfileData?.employee_start_date}</td>
                </tr>
                <tr>
                  <td className="fw-medium">Position</td>
                  <td>{employeeProfileData?.position_name}</td>
                </tr>
                <tr>
                  <td className="fw-medium">Skills</td>
                  <td>
                    {skillsArray.map((skill, index) => (
                      <div
                        key={index}
                        className="badge bg-primary-subtle text-primary"
                      >
                        {skill}
                      </div>
                    ))}
                  </td>
                </tr>
                <tr>
                  <td className="fw-medium">Onboarding Mentor</td>
                  <td> {employeeProfileData?.onboarding_mentor_name}</td>
                </tr>
                <tr>
                  <td className="fw-medium">Reporting Manager</td>
                  <td> {employeeProfileData?.direct_manager_name}</td>
                </tr>
                <tr>
                  <td className="fw-medium"> Project/Work/Branch</td>
                  <td>
                    {projectsArray.map((skill, index) => (
                      <div
                        key={index}
                        className="badge bg-primary-subtle text-primary"
                      >
                        {skill}
                      </div>
                    ))}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          {/* <Row>
            <Col xs={6} md={4}>
              <div className="d-flex mt-4">
                <div className="flex-grow-1 overflow-hidden">
                  <h6 className="text-truncate fw-semibold mb-0">
                    Full Name :
                  </h6>
                </div>
              </div>
            </Col>
            <Col xs={6} md={4}>
              <div className="d-flex mt-4">
                <div className="flex-grow-1 overflow-hidden">
                  <p className="mb-1">
                    {employeeProfileData?.employee_fullname}
                  </p>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={6} md={4}>
              <div className="d-flex mt-4">
                <div className="flex-grow-1 overflow-hidden">
                  <h6 className="text-truncate fw-semibold mb-0">
                    Employee Number :
                  </h6>
                </div>
              </div>
            </Col>
            <Col xs={6} md={4}>
              <div className="d-flex mt-4">
                <div className="flex-grow-1 overflow-hidden">
                  <p className="mb-1">{employeeProfileData?.employee_number}</p>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={6} md={4}>
              <div className="d-flex mt-4">
                <div className="flex-grow-1 overflow-hidden">
                  <h6 className="text-truncate fw-semibold mb-0">E-mail :</h6>
                </div>
              </div>
            </Col>
            <Col xs={6} md={4}>
              <div className="d-flex mt-4">
                <div className="flex-grow-1 overflow-hidden">
                  <p className="mb-1">{employeeProfileData?.work_email}</p>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={6} md={4}>
              <div className="d-flex mt-4">
                <div className="flex-grow-1 overflow-hidden">
                  <h6 className="text-truncate fw-semibold mb-0">Mobile :</h6>
                </div>
              </div>
            </Col>
            <Col xs={6} md={4}>
              <div className="d-flex mt-4">
                <div className="flex-grow-1 overflow-hidden">
                  <p className="mb-1">{employeeProfileData?.work_phone}</p>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={6} md={4}>
              <div className="d-flex mt-4">
                <div className="flex-grow-1 overflow-hidden">
                  <h6 className="text-truncate fw-semibold mb-0">
                    Joining Date :
                  </h6>
                </div>
              </div>
            </Col>
            <Col xs={6} md={4}>
              <div className="d-flex mt-4">
                <div className="flex-grow-1 overflow-hidden">
                  <p className="mb-1">
                    {employeeProfileData?.employee_start_date}
                  </p>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={6} md={4}>
              <div className="d-flex mt-4">
                <div className="flex-grow-1 overflow-hidden">
                  <h6 className="text-truncate fw-semibold mb-0">Position :</h6>
                </div>
              </div>
            </Col>
            <Col xs={6} md={4}>
              <div className="d-flex mt-4">
                <div className="flex-grow-1 overflow-hidden">
                  <p className="mb-1">{employeeProfileData?.position_name}</p>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={6} md={4}>
              <div className="d-flex mt-4">
                <div className="flex-grow-1 overflow-hidden">
                  <h6 className="text-truncate fw-semibold mb-0">Skills :</h6>
                </div>
              </div>
            </Col>
            <Col xs={6} md={4}>
              <div className="d-flex mt-4">
                <div className="flex-grow-1 overflow-hidden">
                  {skillsArray.map((skill, index) => (
                    <div
                      key={index}
                      className="badge bg-primary-subtle text-primary"
                    >
                      {skill}
                    </div>
                  ))}
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={6} md={4}>
              <div className="d-flex mt-4">
                <div className="flex-grow-1 overflow-hidden">
                  <h6 className="text-truncate fw-semibold mb-0">
                    Onboarding Mentor :
                  </h6>
                </div>
              </div>
            </Col>
            <Col xs={6} md={4}>
              <div className="d-flex mt-4">
                <div className="flex-grow-1 overflow-hidden">
                  <p className="mb-1">
                    {" "}
                    {employeeProfileData?.onboarding_mentor_name}
                  </p>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={6} md={4}>
              <div className="d-flex mt-4">
                <div className="flex-grow-1 overflow-hidden">
                  <h6 className="text-truncate fw-semibold mb-0">
                    Reporting Manager:
                  </h6>
                </div>
              </div>
            </Col>
            <Col xs={6} md={4}>
              <div className="d-flex mt-4">
                <div className="flex-grow-1 overflow-hidden">
                  <p className="mb-1">
                    {" "}
                    {employeeProfileData?.direct_manager_name}
                  </p>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={6} md={4}>
              <div className="d-flex mt-4">
                <div className="flex-grow-1 overflow-hidden">
                  <h6 className="text-truncate fw-semibold mb-0">
                    Project/Work/Branch :
                  </h6>
                </div>
              </div>
            </Col>
            <Col xs={6} md={4}>
              <div className="d-flex mt-4">
                <div className="flex-grow-1 overflow-hidden">
                  {projectsArray.map((skill, index) => (
                    <div
                      key={index}
                      className="badge bg-primary-subtle text-primary"
                    >
                      {skill}
                    </div>
                  ))}
                </div>
              </div>
            </Col>
          </Row> */}
        </CardBody>
      </Card>
    </React.Fragment>
  );
};
