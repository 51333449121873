import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { WorkflowType, ApprovalType } from "./type";

const getToken = () => {
  const authUser = sessionStorage.getItem("authUser");
  return authUser ? JSON.parse(authUser).access : null;
};

export const workflowSlice = createApi({
  reducerPath: "workflowApi",
  baseQuery: fetchBaseQuery({
    baseUrl: window.__RUNTIME_CONFIG__.REACT_APP_API_URL,
    prepareHeaders: (headers) => {
      const token = getToken();
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getWorkflows: builder.query<WorkflowType[], void>({
      query: () => "/workflow/api/v1/workflow/",
    }),
    getWorkflowById: builder.query({
      query: (workflowId) => `/workflow/api/v1/workflow/${workflowId}/`,
    }),
    createWorkflow: builder.mutation({
      query: (workflowData) => ({
        url: `/workflow/api/v1/create_update_workflow/`,
        method: "POST",
        body: workflowData,
      }),
    }),
    updateWorkflow: builder.mutation({
      query: ({ id, ...workflowData }) => ({
        url: `/workflow/api/v1/create_update_workflow/${id}/`,
        method: "PATCH",
        body: workflowData,
      }),
    }),

    getRoles: builder.query({
      query: () => "/organization/api/v1/roles/",
    }),
    getWorkFlowApprovals: builder.query({
      query: () => "/workflow/api/v1/workflow_approvals/",
    }),
    getWorkFlowApprovalsByProject: builder.query({
      query: (projectId) => `/workflow/api/v1/workflow_approvals/?project=${projectId}`,
    }),
    createApproveWorkflow: builder.mutation({
      query: (approvalData) => ({
        url: `/workflow/api/v1/approve_workflow/`,
        method: "POST",
        body: approvalData,
      }),
    }),
    updateWorkflowProject: builder.mutation({
      query: ({ id, ...updateData }) => ({
        url: `/workflow/api/v1/workflow_project_update/${id}/`,
        method: "PATCH",
        body: updateData,
      }),
    }),
  }),
});

export const {
  useGetWorkflowsQuery,
  useGetWorkflowByIdQuery,
  useCreateWorkflowMutation,
  useUpdateWorkflowMutation,
  useGetRolesQuery,
  useGetWorkFlowApprovalsQuery,
  useGetWorkFlowApprovalsByProjectQuery,
  useCreateApproveWorkflowMutation,
  useUpdateWorkflowProjectMutation,
} = workflowSlice;
