import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const getToken = () => {
  const authUser = sessionStorage.getItem("authUser");
  return authUser ? JSON.parse(authUser).access : null;
};

export const resourceInventorySlice = createApi({
  reducerPath: "resourceInventoryApi",
  baseQuery: fetchBaseQuery({
    baseUrl: window.__RUNTIME_CONFIG__.REACT_APP_API_URL,
    prepareHeaders: (headers) => {
      const token = getToken();
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    // Suppliers
    getSuppliers: builder.query({
      query: () => "/resource_inventory/suppliers/",
    }),
    getSupplierById: builder.query({
      query: (id) => `/resource_inventory/suppliers/${id}/`,
    }),
    createSupplier: builder.mutation({
      query: (newSupplier) => ({
        url: "/resource_inventory/suppliers/",
        method: "POST",
        body: newSupplier,
      }),
    }),
    updateSupplier: builder.mutation({
      query: ({ id, data }) => ({
        url: `/resource_inventory/suppliers/${id}/`,
        method: "PATCH",
        body: data,
      }),
    }),
    deleteSupplierById: builder.mutation({
      query: (id) => ({
        url: `/resource_inventory/suppliers/${id}/`,
        method: "DELETE",
      }),
    }),

    // Stocks
    getStocks: builder.query({
      query: () => "/resource_inventory/stocks/",
    }),
    getStockById: builder.query({
      query: (id) => `/resource_inventory/stocks/${id}/`,
    }),
    getStockByProduct: builder.query({
      query: (productId) => `/resource_inventory/stocks/?product=${productId}`,
    }),
    createStock: builder.mutation({
      query: (newStock) => ({
        url: "/resource_inventory/stocks/",
        method: "POST",
        body: newStock,
      }),
    }),
    updateStock: builder.mutation({
      query: ({ id, data }) => ({
        url: `/resource_inventory/stocks/${id}/`,
        method: "PATCH",
        body: data,
      }),
    }),
    deleteStockById: builder.mutation({
      query: (id) => ({
        url: `/resource_inventory/stocks/${id}/`,
        method: "DELETE",
      }),
    }),

    // Products
    getProducts: builder.query({
      query: () => "/resource_inventory/products/",
    }),
    getProductById: builder.query({
      query: (id) => `/resource_inventory/products/${id}/`,
    }),
    createProduct: builder.mutation({
      query: (newProduct) => ({
        url: "/resource_inventory/products/",
        method: "POST",
        body: newProduct,
      }),
    }),
    updateProduct: builder.mutation({
      query: ({ id, data }) => ({
        url: `/resource_inventory/products/${id}/`,
        method: "PATCH",
        body: data,
      }),
    }),
    deleteProductById: builder.mutation({
      query: (id) => ({
        url: `/resource_inventory/products/${id}/`,
        method: "DELETE",
      }),
    }),

    // Measurements
    getMeasurements: builder.query({
      query: () => "/resource_inventory/measurements/",
    }),
    getMeasurementById: builder.query({
      query: (id) => `/resource_inventory/measurements/${id}/`,
    }),
    createMeasurement: builder.mutation({
      query: (newMeasurement) => ({
        url: "/resource_inventory/measurements/",
        method: "POST",
        body: newMeasurement,
      }),
    }),
    updateMeasurement: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/resource_inventory/measurements/${id}/`,
        method: "PATCH",
        body: data,
      }),
    }),
    deleteMeasurementById: builder.mutation({
      query: (id) => ({
        url: `/resource_inventory/measurements/${id}/`,
        method: "DELETE",
      }),
    }),

    // Categories
    getCategories: builder.query({
      query: () => "/resource_inventory/categories/",
    }),
    getCategoryById: builder.query({
      query: (id) => `/resource_inventory/categories/${id}/`,
    }),
    createCategory: builder.mutation({
      query: (newCategory) => ({
        url: "/resource_inventory/categories/",
        method: "POST",
        body: newCategory,
      }),
    }),
    updateCategory: builder.mutation({
      query: ({ id, data }) => ({
        url: `/resource_inventory/categories/${id}/`,
        method: "PATCH",
        body: data,
      }),
    }),
    deleteCategoryById: builder.mutation({
      query: (id) => ({
        url: `/resource_inventory/categories/${id}/`,
        method: "DELETE",
      }),
    }),

    //Dashboard
    getInventory: builder.query({
      query: () => "/resource_inventory/resource/list_inventory",
    }),

    createSection: builder.mutation({
      query: (newSection) => ({
        url: "/resource_inventory/resource/section/",
        method: "POST",
        body: newSection,
      }),
    }),

    createSectionProduct: builder.mutation({
      query: (newSectionProduct) => ({
        url: "/resource_inventory/resource/product/",
        method: "POST",
        body: newSectionProduct,
      }),
    }),

    createSectionQuoted: builder.mutation({
      query: (newSectionQuoted) => ({
        url: "/resource_inventory/resource/quoted/",
        method: "POST",
        body: newSectionQuoted,
      }),
    }),

    createSectionPlanned: builder.mutation({
      query: (newSectionPlanned) => ({
        url: "/resource_inventory/resource/planned/",
        method: "POST",
        body: newSectionPlanned,
      }),
    }),

    createSectionProcured: builder.mutation({
      query: (newSectionProcured) => ({
        url: "/resource_inventory/resource/procured/",
        method: "POST",
        body: newSectionProcured,
      }),
    }),

    createSectionDelivered: builder.mutation({
      query: (newSectionDelivered) => ({
        url: "/resource_inventory/resource/delivered/",
        method: "POST",
        body: newSectionDelivered,
      }),
    }),

    createSectionImplemented: builder.mutation({
      query: (newSectionImplemented) => ({
        url: "/resource_inventory/resource/implemented/",
        method: "POST",
        body: newSectionImplemented,
      }),
    }),
  }),
});

export const {
  useGetSuppliersQuery,
  useGetSupplierByIdQuery,
  useCreateSupplierMutation,
  useUpdateSupplierMutation,
  useDeleteSupplierByIdMutation,

  useGetStocksQuery,
  useGetStockByIdQuery,
  useGetStockByProductQuery,
  useCreateStockMutation,
  useUpdateStockMutation,
  useDeleteStockByIdMutation,

  useGetProductsQuery,
  useGetProductByIdQuery,
  useCreateProductMutation,
  useUpdateProductMutation,
  useDeleteProductByIdMutation,

  useGetMeasurementsQuery,
  useGetMeasurementByIdQuery,
  useCreateMeasurementMutation,
  useUpdateMeasurementMutation,
  useDeleteMeasurementByIdMutation,

  useGetCategoriesQuery,
  useGetCategoryByIdQuery,
  useCreateCategoryMutation,
  useUpdateCategoryMutation,
  useDeleteCategoryByIdMutation,

  useGetInventoryQuery,
  useCreateSectionMutation,
  useCreateSectionProductMutation,
  useCreateSectionQuotedMutation,
  useCreateSectionPlannedMutation,
  useCreateSectionProcuredMutation,
  useCreateSectionDeliveredMutation,
  useCreateSectionImplementedMutation,
} = resourceInventorySlice;
