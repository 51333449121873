import React, { useMemo, useState } from "react";
import { Container, Row, Col, Card, CardHeader, Button } from "reactstrap";
import TableContainer from "../../../Components/Common/TableContainer";
import Loader from "../../../Components/Common/Loader";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import {
  useGetSuppliersQuery,
  useGetSupplierByIdQuery,
} from "slices/resourceInventory/inventorySlice";
import { toast, ToastContainer } from "react-toastify";
import { SupplierEdit } from "./SuppliersEdit";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const SupplierListView = () => {
  const navigate = useNavigate();

  const [editingSupplierId, setEditingSupplierId] = useState<
    string | undefined
  >(undefined); // State to manage add/edit mode
  const [isAdding, setIsAdding] = useState(false); // State to track if adding a new supplier

  // Fetch supplier data
  const {
    data: supplierData,
    isLoading: isLoadingSuppliers,
    refetch: refetchSuppliers,
  } = useGetSuppliersQuery(undefined);

  // Handle Add and Edit actions
  const handleAddSupplier = () => {
    setEditingSupplierId(undefined); // Clear editing ID for new supplier
    setIsAdding(true); // Set to adding mode
  };

  const handleEditSupplier = (id: string) => {
    setEditingSupplierId(id); // Set the ID for editing
    setIsAdding(false); // Set to editing mode
  };

  // Handle returning to list view
  const handleBackToList = () => {
    setEditingSupplierId(undefined); // Clear the state
    setIsAdding(false); // Exit adding/editing mode
  };

  const handleViewSupplier = (id: string) => {
    navigate(`/supplier-view/${id}`);
  };

  // Define table columns
  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        filterable: false,
      },
      {
        Header: "Contact Name",
        accessor: "contact_name",
        filterable: false,
      },
      {
        Header: "Contact Email",
        accessor: "contact_email",
        filterable: false,
      },
      {
        Header: "Contact Phone",
        accessor: "contact_phone",
        filterable: false,
      },
      {
        Header: "Address",
        accessor: "address",
        filterable: false,
      },
      {
        Header: "Website",
        accessor: "website",
        filterable: false,
      },
      {
        Header: "Actions",
        accessor: "actions",
        Cell: ({ row }: any) => {
          const supplierId = row.original.id;
          return (
            <div>
              <ul className="list-inline hstack gap-2 mb-0">
                <li className="list-inline-item edit" title="Edit">
                  <Link
                    to="#"
                    className="text-primary d-inline-block edit-item-btn"
                    onClick={() => handleEditSupplier(supplierId)}
                  >
                    <i className="ri-pencil-fill fs-16"></i>
                  </Link>
                </li>
                <li className="list-inline-item edit" title="View">
                  <Link
                    to={`/supplier-view/${supplierId}`}
                    className="text-primary d-inline-block edit-item-btn"
                    // onClick={() => handleViewSupplier(supplierId)}
                  >
                    <i className="ri-eye-fill fs-16"></i>
                  </Link>
                </li>
              </ul>
            </div>
          );
        },
      },
    ],
    []
  );

  document.title = "Suppliers | Dashboard";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Suppliers List" pageTitle="Suppliers" />
          {(editingSupplierId || isAdding) && (
            <Row className="mt-4">
              <Col lg={12}>
                <SupplierEdit
                  supplierId={editingSupplierId}
                  setCardView={handleBackToList} // Pass function to go back to list view
                />
              </Col>
            </Row>
          )}

          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader className="border-0 d-flex justify-content-between align-items-center">
                  <h5 className="card-title mb-0">Suppliers List</h5>
                  <Button
                    color="success"
                    onClick={handleAddSupplier}
                    disabled={isLoadingSuppliers}
                  >
                    <i className="ri-add-line align-bottom me-1"></i> Add
                    Supplier
                  </Button>
                </CardHeader>
                <div className="card-body pt-0">
                  {isLoadingSuppliers ? (
                    <Loader />
                  ) : (
                    <TableContainer
                      columns={columns}
                      data={supplierData?.results || []}
                      isGlobalFilter={true}
                      isAddUserList={false}
                      customPageSize={10}
                      className="custom-header-css"
                      // isCustomerFilter={true}
                      SearchPlaceholder={"Search for suppliers..."}
                      isPagination={true}
                    />
                  )}
                </div>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
};

export default SupplierListView;
