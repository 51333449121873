import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Navdata = () => {
  const history = useNavigate();
  //state data
  const [isDashboard, setIsDashboard] = useState<boolean>(false);
  const [isAuth, setIsAuth] = useState<boolean>(false);
  const [isProject, setIsProject] = useState<boolean>(false);
  const [isTask, setIsTask] = useState<boolean>(false);
  const [isMembers, setIsMembers] = useState<boolean>(false);
  const [isReport, setIsReport] = useState<boolean>(false);
  const [isWorkFlow, setIsWorkFlow] = useState<boolean>(false);
  const [isRoles, setIsRoles] = useState<boolean>(false);
  const [isInventory, setIsInventory] = useState<boolean>(false);

  // Authentication
  const [isSignIn, setIsSignIn] = useState<boolean>(false);
  const [isSignUp, setIsSignUp] = useState<boolean>(false);
  const [isPasswordReset, setIsPasswordReset] = useState<boolean>(false);
  const [isPasswordCreate, setIsPasswordCreate] = useState<boolean>(false);
  const [isLockScreen, setIsLockScreen] = useState<boolean>(false);
  const [isLogout, setIsLogout] = useState<boolean>(false);
  const [isSuccessMessage, setIsSuccessMessage] = useState<boolean>(false);
  const [isVerification, setIsVerification] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const [iscurrentState, setIscurrentState] = useState("Dashboard");

  function updateIconSidebar(e: any) {
    if (e && e.target && e.target.getAttribute("sub-items")) {
      const ul: any = document.getElementById("two-column-menu");
      const iconItems = ul.querySelectorAll(".nav-icon.active");
      let activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove("active");
        var id = item.getAttribute("sub-items");
        const getID = document.getElementById(id) as HTMLElement;
        if (getID) getID.classList.remove("show");
      });
    }
  }

  useEffect(() => {
    document.body.classList.remove("twocolumn-panel");
    if (iscurrentState !== "Dashboard") {
      setIsDashboard(false);
    }
    if (iscurrentState !== "Auth") {
      setIsAuth(false);
    }
    if (iscurrentState !== "Project") {
      setIsProject(false);
    }
    if (iscurrentState !== "Task") {
      setIsTask(false);
    }
    if (iscurrentState !== "Members") {
      setIsMembers(false);
    }
    if (iscurrentState !== "Report") {
      setIsReport(false);
    }
    if (iscurrentState !== "WorkFlow") {
      setIsWorkFlow(false);
    }
    if (iscurrentState !== "Roles") {
      setIsRoles(false);
    }
    if (iscurrentState !== "Inventory") {
      setIsInventory(false);
    }
  }, [
    history,
    iscurrentState,
    isDashboard,
    isAuth,
    isProject,
    isTask,
    isMembers,
    isReport,
    isWorkFlow,
    isRoles,
    isInventory,
  ]);

  const menuItems: any = [
    {
      label: "Home",
      isHeader: true,
    },
    {
      id: "dashboard",
      label: "Dashboards",
      icon: "mdi mdi-speedometer",
      link: "/dashboard",
      click: function (e: any) {
        e.preventDefault();
        setIscurrentState("Dashboard");
      },
    },
    {
      label: "apps",
      isHeader: true,
    },
    {
      id: "members",
      label: "Members",
      icon: "bx bx-file",
      link: "/#",
      click: function (e: any) {
        e.preventDefault();
        setIsMembers(!isMembers);
        setIscurrentState("Members");
        updateIconSidebar(e);
      },
      stateVariables: isMembers,
      subItems: [
        // {
        //   id: "members_profile",
        //   label: "Members Profile",
        //   link: "/pages-profile",
        //   parentId: "members",
        // },
        // {
        //   id: "members_list",
        //   label: "Members List",
        //   link: "/apps-ecommerce-orders",
        //   parentId: "members",
        // },
        {
          id: "members_customers",
          label: "Members",
          link: "/apps-ecommerce-customers",
          parentId: "members",
        },
      ],
    },
    {
      id: "Report",
      label: "Report",
      icon: "mdi mdi-file",
      link: "/report-list",
      click: function (e: any) {
        e.preventDefault();
        setIscurrentState("Report");
      },
    },
    {
      id: "WorkFlow",
      label: "WorkFlow",
      icon: "bx bx-line-chart-down",
      link: "/#",
      click: function (e: any) {
        e.preventDefault();
        setIscurrentState("WorkFlow");
        setIsWorkFlow(!isWorkFlow);
        updateIconSidebar(e);
      },
      stateVariables: isWorkFlow,
      subItems: [
        {
          id: "workflow_list",
          label: "WorkFlowList",
          link: "/workflow-list",
          parentId: "WorkFlow",
        },
        {
          id: "work_flow_approvels",
          label: "WorkFlow Approvals",
          link: "/workflowaccess-list",
          parentId: "WorkFlow",
        },
      ],
    },
    {
      id: "Access Control",
      label: "Access Control",
      icon: "bx bx-file",
      link: "/#",
      click: function (e: any) {
        e.preventDefault();
        setIsRoles(!isRoles);
        setIscurrentState("Roles");
        updateIconSidebar(e);
      },
      stateVariables: isRoles,
      subItems: [
        {
          id: "role_access",
          label: "Role Access",
          link: "/roles-list",
          parentId: "Access Control",
        },
        {
          id: "page_access",
          label: "Roles & Page Access",
          link: "/page-access-list",
          parentId: "Access Control",
        },
      ],
    },

    {
      id: "Inventory",
      label: "Resource Inventory",
      icon: "bx bx-wrench",
      link: "/#",
      click: function (e: any) {
        e.preventDefault();
        setIsInventory(!isInventory);
        setIscurrentState("Inventory");
        updateIconSidebar(e);
      },
      stateVariables: isInventory,
      subItems: [
        // {
        //   id: "dashboard",
        //   label: "DashBoard",
        //   link: "/inventory-dashboard",
        //   parentId: "Inventory",
        // },
        {
          id: "measurements",
          label: "Measurements",
          link: "/measurment-list",
          parentId: "Inventory",
        },
        {
          id: "suppliers",
          label: "Suppliers",
          link: "/supplier-list",
          parentId: "Inventory",
        },
        {
          id: "category",
          label: "Categories",
          link: "/category-list",
          parentId: "Inventory",
        },
        {
          id: "product",
          label: "Products",
          link: "/product-list",
          parentId: "Inventory",
        },
        {
          id: "stock",
          label: "Stocks",
          link: "/stock-list",
          parentId: "Inventory",
        },
      ],
    },
    // {
    //   id: "authentication",
    //   label: "Authentication",
    //   icon: "bx bx-user-circle",
    //   link: "/#",
    //   click: function (e: any) {
    //     e.preventDefault();
    //     setIsAuth(!isAuth);
    //     setIscurrentState("Auth");
    //     updateIconSidebar(e);
    //   },
    //   stateVariables: isAuth,
    //   subItems: [
    //     {
    //       id: "signIn",
    //       label: "Sign In",
    //       link: "/#",
    //       isChildItem: true,
    //       click: function (e: any) {
    //         e.preventDefault();
    //         setIsSignIn(!isSignIn);
    //       },
    //       parentId: "authentication",
    //       stateVariables: isSignIn,
    //       childItems: [
    //         { id: 1, label: "Basic", link: "/auth-signin-basic" },
    //         { id: 2, label: "Cover", link: "/auth-signin-cover" },
    //       ],
    //     },
    //     {
    //       id: "signUp",
    //       label: "Sign Up",
    //       link: "/#",
    //       isChildItem: true,
    //       click: function (e: any) {
    //         e.preventDefault();
    //         setIsSignUp(!isSignUp);
    //       },
    //       parentId: "authentication",
    //       stateVariables: isSignUp,
    //       childItems: [
    //         { id: 1, label: "Basic", link: "/auth-signup-basic" },
    //         { id: 2, label: "Cover", link: "/auth-signup-cover" },
    //       ],
    //     },
    //     {
    //       id: "passwordReset",
    //       label: "Password Reset",
    //       link: "/#",
    //       isChildItem: true,
    //       click: function (e: any) {
    //         e.preventDefault();
    //         setIsPasswordReset(!isPasswordReset);
    //       },
    //       parentId: "authentication",
    //       stateVariables: isPasswordReset,
    //       childItems: [
    //         { id: 1, label: "Basic", link: "/auth-pass-reset-basic" },
    //         { id: 2, label: "Cover", link: "/auth-pass-reset-cover" },
    //       ],
    //     },
    //     {
    //       id: "passwordCreate",
    //       label: "Password Create",
    //       link: "/#",
    //       isChildItem: true,
    //       click: function (e: any) {
    //         e.preventDefault();
    //         setIsPasswordCreate(!isPasswordCreate);
    //       },
    //       parentId: "authentication",
    //       stateVariables: isPasswordCreate,
    //       childItems: [
    //         { id: 1, label: "Basic", link: "/auth-pass-change-basic" },
    //         { id: 2, label: "Cover", link: "/auth-pass-change-cover" },
    //       ],
    //     },
    //     {
    //       id: "lockScreen",
    //       label: "Lock Screen",
    //       link: "/#",
    //       isChildItem: true,
    //       click: function (e: any) {
    //         e.preventDefault();
    //         setIsLockScreen(!isLockScreen);
    //       },
    //       parentId: "authentication",
    //       stateVariables: isLockScreen,
    //       childItems: [
    //         { id: 1, label: "Basic", link: "/auth-lockscreen-basic" },
    //         { id: 2, label: "Cover", link: "/auth-lockscreen-cover" },
    //       ],
    //     },
    //     {
    //       id: "logout",
    //       label: "Logout",
    //       link: "/#",
    //       isChildItem: true,
    //       click: function (e: any) {
    //         e.preventDefault();
    //         setIsLogout(!isLogout);
    //       },
    //       parentId: "authentication",
    //       stateVariables: isLogout,
    //       childItems: [
    //         { id: 1, label: "Basic", link: "/auth-logout-basic" },
    //         { id: 2, label: "Cover", link: "/auth-logout-cover" },
    //       ],
    //     },
    //     {
    //       id: "successMessage",
    //       label: "Success Message",
    //       link: "/#",
    //       isChildItem: true,
    //       click: function (e: any) {
    //         e.preventDefault();
    //         setIsSuccessMessage(!isSuccessMessage);
    //       },
    //       parentId: "authentication",
    //       stateVariables: isSuccessMessage,
    //       childItems: [
    //         { id: 1, label: "Basic", link: "/auth-success-msg-basic" },
    //         { id: 2, label: "Cover", link: "/auth-success-msg-cover" },
    //       ],
    //     },
    //     {
    //       id: "twoStepVerification",
    //       label: "Two Step Verification",
    //       link: "/#",
    //       isChildItem: true,
    //       click: function (e: any) {
    //         e.preventDefault();
    //         setIsVerification(!isVerification);
    //       },
    //       parentId: "authentication",
    //       stateVariables: isVerification,
    //       childItems: [
    //         { id: 1, label: "Basic", link: "/auth-twostep-basic" },
    //         { id: 2, label: "Cover", link: "/auth-twostep-cover" },
    //       ],
    //     },
    //     {
    //       id: "errors",
    //       label: "Errors",
    //       link: "/#",
    //       isChildItem: true,
    //       click: function (e: any) {
    //         e.preventDefault();
    //         setIsError(!isError);
    //       },
    //       parentId: "authentication",
    //       stateVariables: isError,
    //       childItems: [
    //         { id: 1, label: "404 Basic", link: "/auth-404-basic" },
    //         { id: 2, label: "404 Cover", link: "/auth-404-cover" },
    //         { id: 3, label: "404 Alt", link: "/auth-404-alt" },
    //         { id: 4, label: "500", link: "/auth-500" },
    //         { id: 5, label: "Offline Page", link: "/auth-offline" },
    //       ],
    //     },
    //   ],
    // },
    {
      id: "project",
      label: "Project",
      icon: "bx bx-file",
      link: "/#",
      click: function (e: any) {
        e.preventDefault();
        setIsProject(!isProject);
        setIscurrentState("Project");
        updateIconSidebar(e);
      },
      stateVariables: isProject,
      subItems: [
        {
          id: "project_list",
          label: "List",
          link: "/apps-projects-list",
          parentId: "project",
        },
        // {
        //   id: "project_overview",
        //   label: "Overview",
        //   link: "/apps-projects-overview",
        //   parentId: "project",
        // },
        // {
        //   id: "project_create",
        //   label: "Create Project",
        //   link: "/apps-projects-create",
        //   parentId: "project",
        // },
      ],
    },

    {
      id: "task",
      label: "Task",
      icon: "bx bx-file",
      link: "/#",
      click: function (e: any) {
        e.preventDefault();
        setIsTask(!isTask);
        setIscurrentState("Task");
        updateIconSidebar(e);
      },
      stateVariables: isTask,
      subItems: [
        // {
        //     id: "kanban_board",
        //     label: "Kanban Board",
        //     link: "/apps-tasks-kanban",
        //     parentId: "task",
        // },
        {
          id: "task_list_create",
          label: "Create New Task",
          link: "/apps-tasks-list-view-create?create_task=true",
          parentId: "task",
        },
        {
          id: "task_list",
          label: "Task List",
          link: "/apps-tasks-list-view",
          parentId: "task",
        },
        // {
        //     id: "task_detail",
        //     label: "Task Details",
        //     link: "/apps-tasks-details",
        //     parentId: "task",
        // },
        // {
        //   id: "resource_dashboard",
        //   label: "Resource Dashboard",
        //   link: "/advance-ui-ratings",
        //   parentId: "task",
        // },
      ],
    },
  ];
  return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;
