import React, { createContext, useState, ReactNode } from "react";

// Create the context
interface ServiceContextProps {
  selectedRoleId: string | null;
  selectedRoleName: string | null;
  setselectedRoleId: (id: string) => void;
  setSelectedRoleName: (name: string) => void;
}

// Initial context value
const initialContextValue: ServiceContextProps = {
  selectedRoleId: null,
  selectedRoleName: null,
  setselectedRoleId: () => {},
  setSelectedRoleName: () => {},
};

export const ServiceContext = createContext<ServiceContextProps>(
  initialContextValue
);

// Context Provider component
export const ServiceContextProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [selectedRoleId, setselectedRoleId] = useState<string | null>(null);
  const [selectedRoleName, setSelectedRoleName] = useState<string | null>(
    null
  );

  return (
    <ServiceContext.Provider
      value={{ selectedRoleId, selectedRoleName, setselectedRoleId, setSelectedRoleName }}
    >
      {children}
    </ServiceContext.Provider>
  );
};
