import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  Modal,
  Form,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Label,
  Input,
  FormFeedback,
  Spinner,
  Button,
} from "reactstrap";
import Flatpickr from "react-flatpickr";
import { dateTimeHandler } from "utils/CommonUtils";
import {
  useGetPositionsQuery,
  useGetWorkLocationsQuery,
  useGetEmployeeByUsernameQuery,
} from "slices/employee/employeeSlice";

const titles = [
  { label: "Mr.", value: 1 },
  { label: "Ms.", value: 2 },
  { label: "Mrs.", value: 3 },
];

interface OnboardEmployeeModalProps {
  modal: any;
  toggle: any;
  validation: any;
  postEmployeeLoading: boolean;
  step: any;
  setStep: any;
}

const OnboardEmployeeModal: React.FC<OnboardEmployeeModalProps> = ({
  modal,
  toggle,
  validation,
  postEmployeeLoading,
  step,
  setStep,
}) => {
  const {
    data: employeeRole,
    isLoading: isLoadingEmployeeRole,
    refetch: refetchEmployeeRole,
  } = useGetPositionsQuery(undefined);

  const {
    data: employeeLocation,
    isLoading: isLoadingLocation,
    refetch: refetchLocation,
  } = useGetWorkLocationsQuery(undefined);

  const [usernameExists, setUsernameExists] = useState(false);
  const [usernameChecked, setUsernameChecked] = useState(false);

  // Call useGetEmployeeByUsernameQuery when the username field is blurred or changes
  const { data: existingUser, refetch: checkUsername } =
    useGetEmployeeByUsernameQuery(validation.values.username, {
      skip: !usernameChecked, // Skip the query initially or when username is not checked
    });

  useEffect(() => {
    if (existingUser?.length > 0) {
      setUsernameExists(true); // Set to true if user exists
    } else {
      setUsernameExists(false); 
    }
  }, [existingUser]);

  // State to handle the current step

  const handleNext = () => {
    if (step === 1) {
      validation.handleSubmit(); // Validate step 1
      setStep(2); // Move to step 2 if validation passes
    } else if (step === 2) {
      validation.handleSubmit(); // Validate and submit the form
    }
  };

  const handleUsernameBlur = () => {
    setUsernameChecked(true); // Trigger the query when the username field is blurred
    checkUsername(); 
  };

  return (
    <Modal id="showModal" size="lg" isOpen={modal} toggle={toggle} centered>
      <ModalHeader className="bg-light p-3">Onboard New Member</ModalHeader>
      <Form onSubmit={validation.handleSubmit} autoComplete="off">
        <ModalBody>
          {step === 1 && (
            <Row>
              <Col md={6}>
                <div className="mb-3">
                  <Label for="title">Title</Label>
                  <span className="text-danger">*</span>
                  <select
                    className="form-select"
                    name="title"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.title || ""}
                  >
                    <option value="">Select</option>
                    {titles.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                  {validation.touched.title && validation.errors.title ? (
                    <FormFeedback type="invalid">
                      {typeof validation.errors.title === "string"
                        ? validation.errors.title
                        : null}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="mb-3">
                  <Label for="middle_name">Middle Name</Label>
                  <Input
                    name="middle_name"
                    type="text"
                    className="form-control"
                    id="middle_name"
                    autoComplete="off"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.middle_name || ""}
                    invalid={
                      validation.touched.middle_name &&
                      validation.errors.middle_name
                        ? true
                        : false
                    }
                  />
                  {validation.touched.middle_name &&
                  validation.errors.middle_name ? (
                    <FormFeedback type="invalid">
                      {typeof validation.errors.middle_name === "string"
                        ? validation.errors.middle_name
                        : null}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="mb-3">
                  <Label for="work_phone">Work Phone</Label>
                  <Input
                    name="work_phone"
                    type="text"
                    className="form-control"
                    id="work_phone"
                    autoComplete="off"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.work_phone || ""}
                    invalid={
                      validation.touched.work_phone &&
                      validation.errors.work_phone
                        ? true
                        : false
                    }
                  />
                  {validation.touched.work_phone &&
                  validation.errors.work_phone ? (
                    <FormFeedback type="invalid">
                      {typeof validation.errors.work_phone === "string"
                        ? validation.errors.work_phone
                        : null}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="mb-3">
                  <Label for="work_location">Work Location</Label>
                  <select
                    className="form-select"
                    name="work_location"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.work_location || ""}
                  >
                    <option value="">Select</option>
                    {employeeLocation?.map((options: any) => (
                      <option key={options.id} value={options.id}>
                        {options.location_name}
                      </option>
                    ))}
                  </select>
                  {validation.touched.work_location &&
                  validation.errors.work_location ? (
                    <FormFeedback type="invalid">
                      {typeof validation.errors.work_location === "string"
                        ? validation.errors.work_location
                        : null}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <Label for="first_name">First Name</Label>
                  <span className="text-danger">*</span>
                  <Input
                    name="first_name"
                    type="text"
                    className="form-control"
                    id="first_name"
                    autoComplete="off"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.first_name || ""}
                    invalid={
                      validation.touched.first_name &&
                      validation.errors.first_name
                        ? true
                        : false
                    }
                  />
                  {validation.touched.first_name &&
                  validation.errors.first_name ? (
                    <FormFeedback type="invalid">
                      {typeof validation.errors.first_name === "string"
                        ? validation.errors.first_name
                        : null}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="mb-3">
                  <Label for="last_name">Last Name</Label>
                  <span className="text-danger">*</span>
                  <Input
                    name="last_name"
                    type="text"
                    className="form-control"
                    id="last_name"
                    autoComplete="off"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.last_name || ""}
                    invalid={
                      validation.touched.last_name &&
                      validation.errors.last_name
                        ? true
                        : false
                    }
                  />
                  {validation.touched.last_name &&
                  validation.errors.last_name ? (
                    <FormFeedback type="invalid">
                      {typeof validation.errors.last_name === "string"
                        ? validation.errors.last_name
                        : null}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="mb-3">
                  <Label for="employee_start_date">Employment Start Date</Label>
                  <span className="text-danger">*</span>
                  <Flatpickr
                    className="form-control"
                    name="employee_start_date"
                    id="employee_start_date"
                    placeholder="dd M, yyyy"
                    onChange={(selectedDates: Date[]) => {
                      if (selectedDates[0] instanceof Date) {
                        const formattedDate = dateTimeHandler(
                          selectedDates[0].toISOString()
                        );
                        validation.setFieldValue(
                          "employee_start_date",
                          formattedDate
                        );
                      }
                    }}
                    onBlur={validation.handleBlur}
                    value={validation.values.employee_start_date || ""}
                    options={{
                      dateFormat: "Y-m-d",
                    }}
                    invalid={
                      validation.touched.employee_start_date &&
                      validation.errors.employee_start_date
                        ? true
                        : false
                    }
                  />
                  {validation.touched.employee_start_date &&
                  validation.errors.employee_start_date ? (
                    <FormFeedback type="invalid">
                      {typeof validation.errors.employee_start_date === "string"
                        ? validation.errors.employee_start_date
                        : null}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="mb-3">
                  <Label for="position">Position</Label>
                  <span className="text-danger">*</span>
                  <select
                    className="form-select"
                    name="position"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.position || ""}
                  >
                    <option value="">Select</option>
                    {employeeRole?.map((options: any) => (
                      <option key={options.id} value={options.id}>
                        {options.position_name}
                      </option>
                    ))}
                  </select>
                  {validation.touched.position && validation.errors.position ? (
                    <FormFeedback type="invalid">
                      {typeof validation.errors.position === "string"
                        ? validation.errors.position
                        : null}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>
          )}

          {step === 2 && (
            <Row>
              <Col md={6}>
                <div className="mb-3">
                  <Label for="username">Username</Label>
                  <Input
                    name="username"
                    type="text"
                    className="form-control"
                    id="username"
                    autoComplete="off"
                    onChange={validation.handleChange}
                    onBlur={handleUsernameBlur} // Trigger username check on blur
                    value={validation.values.username || ""}
                    invalid={
                      usernameExists ||
                      (validation.touched.username &&
                        !!validation.errors.username)
                    }
                  />
                  {usernameExists ? (
                    <FormFeedback type="invalid">
                      Username already exists
                    </FormFeedback>
                  ) : validation.touched.username &&
                    validation.errors.username ? (
                    <FormFeedback type="invalid">
                      {validation.errors.username}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <Label for="password">Password</Label>
                  <Input
                    name="password"
                    type="password"
                    className="form-control"
                    id="password"
                    autoComplete="off"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.password || ""}
                    invalid={
                      validation.touched.password && validation.errors.password
                        ? true
                        : false
                    }
                  />
                  {validation.touched.password && validation.errors.password ? (
                    <FormFeedback type="invalid">
                      {typeof validation.errors.password === "string"
                        ? validation.errors.password
                        : null}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>
          )}
        </ModalBody>
        <ModalFooter className="d-flex justify-content-between">
          {step === 1 && (
            <>
              <div></div>
            </>
          )}
          {step === 2 && (
            <>
              <Button color="secondary"  disabled={postEmployeeLoading} onClick={() => setStep(1)}>
                Previous
              </Button>
            </>
          )}

          <div>
            <Button
              type="submit"
              color="primary"
              disabled={postEmployeeLoading || usernameExists}
            >
              {postEmployeeLoading ? (
                <span className="d-flex align-items-spacebetween">
                  <Spinner size="sm" className="flex-shrink-0" />
                  <span className="flex-grow-1 ms-2">Loading...</span>
                </span>
              ) : (
                <> {step === 1 ? "Next" : "Submit"}</>
              )}
            </Button>
            &nbsp;
            <Button
              onClick={toggle}
              color="danger"
              disabled={postEmployeeLoading}
            >
              Cancel
            </Button>
          </div>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default OnboardEmployeeModal;
