import { postLogin, postJwtLogin } from "../../../helpers/fakebackend_helper";
import {
  loginSuccess,
  logoutUserSuccess,
  apiError,
  reset_login_flag,
  setRole,
} from "./reducer";

export const loginUser = (user: any, history: any) => async (dispatch: any) => {
  try {
    let response;

    response = await postJwtLogin({
      username: user.username,
      password: user.password,
    });

    // // Choose the correct login method based on the environment
    // if (window.__RUNTIME_CONFIG__.REACT_APP_DEFAULTAUTH === "firebase") {
    //   console.log("no firebase");
    // } else if (window.__RUNTIME_CONFIG__.REACT_APP_DEFAULTAUTH === "jwt") {
    //   response = await postJwtLogin({
    //     username: user.username,
    //     password: user.password,
    //   });
    // } else if (window.__RUNTIME_CONFIG__.REACT_APP_API_URL) {
    //   response = await postLogin({
    //     username: user.username,
    //     password: user.password,
    //   });
    // }

    var data = await response;

    if (data) {
      var finallogin: any = JSON.stringify(data);
      finallogin = JSON.parse(finallogin);

      const role_id = finallogin.role_id;
      const role_name = finallogin.role_name;

      if (role_id.length > 1) {
        // More than one role: navigate to role selection page
        history("/role-select", {
          state: { roles: role_name, roleIds: role_id, login: finallogin },
        });
      } else if (role_id.length === 1) {
        // Only one role: proceed to dashboard
        history("/dashboard");
        sessionStorage.setItem(
          "selectedRole",
          JSON.stringify({ role_id: role_id[0], role_name: role_name[0] })
        );

        dispatch(loginSuccess({ finallogin }));
        sessionStorage.setItem("authUser", JSON.stringify(finallogin));

        dispatch(setRole({ role_id: role_id[0], role_name: role_name[0] }));
      } else {
        console.log("Members role not assigned");
        const error = "Members role not assigned";
        dispatch(apiError(error));
        // throw new Error("Members role not assigned");
      }
    } else {
      throw new Error("No data returned from login.");
    }
  } catch (error: any) {
    dispatch(apiError(error));
  }
};
export const logoutUser = () => async (dispatch: any) => {
  try {
    sessionStorage.removeItem("authUser");
    sessionStorage.removeItem("selectedRole");
    if (window.__RUNTIME_CONFIG__.REACT_APP_DEFAULTAUTH === "firebase") {
      console.log("no firebase");
    } else {
      dispatch(logoutUserSuccess(true));
    }
  } catch (error: any) {
    dispatch(apiError(error));
  }
};

export const socialLogin =
  (type: any, history: any) => async (dispatch: any) => {
    try {
      let response;

      if (window.__RUNTIME_CONFIG__.REACT_APP_DEFAULTAUTH === "firebase") {
        console.log("no firebase");
      }

      const socialdata = await response;
      if (socialdata) {
        sessionStorage.setItem("authUser", JSON.stringify(response));
        dispatch(loginSuccess(response));
        history("/dashboard");
      }
    } catch (error: any) {
      dispatch(apiError(error));
    }
  };

export const resetLoginFlag = () => async (dispatch: any) => {
  try {
    const response = dispatch(reset_login_flag());
    return response;
  } catch (error: any) {
    console.log("error-resetlogin", error);
    dispatch(apiError(error));
  }
};
