import React from "react";
import { useParams } from "react-router-dom";
import { useGetSupplierByIdQuery } from "slices/resourceInventory/inventorySlice";
import Loader from "../../../Components/Common/Loader";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Row,
  Button,
} from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { useNavigate } from "react-router-dom";

const SupplierDetails = () => {
  const { supplierId } = useParams<{ supplierId: string }>();

  const navigate = useNavigate();

  const { data: supplier, isLoading } = useGetSupplierByIdQuery(supplierId);

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title="Supplier Details" pageTitle="Suppliers" />
        {isLoading ? (
          <Loader />
        ) : (
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader className="d-flex">
                  <h4 className="card-title flex-grow-1 mb-0">
                    Supplier Details
                  </h4>
                </CardHeader>
                <CardBody>
                  <div className="table-card">
                    <table className="table mb-0">
                      <tbody>
                        <tr>
                          <td className="fw-medium" style={{ width: "300px" }}>
                            Name
                          </td>
                          <td>{supplier?.name}</td>
                        </tr>
                        <tr>
                          <td className="fw-medium">Email</td>
                          <td>{supplier?.contact_email}</td>
                        </tr>
                        <tr>
                          <td className="fw-medium">Phone</td>
                          <td>{supplier?.contact_phone}</td>
                        </tr>
                        <tr>
                          <td className="fw-medium">Address</td>
                          <td>{supplier?.address}</td>
                        </tr>
                        <tr>
                          <td className="fw-medium">Website</td>
                          <td>{supplier?.website}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </CardBody>
                <CardFooter>
                  <Button
                    color="danger"
                    onClick={() => navigate("/supplier-list")}
                  >
                    Back
                  </Button>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        )}
      </Container>
    </div>
  );
};

export default SupplierDetails;
