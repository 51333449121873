import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  Modal,
  Form,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Label,
  Input,
  FormFeedback,
} from "reactstrap";
import { Link } from "react-router-dom";
import Flatpickr from "react-flatpickr";
import { useNavigate } from "react-router-dom";

// Import Breadcrumb
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import DeleteModal from "../../../Components/Common/DeleteModal";

import {
  useGetWorkflowsQuery,
  useUpdateWorkflowProjectMutation,
} from "slices/workflow/WorkFlowSlice.";
import { useGetAllProjectsQuery } from "slices/projects/projectSlice";

import WorkFlow from "../WorkFlowAdd/WorkFlowAdd";

// Redux
import { useSelector, useDispatch } from "react-redux";
import TableContainer from "../../../Components/Common/TableContainer";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../../Components/Common/Loader";

// Export Modal
import ExportCSVModal from "../../../Components/Common/ExportCSVModal";
import { createSelector } from "reselect";
import WorkflowProjectModal from "../Modal/ProjectModal";
import WorkflowChartModal from "../Modal/WorkflowChartModal";

import {
  usePatchEmployeeRoleMutation,
  useGetEmployeeDropDownQuery,
} from "slices/employee/employeeSlice";
import { useGetRolesQuery } from "slices/workflow/WorkFlowSlice.";

interface Workflow {
  id: string;
  workflow_name: string;
}

const WorkflowListView = () => {
  const {
    data: workFlow,
    isLoading: isLoadingWorkFlow,
    refetch: refetchWorkFlow,
  } = useGetWorkflowsQuery(undefined);

  const { data: projectData } = useGetAllProjectsQuery(undefined);

  const [
    updateInspectionReportProject,
    { isLoading: InspectionReportProjectLoading },
  ] = useUpdateWorkflowProjectMutation();

  const navigate = useNavigate();

  const dispatch: any = useDispatch();

  const selectLayoutState = (state: any) => state.Ecommerce;
  const ecomCustomerProperties = createSelector(selectLayoutState, (ecom) => ({
    customers: ecom.customers,
    isCustomerSuccess: ecom.isCustomerSuccess,
    error: ecom.error,
  }));

  const [workflowData, setWorkflowData] = useState<any>([]);
  const { customers, error } = useSelector(ecomCustomerProperties);
  const [customer, setCustomer] = useState<any>([]);
  const [modal, setModal] = useState<boolean>(false);
  const [showAddComponent, setShowAddComponent] = useState<boolean>(false);
  const [workflowId, setWorkflowId] = useState<string | null>(null);
  const [isModalOpen, setShowModal] = useState<boolean>(false);
  const [projectID, setProjectID] = useState<any>([]);
  const [isChartModalOpen, setChartShowModal] = useState<boolean>(false);
  const [selectedWorkflow, setSelectedWorkflow] = useState<any>(null);

  const projectOptions = useMemo(() => {
    return (
      projectData?.map((project: any) => ({
        value: project.id,
        label: project.project_name,
        isDisabled: projectID.includes(project.id),
      })) || []
    );
  }, [projectData]);

  // const toggleModal = useCallback(() => {
  //   setIsModalOpen((prev) => !prev);
  // }, []);

  useEffect(() => {
    if (workFlow) {
      setWorkflowData(workFlow);
      let pj = workFlow.map((n) => {
        return n.projects;
      });
      setProjectID(pj.flat());
    }
  }, [workFlow]);

  const handleWorkFlowEdit = (workflow: any) => {
    setSelectedWorkflow(workflow);
    setShowModal(true);
  };

  const handleWorkFlowChartOpen = (workflow: any) => {
    setSelectedWorkflow(workflow);
    setChartShowModal(true);
    setShowAddComponent(false);
  };

  const handleSaveProjects = (workflowId: any, selectedProjects: any) => {
    updateInspectionReportProject({
      id: workflowId,
      projects: selectedProjects,
    });
    refetchWorkFlow();
    setShowModal(false);
  };

  const toggle = useCallback(() => {
    setModal((prevModal) => !prevModal);
    if (!modal) {
      setCustomer(null);
    }
  }, [modal]);

  useEffect(() => {
    if (!customers) {
      setCustomer(customers);
    }
  }, [customers]);

  // Add Customer
  const handleCustomerClicks = () => {
    setCustomer(null);
    toggle();
  };

  // Delete Multiple
  const [selectedCheckBoxDelete, setSelectedCheckBoxDelete] = useState([]);
  const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);

  const deleteMultiple = () => {
    const checkall: any = document.getElementById("checkBoxAll");
    selectedCheckBoxDelete.forEach((element: any) => {
      console.log("value", element.value);
      // dispatch(onDeleteContact(element.value));
      // setTimeout(() => { toast.clearWaitingQueue(); }, 3000);
    });
    setIsMultiDeleteButton(false);
    checkall.checked = false;
  };

  // Checked All
  const checkedAll = useCallback(() => {
    const checkall: any = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".contactCheckBox");

    if (checkall.checked) {
      ele.forEach((ele: any) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele: any) => {
        ele.checked = false;
      });
    }
    deleteCheckbox();
  }, []);

  const deleteCheckbox = () => {
    const ele: any = document.querySelectorAll(".contactCheckBox:checked");
    ele.length > 0
      ? setIsMultiDeleteButton(true)
      : setIsMultiDeleteButton(false);
    setSelectedCheckBoxDelete(ele);
  };

  const columns = useMemo(
    () => [
      {
        Header: (
          <input
            type="checkbox"
            className="form-check-input"
            id="checkBoxAll"
            onClick={() => checkedAll()}
          />
        ),
        Cell: (cellProps: any) => {
          return (
            <input
              type="checkbox"
              className="contactCheckBox form-check-input"
              value={cellProps.row.original.id}
              onChange={() => deleteCheckbox()}
            />
          );
        },
        id: "#",
      },
      {
        Header: "Workflow Name",
        accessor: "workflow_name",
        filterable: false,
      },
      {
        Header: "Approval Steps",
        accessor: "approvals",
        filterable: false,
        Cell: ({ row }: any) => (
          <span className="badge bg-primary">
            {row.original.approvals.length}
          </span>
        ),
      },
      {
        Header: "Project Names",
        accessor: "project_names",
        filterable: false,
        Cell: ({ row }: any) => (
          <>
            {row.original.project_names.length > 0 ? (
              row.original.project_names.map((projectName: string) => (
                <span key={projectName} className="badge bg-secondary me-1">
                  {projectName}
                </span>
              ))
            ) : (
              <span className="text-muted">No Projects Assigned</span>
            )}
          </>
        ),
      },
      {
        Header: "Workflow",
        accessor: "approvals_org",
        filterable: false,
        Cell: ({ row }: any) =>
          row.original.approvals.length > 0 ? (
            <Link
              to={"#"}
              onClick={() => handleWorkFlowChartOpen(row?.original)}
            >
              <i className="ri-share-circle-fill fs-16"></i>
            </Link>
          ) : (
            ""
          ),
      },
      {
        Header: "Actions",
        accessor: "actions",
        Cell: ({ row }: any) => {
          const workflow = row.original;
          const handleView = () => {
            navigate(`/report-view/${row?.original?.id}`);
          };

          const handleWorkFlowEditById = () => {
            setWorkflowId(row?.original?.id);
            setShowAddComponent(true);
          };
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <li className="list-inline-item edit" title="Assign Projects">
                {row.original.approvals.length > 0 ? (
                  <div
                    className="text-primary d-inline-block edit-item-btn"
                    onClick={() => handleWorkFlowEdit(row?.original)}
                  >
                    <i className="ri-contacts-fill fs-16"></i>{" "}
                    {/* Icon for Assign Projects */}
                  </div>
                ) : (
                  ""
                )}
              </li>
              <li className="list-inline-item edit" title="View">
                <div
                  className="text-primary d-inline-block edit-item-btn"
                  onClick={handleWorkFlowEditById}
                >
                  <i className="ri-edit-fill fs-16"></i>
                </div>
              </li>
            </ul>
          );
        },
      },
    ],
    [checkedAll, deleteCheckbox, navigate]
  );

  // Export Modal
  const [isExportCSV, setIsExportCSV] = useState<boolean>(false);

  document.title = "Customers | Velzon - React Admin & Dashboard Template";
  return (
    <React.Fragment>
      <div className="page-content">
        <ExportCSVModal
          show={isExportCSV}
          onCloseClick={() => setIsExportCSV(false)}
          data={workFlow}
        />
        <DeleteModal
          show={modal}
          onDeleteClick={handleCustomerClicks}
          onCloseClick={() => setModal(false)}
        />
        <Container fluid>
          <BreadCrumb title="WorkFlow List" pageTitle="WorkFlow" />
          <Row>
            <Col lg={12}>
              {showAddComponent && (
                <WorkFlow
                  setShowAddComponent={setShowAddComponent}
                  workflowId={workflowId}
                />
              )}
              <Card id="customerList">
                <CardHeader className="border-0">
                  <Row className="g-4 align-items-center">
                    <div className="col-sm">
                      <div>
                        <h5 className="card-title mb-0">Work List</h5>
                      </div>
                    </div>
                    <div className="col-sm-auto">
                      <div>
                        <button
                          type="button"
                          className="btn btn-success add-btn me-1"
                          id="create-btn"
                          onClick={() => {
                            setWorkflowId(null);
                            setShowAddComponent(true);
                          }}
                          disabled={isLoadingWorkFlow}
                        >
                          <i className="ri-add-line align-bottom me-1"></i> Add
                          Workflow
                        </button>{" "}
                        <button
                          type="button"
                          className="btn btn-secondary"
                          onClick={() => setIsExportCSV(true)}
                          disabled={isLoadingWorkFlow}
                        >
                          <i className="ri-file-download-line align-bottom me-1"></i>{" "}
                          Export
                        </button>
                      </div>
                    </div>
                  </Row>
                </CardHeader>
                <div className="card-body pt-0">
                  <div>
                    {/* {isLoadingWorkFlow ? (
                      <Loader />
                    ) : ( */}
                    <TableContainer
                      columns={columns}
                      data={workflowData}
                      isGlobalFilter={true}
                      isAddUserList={false}
                      customPageSize={10}
                      className="custom-header-css"
                      // isCustomerFilter={true}
                      SearchPlaceholder={"Search for members..."}
                      isPagination={true}
                      isLoading={isLoadingWorkFlow}
                    />
                    {/* )} */}
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
          {selectedWorkflow && (
            <WorkflowProjectModal
              isOpen={isModalOpen}
              toggle={() => setShowModal(false)}
              workflowName={selectedWorkflow?.workflow_name}
              workflowId={selectedWorkflow?.id}
              projects={projectOptions}
              projectID={projectID}
              selectedProjects={selectedWorkflow?.projects || []}
              onSave={handleSaveProjects}
              InspectionReportProjectLoading={InspectionReportProjectLoading}
            />
          )}

          <WorkflowChartModal
            isOpen={isChartModalOpen}
            toggle={() => setChartShowModal(false)}
            workflowName={selectedWorkflow?.workflow_name}
            workflowId={selectedWorkflow?.id}
            projects={projectOptions}
            approvals={selectedWorkflow?.approvals}
            selectedProjects={selectedWorkflow?.projects || []}
            onSave={handleSaveProjects}
          />
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
};

export default WorkflowListView;
