import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Container, Row, Col, Card, CardHeader } from "reactstrap";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

// Formik
import * as Yup from "yup";
import { useFormik } from "formik";

// Import Breadcrumb
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import DeleteModal from "../../../Components/Common/DeleteModal";

import {
  useGetEmployeesQuery,
  useCreateEmployeeMutation,
} from "slices/employee/employeeSlice";

// Redux
import { useSelector, useDispatch } from "react-redux";
import TableContainer from "../../../Components/Common/TableContainer";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../../Components/Common/Loader";

// Export Modal
import ExportCSVModal from "../../../Components/Common/ExportCSVModal";
import { createSelector } from "reselect";
import OnboardEmployeeModal from "./modal/onboardEmployee";

const EcommerceCustomers = () => {
  const {
    data: employees,
    isLoading: employeeLoading,
    refetch: refetchemployees,
  } = useGetEmployeesQuery(undefined);

  console.log("employees", employees);

  const [createEmployee, { isLoading: postEmployeeLoading }] =
    useCreateEmployeeMutation();

  const navigate = useNavigate();

  const selectLayoutState = (state: any) => state.Ecommerce;
  const ecomCustomerProperties = createSelector(selectLayoutState, (ecom) => ({
    customers: ecom.customers,
    isCustomerSuccess: ecom.isCustomerSuccess,
    error: ecom.error,
  }));

  const { customers, error } = useSelector(ecomCustomerProperties);
  const [customer, setCustomer] = useState<any>([]);
  const [modal, setModal] = useState<boolean>(false);
  const [employeeData, setEmployeeData] = useState<any>([]);
  const [currentStep, setCurrentStep] = useState<number>(1);

  useEffect(() => {
    if (employees) {
      setEmployeeData(employees);
    }
  }, [employees]);

  const toggle = useCallback(() => {
    setModal((prevModal) => !prevModal);
    if (!modal) {
      setCurrentStep(1);
    }
  }, [modal]);

  const validationSchemas: Record<number, Yup.ObjectSchema<any>> = {
    1: Yup.object({
      title: Yup.string().required("Please select a title"),
      first_name: Yup.string().required("Please enter the first name"),
      middle_name: Yup.string().nullable(),
      last_name: Yup.string().required("Please enter the last name"),
      work_phone: Yup.string().nullable(),
      work_location: Yup.string().nullable(),
      employee_start_date: Yup.string().required("Please select the start date"),
      position: Yup.string().required("Please select the position"),
    }),
    2: Yup.object({
      username: Yup.string().required("Please enter a username"),
      password: Yup.string()
        .required("Please enter a password")
        .min(8, "Password must be at least 8 characters")
        .matches(/\d/, "Password must contain at least one number"),
    }),
  };

  // Use a type assertion to tell TypeScript that currentStep is a valid key for validationSchemas
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: "",
      first_name: "",
      middle_name: "",
      last_name: "",
      work_phone: "",
      work_email: "",
      employee_number: "",
      work_location: "",
      employee_start_date: null,
      position: "",
      username: "",
      password: "",
    },
    validationSchema:
      validationSchemas[currentStep as keyof typeof validationSchemas],
    onSubmit: async (values) => {
      if (currentStep === 1) {
        setCurrentStep(2);
      } else {
        try {
          await createEmployee(values).unwrap();
          toast.success("Employee added successfully!");
          refetchemployees();
          validation.resetForm();
          toggle();
        } catch (error) {
          toast.error("Error creating employee");
          validation.resetForm();
          toggle();
        }
      }
    },
  });

  useEffect(() => {
    if (!customers) {
      setCustomer(customers);
    }
  }, [customers]);

  // Add Customer
  const handleCustomerClicks = () => {
    setCustomer(null);
    toggle();
  };

  // Delete Multiple
  const [selectedCheckBoxDelete, setSelectedCheckBoxDelete] = useState([]);
  const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);

  const deleteMultiple = () => {
    const checkall: any = document.getElementById("checkBoxAll");
    selectedCheckBoxDelete.forEach((element: any) => {
      console.log("value", element.value);
      // dispatch(onDeleteContact(element.value));
      // setTimeout(() => { toast.clearWaitingQueue(); }, 3000);
    });
    setIsMultiDeleteButton(false);
    checkall.checked = false;
  };

  // Checked All
  const checkedAll = useCallback(() => {
    const checkall: any = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".contactCheckBox");

    if (checkall.checked) {
      ele.forEach((ele: any) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele: any) => {
        ele.checked = false;
      });
    }
    deleteCheckbox();
  }, []);

  const deleteCheckbox = () => {
    const ele: any = document.querySelectorAll(".contactCheckBox:checked");
    ele.length > 0
      ? setIsMultiDeleteButton(true)
      : setIsMultiDeleteButton(false);
    setSelectedCheckBoxDelete(ele);
  };

  // Customers Column
  const columns = useMemo(
    () => [
      {
        Header: (
          <input
            type="checkbox"
            className="form-check-input"
            id="checkBoxAll"
            onClick={() => checkedAll()}
          />
        ),
        Cell: (cellProps: any) => {
          return (
            <input
              type="checkbox"
              className="contactCheckBox form-check-input"
              value={cellProps.row.original.id}
              onChange={() => deleteCheckbox()}
            />
          );
        },
        id: "#",
      },
      {
        Header: "Name",
        accessor: "employee_fullname",
        filterable: false,
        Cell: (contact: any) => (
          <>
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0">
                {
                  contact.row.original?.employee_profile_image_url &&
                  contact.row.original?.employee_profile_image_url[
                    "profile_image_url"
                  ] ? (
                    <img
                      src={
                        contact.row.original?.employee_profile_image_url &&
                        contact.row.original?.employee_profile_image_url[
                          "profile_image_url"
                        ]
                      }
                      // process.env.REACT_APP_API_URL + "/images/users/" +
                      alt=""
                      className="avatar-xs rounded-circle"
                    />
                  ) : (
                    <div className="flex-shrink-0 avatar-xs me-2">
                      <div className="avatar-title bg-success-subtle text-success rounded-circle fs-13">
                        {contact.row.original.employee_fullname.charAt(0)}
                      </div>
                    </div>
                  )
                  // <img src={dummyImg} alt="" className="avatar-xxs rounded-circle" />
                }
              </div>
              <div className="flex-grow-1 ms-2 name">
                <Link to={`/profile/${contact.row.original.employee_uuid}`}>
                  {contact.row.original.employee_fullname}
                </Link>
              </div>
            </div>
          </>
        ),
      },
      {
        Header: "Employee Number",
        accessor: "employee_number",
        filterable: false,
      },
      {
        Header: "User Name",
        accessor: "username",
        filterable: false,
      },
      {
        Header: "Position",
        accessor: "position_name",
        Cell: (cell: any) => (
          <>
            <span className="badge bg-primary-subtle text-primary me-1">
              {cell.row.original?.position_name}
            </span>
          </>
        ),
      },
      {
        Header: "Start Date",
        accessor: "employee_start_date",
        filterable: false,
      },
      {
        Header: "Work Phone",
        accessor: "work_phone",
        filterable: false,
      },
      {
        Header: "Active",
        accessor: "active",
        filterable: false,
        Cell: (cell: { value: any }) =>
          cell.value ? (
            <span className="badge text-uppercase bg-success-subtle text-success">
              {" "}
              Yes{" "}
            </span>
          ) : (
            <span className="badge text-uppercase bg-danger-subtle text-danger">
              {" "}
              No{" "}
            </span>
          ),
      },
      {
        Header: "Actions",
        accessor: "actions",
        Cell: ({ row }: any) => {
          const handleView = () => {
            navigate(`/profile/${row.original.employee_uuid}`);
          };

          const handleEdit = () => {
            // Your edit logic here
            console.log("Edit:", row.original);
          };

          const handleDelete = () => {
            // Your delete logic here
            console.log("Delete:", row.original);
          };

          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <li className="list-inline-item edit" title="View">
                <Link
                  to={`/profile/${row?.original?.employee_uuid}`}
                  className="text-primary d-inline-block edit-item-btn"
                  // onClick={handleView}
                >
                  <i className="ri-eye-fill fs-16"></i>
                </Link>
              </li>
              {/* <li className="list-inline-item edit" title="Edit">
                <Link
                  to="#"
                  className="text-primary d-inline-block edit-item-btn"
                  onClick={() => {
                    const customerData = cellProps.row.original;
                    handleCustomerClick(customerData);
                  }}
                >
                  <i className="ri-pencil-fill fs-16"></i>
                </Link>
              </li> */}
              {/* <li className="list-inline-item" title="Remove">
                <Link
                  to="#"
                  className="text-danger d-inline-block remove-item-btn"
                  onClick={() => {
                    const customerData = cellProps.row.original;
                    onClickDelete(customerData);
                  }}
                >
                  <i className="ri-delete-bin-5-fill fs-16"></i>
                </Link>
              </li> */}
            </ul>
          );
        },
      },
    ],
    []
  );

  // Export Modal
  const [isExportCSV, setIsExportCSV] = useState<boolean>(false);

  document.title = "Customers | Velzon - React Admin & Dashboard Template";
  return (
    <React.Fragment>
      <div className="page-content">
        <ExportCSVModal
          show={isExportCSV}
          onCloseClick={() => setIsExportCSV(false)}
          data={employeeData}
        />
        <DeleteModal
          show={modal}
          onDeleteClick={handleCustomerClicks}
          onCloseClick={() => setModal(false)}
        />
        <Container fluid>
          <BreadCrumb title="Members List" pageTitle="Members" />
          <Row>
            <Col lg={12}>
              <Card id="customerList">
                <CardHeader className="border-0">
                  <Row className="g-4 align-items-center">
                    <div className="col-sm">
                      <div>
                        <h5 className="card-title mb-0">Members List</h5>
                      </div>
                    </div>
                    <div className="col-sm-auto">
                      <div>
                        <button
                          type="button"
                          className="btn btn-success add-btn me-1"
                          id="create-btn"
                          onClick={handleCustomerClicks}
                          disabled={employeeLoading}
                        >
                          <i className="ri-add-line align-bottom me-1"></i> Add
                          Employee
                        </button>{" "}
                        <button
                          type="button"
                          className="btn btn-secondary"
                          onClick={() => setIsExportCSV(true)}
                          disabled={employeeLoading}
                        >
                          <i className="ri-file-download-line align-bottom me-1"></i>{" "}
                          Export
                        </button>
                      </div>
                    </div>
                  </Row>
                </CardHeader>
                <div className="card-body pt-0">
                  <div>
                    <TableContainer
                      columns={columns}
                      data={employeeData}
                      isGlobalFilter={true}
                      isAddUserList={false}
                      customPageSize={10}
                      className="custom-header-css"
                      // isCustomerFilter={true}
                      SearchPlaceholder={"Search for members..."}
                      isPagination={true}
                      isLoading={employeeLoading}
                    />
                  </div>

                  <OnboardEmployeeModal
                    modal={modal}
                    toggle={toggle}
                    validation={validation}
                    postEmployeeLoading={postEmployeeLoading}
                    step={currentStep}
                    setStep={setCurrentStep}
                  />
                </div>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
};

export default EcommerceCustomers;
